import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import WithHeaderFooter from "./components/WithHeaderFooter";
import Home from "./pages/Home.js";
import Dashboard from "./pages/Dashboard";
import UserTable from "./pages/Users/UserTable";
import Add from "./pages/Users/UserAddEdit";
import Live from "./pages/Live.js";
import ContentTable from "./pages/Content/ContentTable";
import ContentAddUpdate from "./pages/Content/ContentAddUpdate";
import MySliderComponent from "./components/VideoSlider";
import SectionsList from "./pages/Sections/SectionsList";
import Privacypolicy from "./pages/Privacypolicy";
import Article from "./pages/Article/ArticleTable";
import ArticleAddUpdate from "./pages/Article/ArticleAddUpdate.js";
import SectionAddUpdate from "./pages/Sections/SectionAddUpdate";
import HeroPoster from "./pages/HeroPoster/HeroPosterTable.js";
import HeroPosterAddUpdate from "./pages/HeroPoster/HeroPosterAddUpdate.js";
import ContactUs from "./pages/ContactUs.js";
import SAMLRedirect from "./pages/SAMLRedirect.js";
import ArticleDescription from "./pages/Article/ArticleDescription.js";
import StaticInfo from "./pages/StaticInfo/StaticInfoTable.js";
import StaticInfoUpdate from "./pages/StaticInfo/StaticInfoUpdate.js";
import FAQs from "./pages/FAQs.js";
import Loader from "./components/loader.js";
import Footer from "./components/Footer.js";
import AaMaster from "./pages/AaMaster/AaMasterTable.js";
import AaMasterAddUpdate from "./pages/AaMaster/AaMasterAddUpdate.js";
import CropImage from "./pages/CropImage.js";
import Participation from "./pages/Participation/ParticipationTable.js";
import ParticipationAddUpdate from "./pages/Participation/ParticipationAddUpdate.js";
import NotificationSend from "./pages/Notification/NotificationSend";
import EpisodeAdd from "./pages/AaMaster/EpisodeAdd.js";
import ScrollProgress from "./pages/scroller.jsx";

function App({instance}) {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const inMobileDevice = queryParams.has("isMobileDevice");

  return (
    <>
      <div className="theme"></div>
      <ScrollProgress/>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/dashboard/*"
          element={
            <WithHeaderFooter>
              <Dashboard />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/user/list"
          element={
            <WithHeaderFooter>
              <UserTable />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/content/list"
          element={
            <WithHeaderFooter>
              <ContentTable />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/content/add"
          element={
            <WithHeaderFooter>
              <ContentAddUpdate />
            </WithHeaderFooter>
          }
        />
        <Route path="/loader" element={<Loader />} />

        <Route
          path="/content/update/:id"
          element={
            <WithHeaderFooter>
              <ContentAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/sections/list"
          element={
            <WithHeaderFooter>
              <SectionsList />
            </WithHeaderFooter>
          }
        />
        <Route
          path="/sections/add"
          element={
            <WithHeaderFooter>
              <SectionAddUpdate />
            </WithHeaderFooter>
          }
        />
        <Route
          path="/sections/update/:id"
          element={
            <WithHeaderFooter>
              <SectionAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/article/list"
          element={
            <WithHeaderFooter>
              <Article />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/article/add"
          element={
            <WithHeaderFooter>
              <ArticleAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/article/update/:id"
          element={
            <WithHeaderFooter>
              <ArticleAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/article/details/:id"
          element={
            <div className="parent">
              <ArticleDescription />
              <Footer />
              {/* {!inMobileDevice ? <Footer /> : null} */}
            </div>
          }
        />

        <Route
          path="/heroposter/list"
          element={
            <WithHeaderFooter>
              <HeroPoster />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/heroposter/add"
          element={
            <WithHeaderFooter>
              <HeroPosterAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/heroposter/update/:id"
          element={
            <WithHeaderFooter>
              <HeroPosterAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/staticinfo/list"
          element={
            <WithHeaderFooter>
              <StaticInfo />
            </WithHeaderFooter>
          }
        />
        <Route
          path="/staticinfo/update/:id"
          element={
            <WithHeaderFooter>
              <StaticInfoUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/user/edit/:id"
          element={
            <WithHeaderFooter>
              <Add />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/notification/send"
          element={
            <WithHeaderFooter>
              <NotificationSend />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/cropimage"
          element={
            <WithHeaderFooter>
              <CropImage />
            </WithHeaderFooter>
          }
        />

        <Route path="/viewer" element={<MySliderComponent />} />

        <Route
          path="/live"
          element={
            <WithHeaderFooter>
              <Live />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/amazingadaniansmaster/list"
          element={
            <WithHeaderFooter>
              <AaMaster />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/amazingadaniansmaster/add"
          element={
            <WithHeaderFooter>
              <AaMasterAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/amazingadaniansmaster/update/:id"
          element={
            <WithHeaderFooter>
              <AaMasterAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/participation/list"
          element={
            <WithHeaderFooter>
              <Participation />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/participation/add"
          element={
            <WithHeaderFooter>
              <ParticipationAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/participation/update/:id"
          element={
            <WithHeaderFooter>
              <ParticipationAddUpdate />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/amazingadaniansmaster/update/:aamasterid/episode/add"
          element={
            <WithHeaderFooter>
              <EpisodeAdd />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/amazingadaniansmaster/update/:aamasterid/episode/update/:episodeid"
          element={
            <WithHeaderFooter>
              <EpisodeAdd />
            </WithHeaderFooter>
          }
        />

        <Route
          path="/privacypolicy"
          element={
            <div className="parent">
              <Privacypolicy />
              {!inMobileDevice ? (
                <Footer />) : (null)}
            </div>
          }
        />

        <Route
          path="/faqs"
          element={
            <div className="parent">
              <FAQs />
              {!inMobileDevice ? (
                <Footer />) : (null)}
            </div>
          }
        />

        <Route
          path="/contactus"
          element={
            // <div classname="parent">
            <div className="parent">
              <ContactUs />
              <Footer />
            </div>
          }
        />
        <Route path="/SAML/Consume" element={<SAMLRedirect instance={instance}/>}/>
      </Routes>
    </>
  );
}

export default App;