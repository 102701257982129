import React, { useState, useEffect } from 'react';
import { HTTP } from '../api/http-common';
import { isValidEmail, isValidOTP } from '../components/common';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { MsLogo } from "../components/SideBarIcons";
import { loginRequest, msalC } from "../components/msalC.js";
const Login = () => {
    const { instance } = useMsal();
    const [email, setEmail] = useState('@adani.com');
    const [emailError, setEmailError] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpSuccess, setOtpSuccess] = useState('');
    const [msalInitialized, setMsalInitialized] = useState(false);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({ page: '1', limit: '10' });
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState({ field: 'id', type: 'desc' });

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'otp') {
            setOtp(value);
        }
    };
    const clearValidationError = () => {
        setEmailError('');
        setOtpSuccess('');
        setOtpError('');
    };
    const validate = () => {
        clearValidationError();
        let checked = true;
        if (email === '') {
            setEmailError('Please enter email address');
            checked = false;
        } 
        else if (!isValidEmail(email)) {
            // setEmailError('Email address should be in xyz@adani.com format');
            setEmailError('Invalid email.');
            checked = false;
        }
        if (isOtpSent) {
            if (otp === '') {
                setOtpError('Please enter OTP');
                checked = false;
            } else if (!isValidOTP(otp)) {
                setOtpError('OTP should be only in numbers');
                checked = false;
            }
        }
        return checked;
    };
    const handleSubmit = () => {
        if (validate()) {
            if (!isOtpSent) {
                handleEmailSubmit();
            } else {
                handleOtpSubmit();
            }
        }
        
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };
    const handleResendOtp = () => {
        handleEmailSubmit();
    };
    const handleEmailSubmit = () => {
        setOtpSuccess('');
        setOtpError('');
        const fields = {
            email: email,
            is_web: true
        };
        HTTP.post("send-otp", fields)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setIsOtpSent(true);
                    setOtpSuccess('OTP sent successfully');
                }
                if (response.status === 400) {
                    alert('400')
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 400) {
                    clearValidationError();
                    setEmailError(error.response.data.detail);
                }

            });
    };
    const handleOtpSubmit = () => {
        const fields = {
            email: email,
            otp: otp
        };
        HTTP.post("verify-otp", fields)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                    localStorage.setItem('access_token_expires', response.data.access_token_expires)
                    fetchUserData();
                }
            })
            .catch((error) => {
                if (error.response.status === 406) {
                    clearValidationError();
                    setOtpError(error.response.data.detail);
                }
            });
    };
    const fetchUserData = () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        };
        HTTP.get("user/me", config, config)
            .then((response) => {
                if (response.status === 200) {
                    loadStaticInfo();
                    localStorage.setItem('userObj', JSON.stringify(response.data));
                    let userObj = JSON.parse(localStorage.getItem('userObj'))
                    console.log(userObj)
                    let userRole = userObj?.role?.id;
                    console.log(userRole)
                    if(userRole < 6){
                        navigate('/content/list');
                    }else{
                        navigate('/content/list');
                    }
                    // navigate('/content/list');
                }
            })
            .catch((error) => {
                console.log(error);

            });
    };
    const loadStaticInfo = async () => {
        setIsLoading(true)
        let fields = {
            filters: {
                key: '',
            },
            pagination: pagination,
            sorting: sorting,
        };
        HTTP.post('static_info/list', fields)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    const responseContentsData = data.contents

                    const brightcovePlayerObject = responseContentsData.find(item => item.key === "brightcove_player_id");
                    
                    const brightcovePlayerValue = brightcovePlayerObject ? brightcovePlayerObject.value : null;

                    localStorage.setItem('brightcovePlayerValue', brightcovePlayerValue);
                    
                }
                if (response.status === 204) {
                    
                }
            })
            .catch((error) => {
                // setError(error)
                // setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const WrappedView = () => {
        const { instance } = useMsal();
        const msalInstance = new PublicClientApplication(msalC);
        const activeAccount_d = instance.getActiveAccount();
        console.log(activeAccount_d)
        let activeAccount = false;
        if (msalInstance.getAllAccounts()[0] != undefined) {
            activeAccount = true;
            let access_token = '';
            let refresh_token = '';
            let access_token_obj = {};
            let refresh_token_obj = {};
            const keys = Object.keys(sessionStorage);
            // Iterate over each key and retrieve its corresponding value
            const sessionStorageItems = {};
            keys.forEach(key => {
                const value = sessionStorage.getItem(key);
                sessionStorageItems[key] = value;
                for (let key_data in JSON.parse(value)) {
                    if (key_data == 'credentialType' && JSON.parse(value)[key_data] == 'AccessToken') {
                        access_token_obj = value;
                        for (let acc_data in JSON.parse(access_token_obj)) {
                            if (acc_data == 'secret') {
                                access_token = JSON.parse(access_token_obj)[acc_data];
                            }
                        }
                    }
                    if (key_data == 'credentialType' && JSON.parse(value)[key_data] == 'RefreshToken') {
                        refresh_token_obj = value;
                        for (let ref_data in JSON.parse(refresh_token_obj)) {
                            if (ref_data == 'secret') {
                                refresh_token = JSON.parse(refresh_token_obj)[ref_data];
                            }
                        }
                    }
                }
            });
            console.log(access_token)
            console.log(refresh_token)
            if(access_token != ''){
                const fields = {
                    access_token: access_token
                };
                HTTP.post('user/saml/authentication', fields)
                    .then((response) => {
                        if (response.status === 200) {
                            sessionStorage.clear();
                            localStorage.setItem('access_token', response.data.access_token);
                            localStorage.setItem('refresh_token', response.data.refresh_token);
                            localStorage.setItem('access_token_expires', response.data.access_token_expires)
                            activeAccount = false;
                            fetchUserData();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error.response.data.detail)
                        activeAccount = false;
                        sessionStorage.clear();
                    });
            }
            
        }

        const handleRedirect = () => {
            instance.loginRedirect({
                ...loginRequest,
                prompt: 'create'
            }).catch((error) => console.log(error))
        };
        return (
            <div className='xyz'>
                {/* <AuthenticatedTemplate>
                    {activeAccount ? (
                        <p> &nbsp; </p>
                    ) : null}
                </AuthenticatedTemplate> */}
                {/* <UnauthenticatedTemplate>
                    <button onClick={handleRedirect} className="microsoft-login">
                        <MsLogo/>
                        Login with Outlook
                    </button>
                </UnauthenticatedTemplate> */}

            </div>
        );

    };

    return (
        <div className="login-wrapper">
            <div className="main-content">
                <div className="logo">
                    <img src="images/logo/login.svg" alt="" />
                </div>

                <MsalProvider instance={instance}>
                    <WrappedView />
                </MsalProvider>

                {/* <div className="or-option"><hr /> <span>OR</span></div>*/}
                
                    <div className="email-field fields">
                        <p className='mb-2' style={{ padding: '0 0 0 10px' }}>Email</p>
                        <input type="email" inputMode='email' name="email" value={email} onChange={changeHandler} onKeyDown={handleKeyDown} className="login-input placeholder-color" placeholder='Email' />
                        <p className="error-message">{emailError}</p>
                    </div>
                    {isOtpSent ? (
                        <div className=" otp-field fields">
                            <p className='mb-2' style={{ padding: '0 0 0 10px' }}>OTP</p>
                            <input type="text" inputMode='numeric' name='otp' value={otp} onChange={changeHandler} onKeyDown={handleKeyDown} className="login-input placeholder-color" maxLength={6} placeholder='OTP' />
                            <p className="error-message">{otpError}</p>
                            <p className="success-message">{otpSuccess}</p>
                            <a href="#" onClick={handleResendOtp}>Resend OTP</a>
                        </div>
                    ) : null}
                    <div className="text-center">
                        {!isOtpSent ? (
                            <button onClick={handleSubmit} className="submit-btn">Send OTP</button>
                        ) : (<button onClick={handleSubmit} className="submit-btn">Login</button>)}
                    </div>
                
            </div>
        </div>
        
    );
};
export default Login;
