import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';

ChartJs.register(Tooltip, Title, ArcElement, Legend);

const data = {
  datasets: [{
    data: [10, 20, 30],
    backgroundColor: [
      '#022560',
      '#5199c3',
      '#2c9f7a',
      '#610664',
      '#a87c0a',
      '#720809',
      '#48c9d8',
      '#89a06f',
      '#8e8c8c'
    ]
  }],
  labels: [
    'Orange',
    'Yellow',
    'Blue'
  ],
};

export default function ReactDoughnut() {
  const [data, setData] = useState({
    datasets: [{
      data: [7, 16, 4, 3, 2, 1, 1, 2, 5],
      backgroundColor: [
        '#022560',
        '#5199c3',
        '#2c9f7a',
        '#610664',
        '#a87c0a',
        '#720809',
        '#48c9d8',
        '#89a06f',
        '#8e8c8c'
      ]
    }],
    labels: [
      'Delhi',
      'Maharashtra',
      'Rajasthan',
      'Madhya Pradesh',
      'Uttar Pradesh',
      'Karnataka',
      'Haryana',
      'Telangana',
      'Others'
    ],
  });


  useEffect(() => {
    const fetchData = () => {
      fetch(
        // 'https://jsonplaceholder.typicode.com/users'
      )
        .then((data) => data.json())
        .then((res) => {
          console.log("resss", res)
          const label = [];
          const data = [];
          for (let i of res) {
            label.push(i.name);
            data.push(i.id)
          }
          setData({
            datasets: [{
              data: data,
              backgroundColor: [
                '#022560',
                '#5199c3',
                '#2c9f7a',
                '#610664',
                '#a87c0a',
                '#720809',
                '#48c9d8',
                '#89a06f',
                '#8e8c8c'
              ]
            }],
            labels: label,
          });
        })
        .catch(e => {
          console.log("error", e)
        })
    }
    fetchData();
  }, []);

  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };


  return (
    <div
      className="" style={{ width: '350px', height: '350px' }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
}

