import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import useUserRoleEffect from '../../components/userRoleEffect';
import Loader from '../../components/loader';
import BrightcoveVideo from '../../components/BrightcoveVideo';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent'
import { CommonTitle } from '../../components/commonComponents';

export default function ContentAddUpdate() {
  const navigate = useNavigate();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const access_token = localStorage.getItem('access_token');
  const userObj = JSON.parse(localStorage.getItem('userObj'));
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [pageType, setPageType] = useState('Add');
  const [disabledStatusList, setDisabledStatusList] = useState([]);

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const [mediaUrl, setMediaUrl] = useState('');
  const [mediaUrlError, setMediaUrlError] = useState('');


  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const [selectedVideo, setSelectedVideo] = useState(null);

  const [statusID, setStatusID] = useState('');

  const [metaTags, setMetaTags] = useState('');

  const [createdBy, setCreatedBy] = useState(0);
  const [statusUpdatedBy, setStatusUpdatedBy] = useState(0);

  const [statusList, setStatusList] = useState('');

  const [sectionId, setSectionId] = useState('');
  const [sections, setSections] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const sorting = ({ field: 'id', type: 'asc' });

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  useEffect(() => {

    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const loadAllSections = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {
        name: '',
        without: process.env.REACT_APP_ARICAL_SECTION_ID,
      },
      sorting: { field: 'order_index', type: 'asc' },
    };
    HTTP.post('/sections/list', fields, config)
      .then((response) => {
        if (response.status === 200) {
          setSections(response.data);
        }
        if (response.status === 204) {
          Swal.fire('', 'Roles Not Found', 'error');
        }
      })
      .catch((error) => {
        setRerenderFlag(true);
      })
  }

  const loadAllStatus = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {

      },
      sorting: sorting,
    };
    HTTP.post('/content_status_master/list', fields, config)
      .then((response) => {
        if (response.status === 200) {
          setStatusList(response.data)
        }
        if (response.status === 204) {
          Swal.fire('', 'Status Not Found', 'error');
        }
      })
      .catch((error) => {
        setRerenderFlag(true);
      })
  };

  const loadContentData = async () => {
    try {
      setIsLoading(true)
      let config = {
        headers: { 'Content-Type': 'application/json', 'token': access_token },
      }
      await HTTP.get('content/' + id, config)
        .then((response) => {
          if (response.status === 200) {
            setTitle(response.data.content.title)
            setMediaUrl(response.data.content.media_url)
            setStatusID(response.data.content.status_id)
            setDescription(response.data.content.description)
            setMetaTags(response.data.content.meta_tags)
            setCreatedBy(response.data.content.created_by)
            setStatusUpdatedBy(response.data.content.status_changed_by)
            setSectionId(response.data.content.section_id)
            setStatusDisableList()

            const accountId = process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID;
            const videoId = response.data.content.media_url;
            const brightcove_url = `https://players.brightcove.net/pages/v1/index.html?accountId=${accountId}&playerId=default&videoId=${videoId}&autoplay=true`;
            setSelectedVideo(brightcove_url)
          }
          if (response.status === 204) {
            Swal.fire('', 'Content Not Found', 'error');
          }
        })
        .catch((error) => {
          setError(error)
          setRerenderFlag(true);
        })
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setStatusDisableList();
  }, [createdBy]);

  const setStatusDisableList = () => {
    let content_created_by = createdBy;
    let login_user_id = parseInt(userObj.id);
    let login_user_role = parseInt(userObj.role.id);
    let status_changed_by = statusUpdatedBy
    let disabledSList = [];
    if (Array.isArray(statusList)) {
      statusList.forEach(function (status) {
        let status_id = status.id;
        let next_status_id = statusID + 1;
        if (status_id == 2) {//Reviewed
          if (login_user_role == 4 || (login_user_role == 3 && status_changed_by == login_user_id)) {//Creator
            disabledSList.push(status_id);
          }
        }
        if (status_id == 3) {//Published
          if (login_user_role == 1) {//Admin

          } else if (login_user_role == 2) {//Publisher
          }
          else if (login_user_role == 3) {//Reviewer
            disabledSList.push(status_id);
          } else if (login_user_role == 4) {//Creator
            disabledSList.push(status_id);
          }
        }
        if (status_id == 4) {//UnPublished
          if (login_user_role == 1) {//Admin

          } else if (login_user_role == 2) {//Publisher
            disabledSList.push(status_id);
          } else if (login_user_role == 3) {//Reviewer
            disabledSList.push(status_id);
          } else if (login_user_role == 4) {//Creator
            disabledSList.push(status_id);
          }
        }
        if (status_id < statusID) {//Can't select previous status
          disabledSList.push(status_id);
        }
        if (status_id > next_status_id) {
          disabledSList.push(status_id);
        }
        if (login_user_role == 5 || login_user_id == content_created_by) {
          disabledSList.push(status_id);
        }
      });
    }
    disabledSList = [...new Set(disabledSList)];

    setDisabledStatusList(disabledSList)
  }

  const showSubmitButton = () => {
    let content_created_by = createdBy;
    let login_user_id = parseInt(userObj.id);
    let user_role = parseInt(userObj.role.id);
    let status_id = statusID;
    let cid = id;

    if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
      return true;
    }
    if (user_role === 1) {
      return true;
    } else if (user_role === 4) {
      return status_id == 1 && login_user_id === content_created_by;
    } else if (user_role === 3 || user_role === 2) {
      return (login_user_id === content_created_by && status_id == 1) || login_user_id !== content_created_by;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadAllSections();
    loadAllStatus();
    let cid = id;
    if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
      setStatusID('1');
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setPageType('Update')
      loadContentData();
      setIsLoading(false);
    }
    setIsLoading(false)
  }, []);

  const resetValidationErrors = () => {
    setTitleError('');
    setMediaUrlError('');
    setDescriptionError('');
  };

  const validateField = () => {

    resetValidationErrors();

    let checked = true;
    if (title === null || title === '') {
      setTitleError('Please enter headline');
      if (checked) {
        checked = false;
      }
    } else if (title.length < 5) {
      setTitleError("Please enter minimum 5 characters");
      if (checked) {
        checked = false;
      }
    } else if (title.length > 200) {
      setTitleError("Max character limit is 200");
      if (checked) {
        checked = false;
      }
    }

    if (description=== null || description === '') {
      setDescriptionError('Please enter intro');
      if (checked) {
        checked = false;
      }
    } else if (description.length < 5) {
      setDescriptionError("Minimum 5 character required");
      if (checked) {
        checked = false;
      }
    }else if (description.length > 500) {
      setDescriptionError("Max character limit 500");
      if (checked) {
        checked = false;
      }
    }
    
    if (mediaUrl === '' || mediaUrl == null) {
      setMediaUrlError('Please enter Video Id');
      if (checked) {
        checked = false;
      }
    }else if (mediaUrl.length != 13) {
      setMediaUrlError("Required character should be 13");
      if (checked) {
        checked = false;
      }
    }

    return checked;
  };

  const handleOptionChange = (value) => {
    if (!disabledStatusList.includes(value)) {
      setStatusID(value);
    }
  };


  const handleSubmit = (e) => {

    e.preventDefault();
    if (validateField()) {
      let config = {
        headers: { 'Content-Type': 'multipart/form-data', 'token': access_token },
      }
      let cid = id;

      if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
        cid = 0
      }

      let formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("media_url", mediaUrl);
      formData.append("status_id", statusID);
      formData.append("meta_tags", metaTags);

      if (sectionId) {
        formData.append("section_id", sectionId);
      }
      if (selectedVideo) {
        formData.append("media_file", selectedVideo);
      }

      setIsLoading(true)
      HTTP.put('content/create/update/' + cid, formData, config)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire('', response.data.message, 'success');
            navigate('/content/list')
          }

          if (response.status === 204) {
            Swal.fire('', 'Content Not Found', 'error');
          }

          if (response.status === 403) {
            Swal.fire('', response.data.content, 'error');
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
          let cid = id;
          if (cid !== 'add' || cid !== 'undefined' || cid !== undefined || cid !== ':id') {
            loadContentData();
          }

        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
  }, [isLoading]);

  const handleBack = () => {
    navigate('/content/list');
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className='d-flex justify-content-between'>
        {/* <h2 style={{ fontSize: '25px' }}>{pageType} Video</h2> */}
        <CommonTitle PageTitle='Videos'/>
        <Link to='/content/list'>
          <span className='video-page-icons'>
          </span>
          <span>
            Back
          </span>
        </Link>
      </div>
      <div className="post-upload-wrapper">
        <div className="post-upload-main row">
          <div className="headline col-xl-12 my-2">
            <label htmlFor='name'>Head Line</label>
            <input type="text" id="name" className="form-input" value={title} onChange={(e) => { setTitle(e.target.value) }} />
            <span className="error-message">{titleError}</span>
          </div>
          <div className="intro col-xl-12 my-2">
            <label htmlFor='description'>Intro</label>
            <textarea className="form-input" id="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            {descriptionError && <span className="error-message">{descriptionError}</span>}
          </div>
          <div className="col-xl-6 col-lg-6 mg-btm">
            <label htmlFor='video_id'> Video ID</label>
            <input type="text" id="video_id" className="form-input" value={mediaUrl} onChange={(e) => { setMediaUrl(e.target.value) }} />
            {selectedVideo !== '' && selectedVideo !== 'undefined' && selectedVideo !== undefined && selectedVideo !== null ? (
              <BrightcoveVideo videoId={mediaUrl} />
            ) : (
              null
            )}
            <span className="error-message">{mediaUrlError}</span>
          </div>
          <div className="col-lg-6 mg-btm">
            <label htmlFor="section_id">
              Section
            </label>
            <select name="section_id" id="section_id" className="form-input" onChange={(e) => {
              setSectionId(e.target.value);
            }} value={sectionId}>
              <option key="" value="">Select</option>
              {Array.isArray(sections) && sections.length > 0 ? (
                sections.map((section) => (
                  <option key={section.id} value={section.id}>{section.name}</option>
                ))
              ) : (
                null
              )}
            </select>
          </div>

          {id !== 'add' && id !== 'undefined' && id !== undefined && id !== ':id' ? (
            <div className="col-lg-6 mg-btm ">
              <label htmlFor="status_id">
                Status
              </label>
              <div>
                <div className="radio-group">
                  {Array.isArray(statusList) && statusList.length > 0 ? (
                    statusList.map((statusOption) => (
                      <div key={statusOption.id} className="radio-option">
                        <input type="radio"
                          id={`status_${statusOption.id}`}
                          name="status_id"
                          value={statusOption.id}
                          onChange={() => handleOptionChange(statusOption.id)}
                          checked={statusID === statusOption.id}
                          disabled={disabledStatusList.includes(statusOption.id)}
                        />
                        <label htmlFor={`status_${statusOption.id}`} className={statusID === statusOption.id ? 'selected' : ''} onChange={() => handleOptionChange(statusOption.id)}>
                          {statusOption.name}
                        </label>
                      </div>
                    ))
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>
          ) : (
            null
          )}
          <div className="col-xl-12 my-3">
            <label htmlFor='meta_tags'>Meta Tags</label>
            <input type="text" id='meta_tags' className="form-input" value={metaTags} onChange={(e) => setMetaTags(e.target.value)} />
          </div>
          <div className="d-flex justify-content-center">
            {showSubmitButton() && (<button type="submit" className="form-submit button-design" onClick={handleSubmit}>Submit</button>)}
            &nbsp;
            <button className="form-submit button-design" type="button" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
