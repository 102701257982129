import React from 'react';

const SearchBar = ({ showHeaderTitle }) => {

  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-9 pl-0">  

        <span className="viewer-searchbar-wrapper">
          <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i> </span>
          <span className="viewer-searchfield ms-2"><input type="text" placeholder="Search here..." /></span>
        </span>

        <div className="header-title" style={{ display: showHeaderTitle ? "block" : "none" }}>
          <h2> Article</h2>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
