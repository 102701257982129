import React, { useState, useEffect } from "react";
import { HTTP } from '../api/http-common';
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "./loader";
import ErrorHandlingComponent from "./ErrorHandlingComponent";

const ArticleSliderComponent = () => {
    const access_token = (localStorage.getItem('access_token'));
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);

    const loadArticles = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                head_line: '',
                is_active: true,
            },
            pagination: { page: '1', limit: '5' },
            sorting: { field: 'id', type: 'desc' },
        };
        HTTP.post('article/list', fields, config)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setArticles(data.contents);
                }
                if (response.status === 204) {
                    setArticles([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
    };
    useEffect(() => {
        loadArticles();
    }, []);

    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="small-video-main-slab">
                {/* main div */}
                <div className="border-bottom  border-light-subtle">
                    <span className="slab-title  d-inline-block">
                        Articles
                    </span>
                </div>
                {/* title */}
                <div className="resume small-videos-wrapper row" id="latest">
                    {Array.isArray(articles) && articles.length > 0 ? (
                        articles.map((article) => (
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6  mobile-slider-width " key={article.id}>
                                {/* sub section */}
                                <div className="image-container">
                                    <span className="article-description-button">
                                        <Link to={`/article/details/${article.id}`}>
                                            <FaArrowAltCircleRight />
                                        </Link>
                                    </span>
                                    {/* sub section */}
                                    <div className="image-container">
                                        <span className="article-description-button">
                                            <Link to={`/article/details/${article.id}`}>
                                                <FaArrowAltCircleRight />

                                            </Link>
                                        </span>
                                        <div className="small-video-thumbnails-description">
                                            <span className="small-thumbnail-title d-flex align-items-center ">
                                                <p className="m-0"> {article.head_line} </p>
                                            </span>
                                        </div>

                                        {Array.isArray(article.media_list) && article.media_list.length > 0 ? (
                                            article.media_list.map((media) => (
                                                media.media_path_url !== '' && media.media_type.includes('image') ? (
                                                    <img
                                                        key={media.id}
                                                        src={media.media_path_url}
                                                        alt=''
                                                    />
                                                ) : (
                                                    <img key="img_id" src="images/slHCGF5fLv.webp" alt={media.media_path_url} />
                                                )
                                            ))
                                        ) : (
                                            null
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>
                            No articles found
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ArticleSliderComponent
