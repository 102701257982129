import React from "react";
import UserProfile from "./UserProfile";
import { Link } from "react-router-dom";

export default function ViewerHeader() {

  return (
    <div className="viewer-header-wrapper row align-items-center justify-content-between " id="start">
    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-11 d-flex p-0 viewer-header-top align-items-center">
      <span className="logo-wrapper d-block">
        <Link to="/dashboard">
          <img src="/images/logo/gsa.svg" alt="" />
        </Link>
      </span>
      <span className="viewer-searchbar-wrapper">
        <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i> </span>
        <span className="viewer-searchfield"><input type="text" placeholder="Search here..."/></span>
      </span>
    </div>
            <UserProfile />
    </div>
  );
}
