import React, { useState, useEffect } from 'react';
import { IoListSharp, IoSearch } from "react-icons/io5";
import {useNavigate } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import useUserRoleEffect from '../../components/userRoleEffect';
import withReactContent from 'sweetalert2-react-content';
import ParticipationListUI from '../../components/ParticipationListUI';
import { CommonTitle } from '../../components/commonComponents';

export default function Participation() {
    const navigate = useNavigate();
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [search, setSearch] = useState({ name: '', selection_status_id: null, aa_master_id: null, temp_status_id: null });
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({ page: '1', limit: '10' });
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [participations, setParticipations] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [aaMasters, setAaMasters] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectionCount, setSlectionCount] = useState(0);
    const [selectedStatusCount, setSelectedStatusCount] = useState(0);
    const [rejectedStatusCount, setRejectedStatusCount] = useState(0);
    const [waitiongStatusCount, setWaitingStatusCount] = useState(0);
    const MySwal = withReactContent(Swal);

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole)

    const loadParticipations = async () => {
        setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                name: search.name,
                selection_status_id: search.selection_status_id,
                aa_master_id: search.aa_master_id,
                temp_status_id: search.temp_status_id,
            },
            pagination: pagination,
            sorting: { field: 'id', type: 'desc' },
        };
        HTTP.post('amazing_adanians/participation/list', fields, config)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setParticipations(data.contents);
                    const participantIds = data.contents.map(participation => participation.id);
                    setParticipants(participantIds);
                    setTotal(data.total_count);
                }
                if (response.status === 204) {
                    setParticipations([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadStatuses = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                name: '',
            },
            sorting: { field: 'order_index', type: 'asc' },
        };
        HTTP.post('amazing_adanians/selection/status/list', fields, config)
            .then((response) => {
                if (response.status === 200) {
                    setStatuses(response.data);
                }
                if (response.status === 204) {
                    Swal.fire('', 'Selection Statuses Not Found', 'error');
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
    }

    const loadUserData = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        HTTP.get('user/' + userObj.id, config)
            .then((response) => {
                if (response.status === 200) {
                    setSlectionCount(response.data.content.selection_count)
                }
                if (response.status === 204) {
                    Swal.fire('', 'User Not Found', 'error');
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const loadAaMaster = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: { name: '' },
            pagination: {},
            sorting: { field: 'id', type: 'asc' }
        }
        HTTP.post('/amazing_adanians/master/list', fields, config)
            .then((response) => {
                if (response.status === 200) {
                    setAaMasters(response.data);
                }
                if (response.status === 204) {
                    Swal.fire('', 'Amazing Adanians master Not Found', 'error');
                }
            })
            .catch((error) => {
                setError(error);
            })
    }


    const calculateSubmissionStatus = async () => {
        // setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        HTTP.post('amazing_adanians/participation/judge/ppstatus/count', {}, config)
            .then((response) => {
                    setWaitingStatusCount(response.data.content.wfr)
                    setSelectedStatusCount(response.data.content.selected)
                    setRejectedStatusCount(response.data.content.rejected)
            })
            .catch((error) => {
                // setError(error)
                // setRerenderFlag(true);
            })
            .finally(() => {
                // setIsLoading(false);
            });
    };

    const handleFinalSubmission = () => {
        MySwal.fire({
            title: 'Are you sure you want final submission?',
            text: 'After final submission you can not update status of any participants',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                if (selectionCount == selectedStatusCount) {
                    setIsLoading(true)
                    let config = {
                        headers: { 'Content-Type': 'application/json', 'token': access_token },
                    }
                    let fields = {
                        participation_ids: participants
                    }
                    HTTP.put('/amazing_adanians/participation/final_submission', fields, config)
                        .then((response) => {
                            if (response.status === 200) {
                                Swal.fire('', response.data.message, 'success');
                            }
                        })
                        .catch((error) => {
                            setError(error)
                            setRerenderFlag(true);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    let message = '<p><strong>You Slected</strong></p>';
                    message += `<p><strong>Waiting for review:</strong> ${waitiongStatusCount}</p>`
                    message += `<p><strong>Selected:</strong> ${selectedStatusCount}</p>`
                    message += `<p><strong>Rejected:</strong> ${rejectedStatusCount}</p>`

                    Swal.fire({
                        title: `Required selected participants ${selectionCount}`,
                        html: message,
                        icon: "error",
                    });
                }
            }
        })
    }

    useEffect(() => {
        loadParticipations();
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag, search, pagination]);



    useEffect(() => {
        // const waitingCount = participations.filter(participation => participation.temp_status_id == 1).length
        // setWaitingStatusCount(waitingCount)
        // const selectedCount = participations.filter(participation => participation.temp_status_id == 2).length
        // setSelectedStatusCount(selectedCount)
        // const rejectedCount = participations.filter(participation => participation.temp_status_id == 3).length
        // setRejectedStatusCount(rejectedCount)

        calculateSubmissionStatus();
    }, [participations]);



    const onPaginationChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const onLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPagination({ ...pagination, limit: newLimit, page: 1 });
    };

    const onSearchEnter = (value) => {
        if (value.length == 0 || value.length >= 3) {
            setSearch({ ...search, name: value });
            setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
        }
    };

    const handleStatusChange = (selection_status_id) => {
        if (participations.is_final_submissoin_done) {
            setSearch({ ...search, selection_status_id: selection_status_id });
        } else {
            setSearch({ ...search, temp_status_id: selection_status_id });
        }
    };

    const handleAaMasterChange = (aa_master_id) => {
        setSearch({ ...search, aa_master_id: aa_master_id })
    }

    useEffect(() => {
        loadStatuses();
        loadAaMaster();
    }, []);

    useEffect(() => {
        if (userRole == 6) {
            loadUserData();
        }
    }, [search.aa_master_id]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="article-wrapper">
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            {userRole == 6 && <span className='participation-header'>
                {/* <h2 style={{ fontSize: '25px' }}>Participation List</h2> */}
                <CommonTitle PageTitle='Participation List'/>
                <p>Required Selected Participant: {selectionCount} : Play video for at least 5 seconds.</p>
                {/* <p>You must play the video for at least 20 seconds before changing status.</p> */}
            </span>}
            <div className="video-table-wrapper">
                <div className="action-buttons-wrapper row">
                    <div className="left-button col-lg-6 col-md-3 col-6">
                        <button className="video-table-button-structure bg-secondary" style={{ padding: '7px 25px' }}>
                            <span>
                                <span className='video-page-icons'> <IoListSharp /></span>
                                <span>List </span>
                            </span>
                        </button>
                    </div>
                    {userRole == 6 &&
                        <div className=" col-lg-6 col-md-9 col-6 ">
                            <span className='action-buttons'>
                                {participations.some(participation => participation.is_final_submission_done) ? (
                                    <a className="final-submission-button video-table-button-structure bg-blue " disabled>
                                        Final Submission
                                    </a>
                                ) : (
                                    <a className="final-submission-button video-table-button-structure bg-blue " onClick={handleFinalSubmission}>
                                        Final Submission
                                    </a>
                                )}
                            </span>
                        </div>
                    }

                </div>
                <div className="video-table-main">
                    <div className="timeline-actions-wrapper row justify-content-between align-items-center">
                        <div className='col-xl-5 col-lg-5 col-md-6'>
                            <span className=' d-flex border border-secondary p-1 border-radius align-items-center'>
                                <span><IoSearch /></span>
                                <span className='viewer-searchfield'>
                                    <input type='text' placeholder={`Search and press enter`} className='border-0 inner-searchbar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter(e.target.value) } }} />
                                </span>
                            </span>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-6 d-flex align-items-center video-table-dropdown p-0'>
                            <span>
                                <label htmlFor="statusSelect" className='pe-2'> Status:</label>
                                <select name="status" id="statusSelect" className="border-secondary  p-1  form-input status-input" onChange={(e) => {
                                    handleStatusChange(e.target.value);
                                }} value={participations.is_final_submissoin_done ? search.selection_status_id : search.temp_status_id}>
                                    <option key="" value="">All</option>
                                    {Array.isArray(statuses) && statuses.length > 0 ? (
                                        statuses.map((status) => (
                                            <option key={status.id} value={status.id}>{status.name}</option>
                                        ))
                                    ) : (
                                        null
                                    )}
                                </select>
                            </span>
                            <span>
                                <label htmlFor="aaMaster" className='pe-2 no-wrap'> Amazing Adanians:</label>
                                <select name="aaMaster" id="aaMaster" className="border-secondary  p-1  form-input" onChange={(e) => {
                                    handleAaMasterChange(e.target.value);
                                }} value={search.aa_master_id}>
                                    <option key="" value="">All</option>
                                    {Array.isArray(aaMasters.contents) && aaMasters.contents.length > 0 ? (
                                        aaMasters.contents.map((aaMaster) => (
                                            <option key={aaMaster.id} value={aaMaster.id}>{aaMaster.name}</option>
                                        ))
                                    ) : (
                                        null
                                    )}
                                </select>
                            </span>
                        </div>
                    </div>

                    {Array.isArray(participations) && participations.length > 0 ? (
                        participations.map((participation) => (
                            <ParticipationListUI
                                key={participation.id}
                                participation={participation}
                                statusList={statuses}
                                handleReload={loadParticipations}
                            />
                        ))
                    ) : (
                        <p className='text-center mt-4'>
                            No participant found
                        </p>
                    )}
                </div>
                <div>
                    {Array.isArray(participations) && participations.length > 0 ? (
                        <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
                    ) : (
                        null
                    )}
                </div>
            </div>
        </div>
    )
}