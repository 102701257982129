import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { HTTP } from "../../api/http-common";
import Swal from "sweetalert2";
import Loader from "../../components/loader";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorHandlingComponent from "../../components/ErrorHandlingComponent";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Select from "react-select";
import {
  EndTimeIcon,
  StartTimeIcon,
} from "../../components/SideBarIcons";
import useUserRoleEffect from "../../components/userRoleEffect";
import { CommonTitle } from "../../components/commonComponents";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function EpisodeAdd() {
  const navigate = useNavigate();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const access_token = localStorage.getItem("access_token");
  const userObj = JSON.parse(localStorage.getItem("userObj"));
  const [error, setError] = useState(null);
  const [pageType, setPageType] = useState("Add");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDatetime, setStartDatetime] = useState(new Date());
  const [startDatetimeError, setStartDatetimeError] = useState("");
  const [endDatetime, setEndDatetime] = useState(new Date());
  const [endDatetimeError, setEndDatetimeError] = useState("");
  const [videoID, setVideoID] = useState("");
  const [videoIDError, setVideoIDError] = useState("");
  const [participantsCount, setParticipantsCount] = useState("");
  const [participantsCountError, setParticipantsCountError] = useState("");
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [participationIDs, setParticipationIDs] = useState([]);
  const [participationVoteData, setParticipationVoteData] = useState([]);
  const [isVotingLineOpen, setIsVotingLineOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { aamasterid, episodeid } = useParams();
  const [aaname, setAaName] = useState("");
  const [aaStartDatetime, setAaStartDatetime] = useState();
  const [aaEendDatetime, setAaEndDatetime] = useState();
  const [registrationStartDatetime, setRegistrationStartDatetime] = useState();
  const [registrationEndDatetime, setRegistrationEndDatetime] = useState();
  const [visible, setVisible] = useState(false);
  const [videoSubmissionStartDatetime, setVideoSubmissionStartDatetime] =
    useState();
  const [videoSubmissionEndDatetime, setVideoSubmissionEndDatetime] =
    useState();

  const [voteCounts, setVoteCounts] = useState([]);
  

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const handleVotingLineCheckboxChange = () => {
    setIsVotingLineOpen(!isVotingLineOpen);
  };

  const handleActiveCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const handleVoteCountClick = () => {
    setVisible(!visible);
  };

  const loadAaMasterData = async () => {
    try {
      setIsLoading(true);
      let config = {
        headers: { "Content-Type": "application/json", token: access_token },
      };
      HTTP.get("amazing_adanians/master/" + aamasterid, config)
        .then((response) => {
          if (response.status === 200) {
            setAaName(response.data.content.name);
            setAaStartDatetime(response.data.content.start_datetime);
            setAaEndDatetime(response.data.content.end_datetime);
            setRegistrationStartDatetime(
              response.data.content.registration_start_datetime
            );
            setRegistrationEndDatetime(
              response.data.content.registration_end_datetime
            );
            setVideoSubmissionStartDatetime(
              response.data.content.video_submission_start_datetime
            );
            setVideoSubmissionEndDatetime(
              response.data.content.video_submission_end_datetime
            );
          }
          if (response.status === 204) {
            Swal.fire("", "Amazing Adanians Master Not Found");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const loadVoteCounts = async () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "application/json", token: access_token },
    };
    let fields = {
      episode_id: episodeid
    }
    HTTP.post('vote/count', fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setVoteCounts(data.contents);
        }
      })
      .catch((error) => {
        setError(error)
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const loadEpisodeData = async () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "application/json", token: access_token },
    };
    HTTP.get(`amazing_adanians/episode/master/${episodeid}`, config)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.content.selected_participants_data)
          setParticipationVoteData(response.data.content.selected_participants_data)
          const {
            name,
            start_datetime_utc,
            end_datetime_utc,
            video_id,
            participants_count,
            is_voting_line_open,
            selected_participants,
            selected_participants_data,
            participants,
            is_active
          } = response.data.content;
          setName(name);
          setStartDatetime(start_datetime_utc);
          setEndDatetime(end_datetime_utc);
          setVideoID(video_id);
          setParticipantsCount(participants_count);
          setIsVotingLineOpen(is_voting_line_open);
          setIsActive(is_active)
          
          const allParticipants = participants.concat(selected_participants);
          const formattedParticipants = allParticipants.map((participant) => ({
            value: participant.id,
            label: participant.name,
          }));
          setParticipants(formattedParticipants);
          const formattedSelectedParticipants = selected_participants.map(
            (participant) => ({
              value: participant.id,
              label: participant.name,
            })
          );
          setSelectedParticipants(formattedSelectedParticipants);
          const participant = formattedSelectedParticipants.map(
            (participant) => participant.value
          );
          setParticipationIDs(participant);
        }
        if (response.status === 204) {
          Swal.fire("", "Episode Not Found");
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadAaMasterData();
    let cid = episodeid;
    if (
      cid === "add" ||
      cid === "undefined" ||
      cid === undefined ||
      cid === ":id"
    ) {
      loadParticipants();
    } else {
      setPageType("Update");
      loadEpisodeData();
      loadParticipants();
      loadVoteCounts();
    }
  }, [episodeid]);

  const resetValidationErrors = () => {
    setNameError("");
    setStartDatetimeError("");
    setEndDatetimeError("");
    setVideoIDError("");
    setParticipantsCountError("");
  };

  const validateField = () => {
    resetValidationErrors();

    let checked = true;
    if (name === "") {
      setNameError("Please enter name");
      checked = false;
    } else if (name.length < 5) {
      setNameError("Minimum 5 character required");
      checked = false;
    } else if (name.length > 50) {
      setNameError("Max character limit 50");
      checked = false;
    }

    if (!startDatetime) {
      setStartDatetimeError("Select start datetime");
      checked = false;
    }
    if (!endDatetime) {
      setEndDatetimeError("Select end datetime");
      checked = false;
    }
    if (videoID === '') {
      setVideoIDError('Please enter video id');
      checked = false;
    } else if (videoID.length != 13) {
      setVideoIDError("Required character should be 13");
      if (checked) {
        checked = false;
      }
    }
    if (participantsCount === "") {
      setParticipantsCountError("Please enter participant count");
      checked = false;
    }
    return checked;
  };

  const handleChange = (selectedParticipants) => {
    setSelectedParticipants(selectedParticipants);
    const participant = selectedParticipants.map(
      (participant) => participant.value
    );
    setParticipationIDs(participant);
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const loadParticipants = async () => {
    setIsLoading(true)
    let config = {
      headers: { "Content-Type": "application/json", token: access_token },
    };
    let fields = {
      filters: {
        name: "",
        selection_status_id: "2",
        aa_master_id: aamasterid,

      },
      pagination: { page: "1", limit: "100" },
      sorting: { field: "id", type: "desc" },
    };
    HTTP.post("amazing_adanians/participation/list", fields, config)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.contents && Array.isArray(data.contents)) {
            const participantsWithNoEpisode = data.contents.filter(
              (participant) => participant.episode_id === null && participant.video_id !== null
            );
            const formattedParticipants = participantsWithNoEpisode.map(
              (participant) => ({
                value: participant.id,
                label: participant.name,
              })
            );
            setParticipants(formattedParticipants);
          } else {
            setParticipants([]);
          }
        }
        if (response.status === 204) {
          setParticipants([]);
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateField()) {
      let config = {
        headers: { "Content-Type": "application/json", token: access_token },
      };

      let cid = episodeid;
      if (
        cid === "add" ||
        cid === "undefined" ||
        cid === undefined ||
        cid === ":id"
      ) {
        cid = 0;
      }
      let fields = {
        name: name,
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        video_id: videoID,
        aa_master_id: aamasterid,
        participants_count: participantsCount,
        is_voting_line_open: isVotingLineOpen,
        participant_id: participationIDs,
        is_active: isActive,
      };
      setIsLoading(true);
      HTTP.put(
        `amazing_adanians/episode/master/create/update/${cid}`,
        fields,
        config
      )
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("", response.data.message, "success");
            if (participationIDs.length > participantsCount) {
              Swal.fire(
                "",
                "participants are more than participant count",
                "warning"
              );
            }
            navigate(`/amazingadaniansmaster/update/${aamasterid}`);
          } else if (response.status === 403) {
            Swal.fire("", response.data.content, "error");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
          loadEpisodeData();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleBack = () => {
    navigate(`/amazingadaniansmaster/update/${aamasterid}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}

      <>
        <div className="d-flex justify-content-between">
          {/* <h2 style={{ fontSize: "25px" }}>{pageType} Episode</h2> */}
          <CommonTitle PageTitle='Episode' />
          <Link to={`/amazingadaniansmaster/update/${aamasterid}`}>
            <span className="video-page-icons"></span>
            <span>Back</span>
          </Link>
        </div>
        {/* Start */}
        <div className="aavideo-content align-items-center row">
          <div className="col-lg-12 ">
            <h4 className="episode-update-title video-title">{aaname}</h4>
            {/* episode-info */}
            <div className="row episode-timeline-container">
              <div className="epidode-add-containter  sub-section-episode-detail">
                <div className="inner-episode-design  episode-timeline-section p-2">
                  <h5 className="sub-section-episode-detail-title">Duration</h5>

                  <div className=" ">
                    <span className="video-status episode-status episode-time-main">
                      <span className="d-flex align-items-center">
                        <StartTimeIcon color={"black"} size={"1rem"} />
                        <b>Start: </b>
                      </span>
                      <span>{aaStartDatetime}</span>
                    </span>

                    <span className="video-status episode-status episode-time-main">
                      <span className="d-flex align-items-center">
                        <EndTimeIcon color={"black"} size={'1rem'} />
                        <b>End: </b>
                      </span>
                      <span>{aaEendDatetime} </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="epidode-add-containter sub-section-episode-detail">
                <div className="inner-episode-design  episode-timeline-section p-2">
                  <h5 className="sub-section-episode-detail-title">
                    Registration
                  </h5>

                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b> Start: </b>
                    </span>
                    <span>{registrationStartDatetime}</span>
                  </span>

                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon
                        color={"black"}
                        size={"1rem"}
                        className="pe-1"
                      />
                      <b> End: </b>
                    </span>
                    <span>{registrationEndDatetime}</span>
                  </span>
                </div>
              </div>

              <div className="epidode-add-containter sub-section-episode-detail ">
                <div className="inner-episode-design  episode-timeline-section p-2">
                  <h5 className="sub-section-episode-detail-title">
                    Submission
                  </h5>

                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b> Start: </b>
                    </span>
                    <span>{videoSubmissionStartDatetime}</span>
                  </span>
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon
                        color={"black"}
                        size={"1rem"}
                        className="pe-1"
                      />
                      <b> End: </b>
                    </span>
                    <span> {videoSubmissionEndDatetime}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End */}
        <div className="post-upload-main row">
          <span className="d-flex justify-content-end">
            <button
              className="button-design p-1 me-2"
              onClick={handleVoteCountClick}
            > Vote Count {visible ? <IoIosArrowUp /> : <IoIosArrowDown />} </button>
          </span>
          <div className="headline col-xl-6 col-md-7 col-sm-8 my-3">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="form-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && <span className="error-message">{nameError}</span>}
          </div>
          <div className="headline col-xl-3 col-md-5 col-sm-4 my-3">
            <label htmlFor="video_id">Video ID</label>
            <input
              type="text"
              id="video_id"
              className="form-input"
              value={videoID}
              onChange={(e) => setVideoID(e.target.value)}
            />
            {videoIDError && (
              <span className="error-message">{videoIDError}</span>
            )}
          </div>

          <div className="headline col-xl-3 col-md-4 col-sm-4 my-3">
            <label htmlFor="count">Participants Count</label>
            <input
              type="text"
              id="count"
              className="form-input"
              value={participantsCount}
              onChange={(e) => setParticipantsCount(e.target.value)}
            />
            {participantsCountError && (
              <span className="error-message">{participantsCountError}</span>
            )}
          </div>

          <div className="headline col-xl-6 col-md-8 col-sm-8 my-3">
            <label htmlFor="status_id">Participants</label>
            <Select
              options={participants}
              isMulti
              value={selectedParticipants}
              onChange={handleChange}
              className="participant-select"
            />
          </div>

          <div className="headline col-xl-3 col-md-6 my-3 col-sm-6 date-time-picker">
            <label htmlFor="start_datetime" className="pe-2">
              Start Datetime
            </label>
            <DateTimePicker
              value={startDatetime}
              onChange={setStartDatetime}
              format="dd/MM/y h:mm a"
              className="episode-date"
            />
            {startDatetimeError && (
              <span className="error-message">{startDatetimeError}</span>
            )}
          </div>

          <div className="headline col-xl-3 my-3 col-sm-6">
            <label htmlFor="end_datetime" className="pe-2">
              End Datetime
            </label>
            <DateTimePicker
              value={endDatetime}
              onChange={setEndDatetime}
              format="dd/MM/y h:mm a"
              className="episode-date"
            />
            {endDatetimeError && (
              <span className="error-message">{endDatetimeError}</span>
            )}
          </div>

          <div className="row">
            <div className="col-xl-12  my-3 checkbox-wrapper">
              <label htmlFor="is_active">Is Voting Line Open</label>
              <input
                className="mx-2"
                type="checkbox"
                checked={isVotingLineOpen}
                onChange={handleVotingLineCheckboxChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12  my-3 checkbox-wrapper">
              <label htmlFor="is_active">Is Active</label>
              <input
                className="mx-2"
                type="checkbox"
                checked={isActive}
                onChange={handleActiveCheckboxChange}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="form-submit button-design"
              onClick={handleSubmit}
            >
              Submit
            </button>
            &nbsp;
            <button className="form-submit button-design" onClick={handleBack}>
              <span className="text-white">Back</span>
            </button>
          </div>
          <div className={`episodes-container row ${visible ? 'visible' : 'hidden'}`} >
            {voteCounts.length > 0
              ? voteCounts.map((vote) => (
                <div className="eps-content">
                  <div className=" episode-container inner-episode-design">
                    <div className="d-flex w-100">
                      <div className="inner-epsidoe-details">
                        <div className="row ">
                          <div className="col-xl-3 col-lg-6 col-sm-6 ">
                            <span className="video-status episode-status">
                              <span className="d-flex align-items-center">
                                <b>Name: </b>
                              </span>
                              <span>{vote.user_name} </span>
                            </span>
                          </div>
                          {vote.vote_points_by_judges ? (
                            <div className="col-xl-3 col-lg-6 col-sm-6 ">
                              <span className="video-status episode-status">
                                <span className="d-flex align-items-center">
                                  <b>Vote points by judges: </b>
                                </span>
                                <span>{vote.vote_points_by_judges} </span>
                              </span>
                            </div>
                          ) : null}
                          {vote.vote_points_by_users ? (
                            <div className="col-xl-3 col-lg-6 col-sm-6 ">
                              <span className="video-status episode-status">
                                <span className="d-flex align-items-center">
                                  <b>Vote points by users: </b>
                                </span>
                                <span>{vote.vote_points_by_users} </span>
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )) : null
            }
          </div>
          
          <div className='table-wrapper'>
            <div className='demo-download-wrapper'>
              <CommonTitle PageTitle='Votes'/>

            </div>
              {Array.isArray(participationVoteData) && participationVoteData.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Actual Score</th>
                      <th>Final Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participationVoteData.map((participation, index) => (
                      <tr key={participation.user_id}>
                        <td>{participation.name}</td>
                        <td>{participation.actual_final_score}</td>
                        <td>{participation.final_score}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No data available</p>
              )}
          </div>

        </div>
      </>
    </>
  );
}
