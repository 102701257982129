import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HTTP } from "../api/http-common";
import Loader from "../components/loader";
import "react-quill/dist/quill.snow.css";
import ErrorHandlingComponent from "../components/ErrorHandlingComponent";

export default function FAQs() {
  const queryString = window.location.search;

  const queryParams = new URLSearchParams(queryString);

  const inMobileDevice = queryParams.has('isMobileDevice');
  
  const [rerenderFlag, setRerenderFlag] = useState(false);
  // const [search, setSearch] = useState({ key: "FAQs" });
  // const [sorting, setSorting] = useState({ field: "id", type: "desc" });
  // const [pagination, setPagination] = useState({ page: "1", limit: "10" });
  const [error, setError] = useState(null);

  const [faq, setFaq] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadFaq = () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "application/json"},
    };
    let fields = {
      filters: {
        key: "FAQs" ,
      },
      pagination: { page: "1", limit: "10" },
      sorting: { field: "id", type: "desc" },
    };
    HTTP.post("static_info/list", fields, config)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.contents && data.contents.length > 0) {
            setFaq(data.contents[0].value);
            // setTotal(data.total_count);
          } else {
            setFaq("");
            // setTotal(0);
          }
        } else if (response.status === 204) {
          setFaq([]);
          // setTotal(0);
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadFaq();
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  useEffect(() => { }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  // function handleBack() {
  //   navigate("/");
  // }

  return (
    <>
      {error && <ErrorHandlingComponent err={error} />}
      <div className="container">

        {!inMobileDevice ? (
          <h2 className="text-center mt-3  page-title">FAQs</h2>
        ) : (
          null
        )}
        <>
          {!inMobileDevice ? (
            <div className="text-end">
              <Link to="/">Back</Link>
            </div>
          ) : (
            null
          )}
          <div className={`policies-wrapper border-radius ${inMobileDevice ? "mt-4 vh-100" : ""}`}>

            <div className="policies">
              <div
                className="ql-editor mt-16 w-[500px] h-[300px]"
                dangerouslySetInnerHTML={{ __html: faq }}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
}
