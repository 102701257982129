import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "./slider.css";
import { HTTP } from '../api/http-common';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ViewerHeader from "./ViewerHeader";
import VideoSliderComponent from "./VideoSliderComponent"
import ArticleSliderComponent from "./ArticleSliderComponent"
import HeroPosterSliderComponent from "./HeroPosterSliderComponent";
import ScrollProgress from "../pages/scroller";

const MySliderComponent = () => {
  const access_token = (localStorage.getItem('access_token'));
  const [sectionList, setSectionList] = useState([]);

  const loadSections = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {
        name: '',
        is_active: true,
        without: '',
      },
      sorting: { field: 'order_index', type: 'asc' },
    };
    HTTP.post('/sections/list', fields, config)
      .then((response) => {
        if (response.status === 200) {
          setSectionList(response.data);
        }
        if (response.status === 204) {
          Swal.fire('', 'Status Not Found', 'error');
        }
      })
      .catch((error) => {

      })

  };
  

  useEffect(() => {
    const sliderDots = document.querySelector(".slick-dots");
    if (sliderDots) {
      sliderDots.style.display = "flex";
    }
    loadSections();
  }, []);

  return (
    <>
      
      <ViewerHeader />
      <div className="content-page-main" >
        <HeroPosterSliderComponent />
      </div>
      <div className="small-section-wrapper">
            <ScrollProgress/>
        {Array.isArray(sectionList) && sectionList.length > 0 ? (
          sectionList.map((section) => (
            section.id != process.env.REACT_APP_ARICAL_SECTION_ID ? (
              <VideoSliderComponent key={section.id} section={section} />
            ) : (
              <ArticleSliderComponent key={section.id} />
            )
          ))
        ) : (
          null
        )}
      </div>
    </>
  );
};

export default MySliderComponent;
