import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import Loader from '../../components/loader';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';

export default function HeroPosterAddUpdate() {
    const navigate = useNavigate();
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [pageType, setPageType] = useState('Add');

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');

    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    const [isActive, setIsActive] = useState(false);

    const [isAa, setIsAa] = useState(false);

    const [buttonText, setButtonText] = useState('Click Me!');
    const [buttonTextError, setButtonTextError] = useState('');
    
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const [mediaUrl, setMediaUrl] = useState('');
    const [mediaUrlError, setMediaUrlError] = useState('');

    const [createdBy, setCreatedBy] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    const handleStatusCheckboxChange = () => {
        setIsActive(!isActive);
    };
    const handleButtonVisibleCheckboxChange = () => {
        setIsButtonVisible(!isButtonVisible);
    };

    const handleIsAaCheckboxChange = () => {
        setIsAa(!isAa);
    }

    useEffect(() => {
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag]);

    const loadHeroPosterData = async () => {
        try {
            setIsLoading(true)
            let config = {
                headers: { 'Content-Type': 'application/json', 'token': access_token },
            }
            HTTP.get('hero_poster/' + id, config)
                .then((response) => {
                    if (response.status === 200) {
                        setTitle(response.data.content.title)
                        setDescription(response.data.content.description)
                        setIsActive(response.data.content.is_active)
                        setButtonText(response.data.content.button_text)
                        setIsButtonVisible(response.data.content.is_button_visible)
                        setMediaUrl(response.data.content.media_url)
                        setCreatedBy(response.data.content.created_by)
                        setIsAa(response.data.content.is_aa)
                    }
                    if (response.status === 204) {
                        Swal.fire('', 'Hero poster Not Found', 'error');
                    }
                })
                .catch((error) => {
                    setError(error)
                    setRerenderFlag(true);
                })
        } finally {
            setIsLoading(false);
        }
    };

    const showSubmitButton = () => {
        let content_created_by = createdBy;
        let login_user_id = parseInt(userObj.id);
        let user_role = parseInt(userObj.role.id);
        let cid = id;

        if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
            return true;
        }
        if (user_role === 1) {
            return true;
        } else if (user_role === 4 || user_role === 2 || user_role === 3) {
            return login_user_id === content_created_by;
        } else {
            return false;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        let cid = id;
        if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setPageType('Update')
            loadHeroPosterData();
            setIsLoading(false);
        }
        setIsLoading(false)
    }, []);

    const resetValidationErrors = () => {
        setTitleError('');
        setDescriptionError('');
        setMediaUrlError('');
        setButtonTextError('');
    };

    const validateField = () => {
        resetValidationErrors();

        let checked = true;
        if (title === null || title === '') {
            setTitleError('Please enter title')
            if (checked) {
                checked = false;
            }
        } else if (title.length < 5) {
            setTitleError("Please enter minimum 5 characters");
            if (checked) {
                checked = false;
            }
        } else if (title.length > 200) {
            setTitleError("Max character limit is 200");
            if (checked) {
                checked = false;
            }
        }
        if (buttonText === null || buttonText === '') {
            setButtonTextError('Please enter text')
            if (checked) {
                checked = false;
            }
        } else if (buttonText.length < 2) {
            setButtonTextError("Please enter minimum 2 characters");
            if (checked) {
                checked = false;
            }
        } else if (buttonText.length > 10) {
            setButtonTextError("Max character limit is 10");
            if (checked) {
                checked = false;
            }
        }
        if (description === null || description === '') {
            setDescriptionError('Please enter description');
            if (checked) {
                checked = false;
            }
        } else if (description.length < 5) {
            setDescriptionError("Minimum 5 character required");
            if (checked) {
                checked = false;
            }
        } else if (description.length > 500) {
            setDescriptionError("Max character limit 500");
            if (checked) {
                checked = false;
            }
        }
        if (mediaUrl === null || mediaUrl === '') {
            setMediaUrlError('Please enter Video Id');
            if (checked) {
                checked = false;
            }
        } else if (mediaUrl.length != 13) {
            setMediaUrlError("Required character should be 13");
            if (checked) {
                checked = false;
            }
        }
        return checked;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateField()) {
            let config = {
                headers: { 'Content-Type': 'multipart/form-data', 'token': access_token },
            }
            let cid = id;

            if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
                cid = 0
            }

            let formData = new FormData();
            formData.append("title", title);
            formData.append("description", description);
            formData.append("media_url", mediaUrl);
            formData.append("button_text", buttonText);
            if (isButtonVisible) {
                formData.append("is_button_visible", isButtonVisible)
            }
            if (isActive) {
                formData.append("is_active", isActive)
            }
            if (isAa) {
                formData.append("is_aa", isAa)
            }
            setIsLoading(true)
            HTTP.put('hero_poster/create/update/' + cid, formData, config)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('', response.data.message, 'success');
                        navigate('/heroposter/list')
                    }

                    if (response.status === 204) {
                        Swal.fire('', 'Hero poster Not Found', 'error');
                    }

                    if (response.status === 403) {
                        Swal.fire('', response.data.content, 'error');
                    }
                })
                .catch((error) => {
                    setError(error);
                    setRerenderFlag(true);
                    loadHeroPosterData();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
    }, [isLoading]);

    const handleBack = () => {
        navigate('/heroposter/list');
    };

    if (isLoading) {
        return <Loader />
    };

    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="post-upload-wrapper">
                <div className='d-flex justify-content-between'>
                    {/* <h2 style={{ fontSize: '25px' }}>{pageType} Hero Poster</h2> */}
                    <CommonTitle PageTitle='Hero Poster'/>
                    <Link to='/heroposter/list'>
                        <span className='video-page-icons'></span>
                        <span>
                            Back
                        </span>
                    </Link>
                </div>
                <div className='post-upload-main'>
                    <div className="headline col-xl-12 my-3">
                        <label htmlFor='head_line'>Title</label>
                        <input type="text" id="head_line" className="form-input" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                        {titleError && <span className="error-message">{titleError}</span>}
                    </div>
                    <div className="intro col-xl-12 my-3">
                        <label htmlFor='intro'>Description</label>
                        <textarea className="form-input" id="intro" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        {descriptionError && <span className="error-message">{descriptionError}</span>}
                    </div>
                    <div className="media_url col-xl-12 my-3">
                        <label htmlFor='vodeo_id'>Video ID</label>
                        <input type="text" id="vodeo_id" className="form-input" value={mediaUrl} onChange={(e) => { setMediaUrl(e.target.value) }} />
                        {mediaUrlError && <span className="error-message">{mediaUrlError}</span>}
                    </div>
                    <div className="headline col-xl-12 my-3">
                        <label htmlFor='head_line'>Title</label>
                        <input type="text" id="head_line" className="form-input" value={buttonText} onChange={(e) => { setButtonText(e.target.value) }} />
                        {buttonTextError && <span className="error-message">{buttonTextError}</span>}
                    </div>
                    <div className="my-2">
                        <label htmlFor="is_active">
                            Button Active
                        </label>
                        <input type="checkbox" checked={isButtonVisible} onChange={handleButtonVisibleCheckboxChange} />
                    </div>
                    <div className="my-2">
                        <label htmlFor="is_active">
                            Is Active
                        </label>
                        <input type="checkbox" checked={isActive} onChange={handleStatusCheckboxChange} />
                    </div>
                    <div className="my-2">
                        <label htmlFor="is_active">
                            Is Aa
                        </label>
                        <input type="checkbox" checked={isAa} onChange={handleIsAaCheckboxChange} />
                    </div>
                    <div className="d-flex justify-content-center">
                        {showSubmitButton() && (<button type="submit" className="form-submit button-design" onClick={handleSubmit}>Submit</button>)}
                        &nbsp;
                        <button className='form-submit button-design' onClick={handleBack}>
                            <span className='text-white'>Back</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}