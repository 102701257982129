import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import Loader from '../../components/loader';
import 'react-quill/dist/quill.snow.css';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';

export default function ParticipationAddUpdate() {
    const navigate = useNavigate();
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [pageType, setPageType] = useState('Add');

    const [userId, setUserId] = useState('');
    const [userIdError, setUserIdError] = useState('');

    const [name, setName] = useState('');

    const [email, setEmail] = useState('');

    const [employeeId, setEmployeeId] = useState('');

    const [whatsappNo, setwhatsappNo] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');

    const [company, setCompany] = useState('');
    const [companyError, setCompanyError] = useState('');

    const [selectionStatus, setSelectionStatus] = useState('');
    const [selectionStatusError, setSelectionStatusError] = useState('');

    const [videoId, setVideoId] = useState('')
    const [videoIdError, setVideoIdError] = useState('')

    const [isVideoSubmitted, setIsVideoSubmitted] = useState(false);
    const [isFinalSubmissionDone, setIsFinalSubmissionDone] = useState(false)

    const [episodeId, setEpisodeId] = useState('');
    const [episode, setEpisode] = useState('');
    const [aaMasterId, setAaMasterId] = useState('');

    const [companies, setCompanies] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [episodes, setEpisodes] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [disabledStatusList, setDisabledStatusList] = useState([]);

    const [tempStatus, setTempStatus] = useState('');

    const [jadge1Points, setJadge1Points] = useState();
    const [jadge2Points, setJadge2Points] = useState();
    const [jadge3Points, setJadge3Points] = useState();
    const [jadge4Points, setJadge4Points] = useState();
    const [jadge5Points, setJadge5Points] = useState();

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole)

    const loadCompanies = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        HTTP.get('/companies/list', config)
            .then((response) => {
                if (response.status === 200) {
                    setCompanies(response.data)
                }
                if (response.status === 204) {
                    Swal.fire('', 'Companies Not Found', 'error');
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // const loadStatuses = async () => {
    //     let config = {
    //         headers: { 'Content-Type': 'application/json', 'token': access_token },
    //     }
    //     let fields = {
    //         filters: {
    //             name: '',
    //         },
    //         sorting: { field: 'order_index', type: 'asc' },
    //     };
    //     HTTP.post('amazing_adanians/selection/status/list', fields, config)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 setStatuses(response.data);
    //             }
    //             if (response.status === 204) {
    //                 Swal.fire('', 'Selection Statuses Not Found', 'error');
    //             }
    //         })
    //         .catch((error) => {
    //             setError(error)
    //             setRerenderFlag(true);
    //         })
    // }

    // useEffect(() => {
    //     setStatusDisableList();
    // }, [userId]);

    // const setStatusDisableList = () => {
    //     let user_id = userId;
    //     let login_user_id = parseInt(userObj.id);
    //     let login_user_role = parseInt(userObj.role.id);

    //     let disabledSList = [];
    //     if (((login_user_role !== 6 && login_user_role !== 1) || login_user_id == user_id)) {
    //         disabledSList = statuses.map(status => status.id);
    //     }

    //     if (isFinalSubmissionDone) {
    //         disabledSList = statuses.map(status => status.id);
    //     }

    //     disabledSList = [...new Set(disabledSList)];
    //     setDisabledStatusList(disabledSList)
    // }

    // const loadEpisodes = async () => {
    //     let config = {
    //         headers: { 'Content-Type': 'application/json', 'token': access_token },
    //     }
    //     let fields = {
    //         filters: {
    //             name: '',
    //             aa_master_id: aaMasterId
    //         },
    //         pagination: { page: '1', limit: '200' },
    //         sorting: { field: 'order_index', type: 'asc' },
    //     };
    //     HTTP.post('amazing_adanians/episode/master/list', fields, config)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 setEpisodes(response.data.contents);
    //             }
    //             if (response.status === 204) {
    //                 Swal.fire('', 'Episodes Not Found', 'error');
    //             }
    //         })
    //         .catch((error) => {
    //             setRerenderFlag(true);
    //         })
    // }

    const loadParticipationData = async () => {
        setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        HTTP.get('amazing_adanians/participation/' + id, config)
            .then((response) => {
                if (response.status === 200) {
                    setUserId(response.data.content.user_id)
                    setName(response.data.content.name)
                    setEmail(response.data.content.email)
                    setEmployeeId(response.data.content.employee_id)
                    setwhatsappNo(response.data.content.whatsapp_no)
                    setCity(response.data.content.city_name)
                    setCompany(response.data.content.company_id)
                    setSelectionStatus(response.data.content.selection_status_name)
                    setTempStatus(response.data.content.temp_status_name)
                    setVideoId(response.data.content.video_id)
                    setIsVideoSubmitted(response.data.content.is_video_submitted)
                    if (response.data.content.episode_id !== null, response.data.content.episode_id !== undefined) {
                        setEpisode(response.data.content.episode_name || '')
                        setEpisodeId(response.data.content.episode_id)
                    }
                    setIsFinalSubmissionDone(response.data.content.is_final_submission_done)
                    if (response.data.content.judges_vote) {
                        setJadge1Points(response.data.content.judges_vote.judge_1_points)
                        setJadge2Points(response.data.content.judges_vote.judge_2_points)
                        setJadge3Points(response.data.content.judges_vote.judge_3_points)
                        setJadge4Points(response.data.content.judges_vote.judge_4_points)
                        setJadge5Points(response.data.content.judges_vote.judge_5_points)
                    }
                    // setStatusDisableList()
                    if (response.data.content.aa_master_id !== null && response.data.content.aa_master_id !== undefined) {
                        setAaMasterId(response.data.content.aa_master_id);
                        // Call loadEpisodes only if aa_master_id exists
                        // loadEpisodes();
                    }
                }
                if (response.status === 204) {
                    Swal.fire('', 'Participation Not Found', 'error');
                }
            })
            .catch((error) => {
                setError(error);
                setRerenderFlag(true);
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        loadCompanies();
        // loadStatuses();
        let cid = id;
        if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setPageType('Update')
            loadParticipationData()
            setIsLoading(false);
        }
        setIsLoading(false)
    }, []);

    // useEffect(() => {
    //     if (aaMasterId !== null && aaMasterId !== '') {
    //         loadEpisodes();
    //     }
    // }, [aaMasterId]);

    const resetValidationErrors = () => {
        setUserIdError('');
        setCityError('');
        setCompanyError('');
        setSelectionStatusError('');
        setVideoIdError('');
    };

    const validateField = () => {
        resetValidationErrors();
        let checked = true;
        if (city === '') {
            setCityError('Please enter city')
            if (checked) {
                checked = false;
            }
        } else if (city.length < 5) {
            setCityError("Minimum 5 character required");
            if (checked) {
                checked = false;
            }
        } else if (city.length > 500) {
            setCityError("Max character limit 500");
            if (checked) {
                checked = false;
            }
        }

        if (company === '') {
            setCompanyError('Please enter company')
            if (checked) {
                checked = false;
            }
        } else if (company.length < 5) {
            setCompanyError("Minimum 5 character required");
            if (checked) {
                checked = false;
            }
        } else if (company.length > 500) {
            setCompanyError("Max character limit 500");
            if (checked) {
                checked = false;
            }
        }

        if (selectionStatus === '') {
            setSelectionStatusError('Please enter selection status')
            if (checked) {
                checked = false;
            }
        }

        if (videoId === '' || videoId === null) {
            setVideoIdError('Please enter video id')
            if (checked) {
                checked = false;
            }
        } else if (videoId.length != 13) {
            setVideoIdError("Required character should be 13");
            if (checked) {
                checked = false;
            }
        }
        return checked;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateField()) {
            let config = {
                headers: { 'Content-Type': 'application/json', 'token': access_token },
            }
            let cid = id;
            if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
                cid = 0
            }
            let fields = {
                aa_master_id: aaMasterId,
                name: name,
                email: email,
                employee_id: employeeId,
                whatsapp_no: whatsappNo,
                city_name: city,
                company_id: company,
                // temp_status_id: selectionStatus,
                video_id: videoId,
                // is_video_submitted: isVideoSubmitted,
                episode_id: (selectionStatus == "Selected" ? episodeId : null),
                judge_1_points: jadge1Points,
                judge_2_points: jadge2Points,
                judge_3_points: jadge3Points,
                judge_4_points: jadge4Points,
                judge_5_points: jadge5Points,
            };
            setIsLoading(true)
            HTTP.put('amazing_adanians/participation/create/update/' + cid, fields, config)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('', response.data.message, 'success');
                        navigate('/participation/list')
                    }
                    if (response.status === 204) {
                        Swal.fire('', response.data.content, 'error');
                    }
                    if (response.status === 403) {
                        Swal.fire('', response.data.content, 'error');
                    }
                })
                .catch((error) => {
                    setError(error);
                    setRerenderFlag(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleBack = () => {
        navigate('/participation/list');
    };

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="post-upload-wrapper ">
                <div className='d-flex justify-content-between'>
                    {/* <h2 style={{ fontSize: '25px' }}>{pageType} Participation</h2> */}
                    <CommonTitle PageTitle='Update Participation'></CommonTitle>
                    <Link to='/participation/list'>
                        <span className='video-page-icons'></span>
                        <span>
                            Back
                        </span>
                    </Link>
                </div>
                <div className='post-upload-main row'>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='name'>Name</label>
                        <input id="name" className="form-input" value={name} readOnly />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='email'>Email</label>
                        <input id="email" className="form-input" value={email} readOnly />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='employee_id'>Employee ID</label>
                        <input id="employee_id" className="form-input" value={employeeId} readOnly />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='whatsapp_no'>Whatsapp No</label>
                        <input id="whatsapp_no" className="form-input" value={whatsappNo} readOnly />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='city_name'>City</label>
                        <input id="city_name" className="form-input" value={city} onChange={(e) => { setCity(e.target.value) }} />
                        {cityError && <span className="error-message">{cityError}</span>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor="company_id">
                            Company
                        </label>
                        <select name="" id="company_id" className="form-input" value={company} readOnly>
                            <option key="" value="">Select</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>{company.name}</option>
                            ))}
                        </select>
                        {companyError && <span className="error-message">{companyError}</span>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor='vidoe_id'>Video ID</label>
                        <input type="text" id="vidoe_id" className="form-input" value={videoId} onChange={(e) => { setVideoId(e.target.value) }} />
                        {videoIdError && <span className="error-message">{videoIdError}</span>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                        <label htmlFor="selection_status_id">
                            Selection Status
                        </label>
                        <input id="selection_status_id" className="form-input" value={isFinalSubmissionDone ? selectionStatus : tempStatus} readOnly />
                        {/* <select name="" id="selection_status_id" className="form-input" value={() => isFinalSubmissionDone ? selectionStatus : tempStatus} readOnly>
                            <option key="" value="">Select</option>
                            {statuses.map((status) => (
                                <option key={status.id} value={status.id} disabled={disabledStatusList.includes(status.id)}>{status.name}</option>
                            ))}
                        </select> */}
                        {selectionStatusError && <span className="error-message">{selectionStatusError}</span>}
                    </div>
                    {selectionStatus == "Selected" &&
                        <>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="episode_id">
                                    Current Episode
                                </label>
                                <input id="episode_id" className="form-input" value={episode} readOnly />
                                {/* <select name="" id="episode_id" className="form-input" value={episodeId} readOnly>
                                <option key="" value="">Select</option>
                                {episodes.map((episode) => (
                                    <option key={episode.id} value={episode.id}>{episode.name}</option>
                                ))}
                            </select> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="judge_1_point">
                                    Judge 1 Points
                                </label>
                                <input id="judge_1_point" className="form-input" value={jadge1Points} onChange={(e) => { setJadge1Points(e.target.value) }} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="judge_1_point">
                                    Judge 2 Points
                                </label>
                                <input id="judge_2_point" className="form-input" value={jadge2Points} onChange={(e) => { setJadge2Points(e.target.value) }} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="judge_1_point">
                                    Judge 3 Points
                                </label>
                                <input id="judge_3_point" className="form-input" value={jadge3Points} onChange={(e) => { setJadge3Points(e.target.value) }} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="judge_1_point">
                                    Judge 4 Points
                                </label>
                                <input id="judge_4_point" className="form-input" value={jadge4Points} onChange={(e) => { setJadge4Points(e.target.value) }} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mg-btm">
                                <label htmlFor="judge_1_point">
                                    Judge 5 Points
                                </label>
                                <input id="judge_5_point" className="form-input" value={jadge5Points} onChange={(e) => { setJadge5Points(e.target.value) }} />
                            </div>
                        </>
                    }
                    <div className="my-2">
                        <span className="flex align-items-center">
                            <label htmlFor="is_active" className="pe-1">
                                Is Final Submission Done
                            </label>
                            <input
                                type="checkbox"
                                checked={isFinalSubmissionDone}
                            />
                        </span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="form-submit button-design" onClick={handleSubmit}>Submit</button>
                        &nbsp;
                        <button className='form-submit button-design' onClick={handleBack}>
                            <span className='text-white'>Back</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}