import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ErrorHandlingComponent from "./ErrorHandlingComponent";
import BrightcoveVideo from "./BrightcoveVideo";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Loader from "./loader";
import { HTTP } from "../api/http-common";
import { HiOutlineRefresh } from "react-icons/hi";
import PropTypes from "prop-types";
import { BiSolidEdit } from "react-icons/bi";
import { GoIssueClosed } from "react-icons/go";
import { AiOutlineCloseCircle } from "react-icons/ai";

const HeroPosterListUI = ({ heroPoster }) => {
  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("access_token")
  const userObj = JSON.parse(localStorage.getItem("userObj"))

  const userRole = userObj?.role?.id;

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const refreshImages = () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "multipart/form-data", token: accessToken },
    };
    let formData = new FormData();
    formData.append("video_id", heroPoster.media_url);
    setIsLoading(true);
    HTTP.post("hero_poster/refresh/images", formData, config)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("", response.data.message, "success");
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => { }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className=" video-content row">
        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-11 video-thumbnail-wrapper">
          <div className="video-thumbnail-container">
            {heroPoster.media_url && (
              <BrightcoveVideo videoId={heroPoster.media_url} />
            )}
          </div>
        </div>
        <div className="col-xl-10 col-lg-9 col-sm-8 col-md-8 d-flex justify-content-between align-items-baseline">
          <div className="content-details-left">
            <div className="d-flex justify-content-between align-items-baseline">
              <h4 className="video-title">{heroPoster.title}</h4>
            </div>
            <p className="video-description">{heroPoster.description}</p>
            <div className="video-status-wrapper">
              {heroPoster.is_active ? (
                <span className="article-status success-message">
                  <GoIssueClosed />
                  <span className="ps-1" style={{ marginRight: "10px" }}>
                    {" "}
                    Active{" "}
                  </span>
                </span>
              ) : (
                <span className="article-status error-message">
                  <AiOutlineCloseCircle />
                  <span className="ps-1" style={{ marginRight: "10px" }}>
                    {" "}
                    Not Active{" "}
                  </span>
                </span>
              )}
              {heroPoster.is_aa ? (
                <span className="article-status success-message">
                  <GoIssueClosed />
                  <span className="ps-1" style={{ marginRight: "10px" }}>
                    {" "}
                    Amazing Adanians{" "}
                  </span>
                </span>
              ) : (
                <span className="article-status error-message">
                  <AiOutlineCloseCircle />
                  <span className="ps-1" style={{ marginRight: "10px" }}>
                    {" "}
                    Not Amazing Adanians{" "}
                  </span>
                </span>
              )}
              {heroPoster.created_at !== null &&
                heroPoster.created_at !== "" ? (
                <span className="video-status">
                  Created At : {heroPoster.created_at}
                </span>
              ) : null}
            </div>
          </div>

          <div className="content-buttons-right">
            <span className="d-block content-button">
              <Link
                className="view-analytics-button button-design"
                key={heroPoster.id}
                to={`/heroposter/update/${heroPoster.id}`}
                title="Edit"
              >
                <BiSolidEdit />
              </Link>
            </span>
            <span>
              <span title="Refresh" className="content-button">
                {userRole === 1 && (
                  <button
                    className="view-analytics-button button-design"
                    onClick={refreshImages}
                  >
                    <HiOutlineRefresh />
                  </button>
                )}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

HeroPosterListUI.propTypes = {
  heroPoster: PropTypes.object.isRequired,
  handleReload: PropTypes.func.isRequired,
};

export default HeroPosterListUI;
