import React, { useEffect, useState } from 'react';
import { IoIosArrowUp } from "react-icons/io";
const ScrollProgress = () => {
  const [scrollValue, setScrollValue] = useState(0);

  const calcScrollValue = () => {
    const pos = document.documentElement.scrollTop;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const newValue = Math.round((pos * 100) / calcHeight);
    setScrollValue(newValue);

    if (pos > 100) {
      return 'grid';
    } else {
      return 'none';
    }
  };

  useEffect(() => {
    const scrollListener = () => {
      const scrollProgress = document.getElementById("progress");
      const progressValue = document.getElementById("progress-value");

      const displayStyle = calcScrollValue();
      scrollProgress.style.display = displayStyle;

      scrollProgress.style.background = `conic-gradient(#3d7ba7 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
    };

    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollValue]);

  const handleScrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <div id="progress" style={{ display: 'none' }} onClick={handleScrollToTop}>
      <span id="progress-value"><IoIosArrowUp /></span>
    </div>
  );
};

export default ScrollProgress;
