import React, { useState, useEffect, useRef } from "react";
import { HTTP } from "../../api/http-common";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorHandlingComponent from "./../../components/ErrorHandlingComponent";
import useUserRoleEffect from "../../components/userRoleEffect";
import Loader from "../../components/loader";
import Select from "react-select";
import { CommonTitle } from "../../components/commonComponents";

function NotificationSend() {
  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const userObj = JSON.parse(localStorage.getItem("userObj"));

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: "1", limit: "20" });

  const [contents, setContents] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchContents, setSearchContents] = useState({ title: "", status_id: "3", section_id: "" });
  const [selectedContent, setSelectedContent] = useState(null);
  const [contentsId, setContentsId] = useState(0);
  const [contentsIdError, setContentsIdError] = useState("");

  const [articles, setArticles] = useState([]);
  const [searchArticles, setSearchArticles] = useState({ head_line: '', is_active: true })
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [articleId, setArticleId] = useState("");
  const [articleIdError, setArticleIdError] = useState("");

  const [searchUsers, setSearchUsers] = useState({ name: '' })
  const [noticationTypeId, setNoticationTypeId] = useState("");
  const [mTypeId, setMTypeId] = useState("all");
  

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersIDs, setUsersIDs] = useState([]);
  const [usersIDsError, setUsersIDsError] = useState("");
  const [token, setToken] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const imgInputRef = useRef(null);

  const [validationMessage, setValidationMessage] = useState("");

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const [optionID, setoptionID] = useState("selected");
  const [userOptionList, setuserOptionList] = useState([
    {
      id: "selected",
      name: "Selected Users",
    },
    {
      id: "all",
      name: "All",
    }
  ]);

  const [mobileType, setMobileType] = useState([
    {
      id: "all",
      name: "All",
    },
    {
      id: "android",
      name: "Android Users",
    },
    {
      id: "ios",
      name: "IOS Users",
    },
  ]);

  const [noticationTypes, setNoticationTypes] = useState([
    {
      id: 4,
      name: "Article",
    },
    {
      id: 1,
      name: "New Content Published",
    },
    {
      id: 2,
      name: "Privacy Policy",
    },
    {
      id: 3,
      name: "FAQ",
    },
    {
      id: 5,
      name: "Amazing adanians",
    },
  ]);

  const loadContents = async () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        token: access_token,
      },
    };
    let fields = {
      filters: {
        title: searchContents.title,
        status_id: searchContents.status_id,
        section_id: searchContents.section_id,
      },

      pagination: pagination,
      sorting: { field: "id", type: "desc" },
    };

    HTTP.post("content/list", fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          const formattedContents = data.contents.map((content) => ({
            value: content.id,
            label: content.title,
          }));
          setContents(formattedContents);
          setTotal(data.total_count);
        }
        if (response.status === 204) {
          setContents([]);
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
  };

  const loadArticles = async () => {
    let config = {
      headers: { "Content-Type": "application/json", token: access_token },
    };
    let fields = {
      filters: {
        head_line: searchArticles.head_line,
        is_active: searchArticles.is_active,
      },
      pagination: pagination,
      sorting: { field: "id", type: "desc" },
    };
    HTTP.post("article/list", fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          const formattedArticles = data.contents.map((article) => ({
            value: article.id,
            label: article.head_line,
          }));
          setArticles(formattedArticles);
          setTotal(data.total_count);
        }
        if (response.status === 204) {
          setArticles([]);
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
  };

  const loadUsers = async () => {
    let config = {
      headers: { "Content-Type": "application/json", token: access_token },
    };
    let fields = {
      filters: {
        name: searchUsers.name,
        email: "",
        contact_no: "",
        role_name: "",
        status: "",
        has_mobile_token: true,
      },
      pagination: pagination,
      sorting: { field: "id", type: "desc" },
    };

    HTTP.post("user/list", fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          const formattedUsers = data.contents.map((user) => ({
            value: user.id,
            label: user.name,
          }));
          setUsers(formattedUsers);
        }
        if (response.status === 204) {
          setUsers([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setRerenderFlag(true);
      })
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  useEffect(() => {
  }, [noticationTypeId]);

  const resetValidationErrors = () => {
    setTitleError("");
    setDescriptionError("");
    setContentsIdError("");
    setArticleIdError("");
    setUsersIDsError("");
  };

  const validateField = () => {
    resetValidationErrors();

    let checked = true;
    if (title === "") {
      setTitleError("Please enter title");
      if (checked) {
        checked = false;
      }
    } else if (title.length < 5) {
      setTitleError("Please enter minimum 5 characters");
      if (checked) {
        checked = false;
      }
    } else if (title.length > 200) {
      setTitleError("Max character limit is 200");
      if (checked) {
        checked = false;
      }
    }
    if (description === "") {
      setDescriptionError("Please enter description");
      if (checked) {
        checked = false;
      }
    } else if (description.length < 5) {
      setDescriptionError("Minimum 5 character required");
      if (checked) {
        checked = false;
      }
    } else if (description.length > 500) {
      setDescriptionError("Max character limit 500");
      if (checked) {
        checked = false;
      }
    }
    if (noticationTypeId == 1 && !contentsId) {
      setContentsIdError("Select Content");
    }
    if (noticationTypeId == 4 && !articleId) {
      setArticleIdError("Select Article");
    }
    if (optionID == "selected" && usersIDs.length === 0) {
      setUsersIDsError("Select User(s)")
    }

    return checked;
  };

  const resetInputs = () => {
    setTitle('');
    setDescription('');
    setNoticationTypeId('');
    setContentsId('');
    setUsersIDs([]);
    setSelectedUsers([]);
    setSelectedImage(null);
    setImage(null);
    setValidationMessage("");
  };

  const handleNotificationTypeChange = (e) => {
    setNoticationTypeId(e.target.value);
    if (e.target.value == 1) {
    }
    if (e.target.value == 4) {
    }
  };

  const handleMTypeChange = (e) => {
    setMTypeId(e.target.value);
  };

  const handleOptionChange = (value) => {
    setoptionID(value);
  };

  useEffect(() => {
    if (searchContents.title.length >= 3) {
      loadContents();
    }
    if (searchArticles.head_line.length >= 3) {
      loadArticles();
    }
    if (searchUsers.name.length >= 3) {
      loadUsers();
    }
  }, [searchContents, searchArticles, searchUsers]);


  const handleChange = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
    const userIDs = selectedUsers.map((user) => user.value);
    setUsersIDs(userIDs);
    console.log(userIDs);
  };

  const handleUserLoad = (value) => {
    if (value.length >= 3) {
      setSearchUsers({ ...searchUsers, name: value });
    }
  };

  const handleContentLoad = (value) => {
    if (value.length >= 3) {
      setSearchContents({ ...searchContents, title: value });
    }
  };

  const handleContentChange = (selectedContent) => {
    setSelectedContent(selectedContent);
    setContentsId(String(selectedContent.value));
  };

  const handleArticleLoad = (value) => {
    if (value.length >= 3) {
      setSearchArticles({ ...searchArticles, head_line: value });
    }
  };

  const handleArticleChange = (selectedArticle) => {
    setSelectedArticle(selectedArticle);
    setArticleId(String(selectedArticle.value));
  };

  const handleImageChange = (event) => {
    setValidationMessage("");
    setSelectedImage(null);
    setImage(null);
    const file = event.target.files[0];
    // Check if file is an image
    if (file.type && file.type.indexOf('image') === -1) {
      console.error('File is not an image');
      setValidationMessage('Please select image');
      return;
    }
    const maxSizeInBytes = 1 * 1024 * 1024; // 3 MB
    if (file.size > maxSizeInBytes) {
      setValidationMessage('Please select an image smaller than 1 MB.');
        event.target.value = null;
        return false;
    } 

    const reader = new FileReader();

    reader.onload = function(event) {
      const img = new Image();
      
      img.onload = function() {
        const width = this.width;
        const height = this.height;
        const gcd = getGCD(width, height);
        const aspectRatio = `${width / gcd}:${height / gcd}`;
        console.log('Image aspectRatio:', aspectRatio);

        if (width < 300 || width > 2000) {
          setValidationMessage('Width between 300px and 2000px.');
            event.target.value = null;
            setSelectedImage(null);
            setImage(null);
            return false;
        } 
        if (height < 200) {
          setValidationMessage('Height must be at least 200px.');
            event.target.value = null;
            setSelectedImage(null);
            setImage(null);
            return false;
        }
        if (aspectRatio != '2:1') {
          setValidationMessage('Recommended aspectratio is 2:1 Your image aspectratio is '+`${aspectRatio}`);
        }

      };
      
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);

    function getGCD(a, b) {
      while (b) {
        let temp = b;
        b = a % b;
        a = temp;
      }
      return a;
    }
      

    setSelectedImage(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    if (imgInputRef.current) {
      imgInputRef.current.value = "";
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateField()) {
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let content_id = "";
      if (noticationTypeId == 1) {
        content_id = contentsId;
      }
      if (noticationTypeId == 4) {
        content_id = articleId;
      }
      let formData = new FormData();
      if (optionID == "selected") {
        formData.append('users', usersIDs);
      }
      formData.append('title', title);
      formData.append('body', description);
      formData.append('type', noticationTypeId);
      formData.append('content_id', content_id);
      formData.append('send_to', optionID);
      formData.append('mobile_type', mTypeId);
      

      if (selectedImage) {
        formData.append("image", selectedImage);
      }
      setIsLoading(true);
      HTTP.post("/notification/send-notification/all", formData, config)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("", response.data.message, "success");
            resetValidationErrors();
            resetInputs();
          }

          if (response.status === 204) {
            Swal.fire("", "Roles Not Found", "error");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
        })
        .finally(() => {
          setIsLoading(false);
          resetValidationErrors();
        });
    }
  };

  useEffect(() => { }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className="d-flex justify-content-between">
        {/* <h2 style={{ fontSize: "25px" }}>Send Notification</h2> */}
        <CommonTitle PageTitle='Send Notification' />

      </div>

      <div className="video-table-main form-inner post-upload-main">
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <div className="row mg-btm">
            {/* Name field */}
            <div className="col-lg-12 mg-btm">
              <label htmlFor="name" className="add-form-label label-title">
                Title
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                id="name"
                className="form-input"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <span className="error-message">{titleError}</span>
            </div>
            {/* description field */}
            <div className="col-lg-12 mg-btm">
              <label
                htmlFor="description"
                className="add-form-label label-title"
              >
                Description
              </label>
              <textarea
                rows="2"
                placeholder="Enter Description"
                id="description"
                className="form-input"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <span className="error-message">{descriptionError}</span>
            </div>
            <div className="col-lg-12 mg-btm">
              <label htmlFor="noticationType_id">Notication Type</label>
              <select
                name="noticationType_id"
                id="noticationType_id"
                className="form-input"
                onChange={handleNotificationTypeChange}
                value={noticationTypeId}
              >
                <option key="default" value="default">
                  Select
                </option>
                {Array.isArray(noticationTypes) && noticationTypes.length > 0
                  ? noticationTypes.map((noticationType) => (
                    <option key={noticationType.id} value={noticationType.id}>
                      {noticationType.name}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            {noticationTypeId == 1 ? (
              <div className="col-lg-12 mg-btm">
                <label htmlFor="noticationType_id">Contents</label>
                <Select
                  onChange={handleContentChange}
                  onInputChange={handleContentLoad}
                  isClearable
                  isSearchable
                  options={contents}
                  getOptionValue={(option) => `${option['id']}`}
                  className="participant-select"
                />
                <span className="error-message">{contentsIdError}</span>
              </div>
            ) : null}

            {noticationTypeId == 4 ? (
              <div className="col-lg-12 mg-btm">
                <label htmlFor="articles_id">Articles</label>
                <Select
                  onChange={handleArticleChange}
                  onInputChange={handleArticleLoad}
                  isClearable
                  isSearchable
                  options={articles}
                  getOptionValue={(option) => `${option['id']}`}
                  className="participant-select"
                />
                <span className="error-message">{articleIdError}</span>
              </div>
            ) : null}
            <div>

            <div className="col-lg-12 mg-btm">
              <label htmlFor="mType_id">Mobile Type</label>
              <select
                name="mType_id"
                id="mType_id"
                className="form-input"
                onChange={handleMTypeChange}
                value={mTypeId}
              >
                {Array.isArray(mobileType) && mobileType.length > 0
                  ? mobileType.map((mType) => (
                    <option key={mType.id} value={mType.id}>
                      {mType.name}
                    </option>
                  ))
                  : null}
              </select>
            </div>

            <div className="thumbnail-upload-right col-xl-12 my-2 col-lg-12">
              <div className="d-flex justify-content-between align-items-center row my-2">
                {/* <div className="col-lg-2">
                  <label htmlFor="file">Image Upload</label>
                </div> */}
                <div className="col-lg-12">
                  <span className="">
                    <input ref={imgInputRef} type="file" className="form-input" id="fileInput" accept=".jpeg, .jpg, .png" onChange={handleImageChange} />
                    <label htmlFor="fileInput" className="">
                      {/* Upload Image */}
                    </label>
                    <p className="error-message text-center pb-2"> {validationMessage} </p>
                  </span>
                </div>
              </div>
              <div className="image-upload-wrapper">
                <div className="upload-main">
                  <div className="custom-file-input">
                    {image ? (
                      <img src={image} width="100%" height="100%" alt="Cover" />
                    ) : (
                      <div style={{border:"1px solid #b0b0b0",borderRadius:"5px"}}>
                        <p>No image choosen</p>
                        <p>Requires a PNG or JPEG file</p>
                        <p>Size:Width must be at least 300px, height at least 200px.</p>
                        <p>Aspect ratio: Use images in landscape format respecting a 2:1 ratio (e.g. 1000x500px).</p>
                        <p>Avoid using images wider than 2000 pixels. Also, make sure that your image is not bigger than 1MB.</p>
                      </div>
                      
                    )}
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="radio-group-wrapper">
            <div className="radio-group">
              {Array.isArray(userOptionList) && userOptionList.length > 0
                ? userOptionList.map((userOption) => (
                  <div key={userOption.id} className="radio-option">
                    <input
                      type="radio"
                      id={`status_${userOption.id}`}
                      name="status_id"
                      value={userOption.id}
                      onChange={() => handleOptionChange(userOption.id)}
                      checked={optionID === userOption.id}
                    />
                    <label
                      htmlFor={`status_${userOption.id}`}
                      className={optionID === userOption.id ? "selected" : ""}
                      onChange={() => handleOptionChange(userOption.id)}
                    >
                      {userOption.name}
                    </label>
                  </div>
                ))
                : null}
            </div>
            </div>

            {optionID == "selected" && (
              
                  <div className="headline col-xl-12 mb-3">
                <label htmlFor="status_id">User(s)</label>
                <Select
                  isMulti
                  isClearable
                  isSearchable
                  options={users}
                  value={selectedUsers}
                  onChange={handleChange}
                  onInputChange={handleUserLoad}
                  className="participant-select"
                />
                <span className="error-message">{usersIDsError}</span>
              </div>
              
              
            )}
          </div>

          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="form-submit button-design"
              value="Submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default NotificationSend;
