import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear();
    
    return (
        <footer className='footer-wrapper footer'>
            <div className='container'>
                <div className="footer-bottom justify-content-between align-items-center row">
                    <div className="footer-bottom-left col-xl-2 col-lg-3 col-md-4 col-sm-3  p-0 text-xl-start footer-list">
                        <Link to='/'>
                            © {year} Adani Group
                        </Link>
                    </div>
                    <div className="footer-bottom-right col-xl-4 col-lg-6 col-md-8 col-sm-6 p-0 text-xl-end">
                        <ul className="footer-list d-flex ">
                            <li><Link to='/privacypolicy'> Privacy Policy</Link></li>
                            <li><Link to='/faqs'>FAQs</Link></li>
                            <li><Link to='/contactUs'>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )

}
