import React, { useState, useEffect } from 'react';
import { IoListSharp } from "react-icons/io5";
import { HTTP } from '../../api/http-common';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import { IoSearch } from "react-icons/io5";
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import StaticInfoListUI from '../../components/StaticInfoListUI';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';

export default function StaticInfo() {
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [staticInfo, setStaticInfo] = useState([]);
    const [search, setSearch] = useState({ key: '' });
    const [total, setTotal] = useState(0);
    const [sorting, setSorting] = useState({ field: 'id', type: 'desc' });
    const [pagination, setPagination] = useState({ page: '1', limit: '10' });
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('')

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    const loadStaticInfo = async () => {
        setIsLoading(true)
        let fields = {
            filters: {
                key: search.key,
            },
            pagination: pagination,
            sorting: sorting,
        };
        HTTP.post('static_info/list', fields)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setStaticInfo(data.contents);
                    setTotal(data.total_count);
                }
                if (response.status === 204) {
                    setStaticInfo([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };
    useEffect(() => {
        loadStaticInfo();
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag, search, sorting, pagination]);

    const onPaginationChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const onLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPagination({ ...pagination, limit: newLimit, page: 1 });
    };

    const handleSortFieldChange = (field) => {
        const newSorting = {
            field,
            type: 'desc',
        };
        setSorting(newSorting);
        setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
    }

    const handleSortTypeChange = (type) => {
        setSorting({ ...sorting, type: type });
        setPagination({ ...pagination, page: 1, limit: pagination.limit });
    }

    const onSearchEnter = (value) => {
        if (value.length == 0 || value.length >= 3) {
            setSearch({ ...search, key: value });
            setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
        }
    };

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="article-wrapper">
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <CommonTitle PageTitle='Static Info' />
            <div className="video-table-wrapper">
                <div className="action-buttons-wrapper row">
                    <div className="left-button col-lg-6 col-md-3">
                        <a href="#" className="video-table-button-structure bg-secondary" style={{ padding: '7px 25px' }}>
                            <span>
                                <span className='video-page-icons'> <IoListSharp /></span>
                                <span>List </span>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="video-table-main">
                    <div className="timeline-actions-wrapper row justify-content-between align-items-center ">
                        <div className="col-xl-5 col-lg-5 col-md-7">
                            <span className="d-block border border-secondary p-1 border-radius">
                                <span><IoSearch /></span>
                                <span>
                                    <input type='text' placeholder='Search' className='border-0 inner-searchbar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter(e.target.value) } }} />
                                </span>
                            </span>
                        </div>
                        <div className='col-xl-4 col-lg-5 col-md-6 d-flex align-items-center video-table-dropdown p-0'>
                            <span>
                                <label htmlFor="sort" className='pe-2'> Sort:</label>
                                <select className="border-secondary  p-1  form-input" value={sorting.field} onChange={(e) => handleSortFieldChange(e.target.value)}>
                                    <option value="id">Id</option>
                                    <option value="title">Title</option>
                                    <option value="key">Description</option>
                                </select>
                            </span>
                            <span>
                                <label htmlFor="sortBy" className='pe-2'> SortBy:</label>
                                <select className="border-secondary  p-1  form-input" value={sorting.type} onChange={(e) => handleSortTypeChange(e.target.value)}>
                                    <option value="desc">Desc</option>
                                    <option value="asc">Asc</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    {Array.isArray(staticInfo) && staticInfo.length > 0 ? (
                        staticInfo.map((staticInfo) => (
                            <StaticInfoListUI key={staticInfo.id} static_info={staticInfo} handleReload={loadStaticInfo} />
                        ))
                    ) : (
                        <p className='text-center mt-4'>
                            No Static info found
                        </ p>
                    )}
                </div>
                {Array.isArray(staticInfo) && staticInfo.length > 0 ? (
                    <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
                ) : (
                    null
                )}
            </div>
        </div>
    )
}