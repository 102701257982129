import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { FaRegUser } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";
const UserProfile = () => {
  let navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState('');
  const [userRole, setUserRole] = useState('');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let userObj = localStorage.getItem('userObj');
    if (userObj != null && userObj !== "") {
      let userJson = JSON.parse(userObj);
      setUsername(userJson.name)
      setUserRole(userJson.role.name)
    }
  }, []);

  const logOutHandler = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload()
  }

  return (
    <div className=" col-xl-2 col-lg-2 col-md-3 col-sm-3 col-1 ">
      <div ref={dropdownRef} className={`dropdown ${isActive ? 'active' : ''}`} onClick={() => toggleDropdown()}>
        <div className="user-details">
          <button onClick={() => toggleDropdown()} className="username dot-effect bg-white border-0" title={`${username} - ${userRole}`}>
            {`${username} - ${userRole}`}
          </button>

          <i className={`fa ${isActive ? 'fa fa-plus' : 'fa-regular fa-bell'}`} ></i>
        </div>
        <div className="dropdown-content" style={{ display: isActive ? 'block' : 'none' }}>
          <Link to="/dashboard" className="useroption">
            <span>Profile </span> 
            <span className='dropdown-icon'> <FaRegUser /></span>
          </Link>
          <button onClick={() => logOutHandler()} className="useroption  border-0">
            <span>Log Out </span> 
            <span className='dropdown-icon'><RiLogoutCircleLine />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
