import React, { Component } from 'react';
import { HTTP } from '../api/http-common';
import MySliderComponent from '../components/VideoSlider';
import LoginComponentWithNavigate from './login';
import Loader from '../components/loader';

import { MsalProvider } from '@azure/msal-react';
import {msalC} from '../components/msalC';
import { PublicClientApplication } from '@azure/msal-browser';
import Footer from '../components/Footer';


const msalInstance = new PublicClientApplication(msalC);

class Home extends Component {
  constructor() {
    super();
    this.state = {
      renderComponent: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.checkAccessToken();
  }

  checkAccessToken = async () => {
    const access_token = localStorage.getItem('access_token');

    if (access_token) {
      await this.fetchUserData(access_token);
    } else {
      this.setState({
        renderComponent: false,
        isLoading: false,
      });
    }
  };

  fetchUserData = async (access_token) => {
    try {
      const config = {
        headers: { 'Content-Type': 'application/json', 'token': access_token },
      };

      const response = await HTTP.get("user/me", config);

      if (response.status === 200) {
        localStorage.setItem('userObj', JSON.stringify(response.data));
        this.setState({
          renderComponent: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };
  

  render() {
    const { renderComponent, isLoading } = this.state;

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          renderComponent ? (
            <div className='parent'>
              <MySliderComponent />
              <Footer/>
            </div>
          ) : (
            <MsalProvider instance={msalInstance}>
              <LoginComponentWithNavigate />
            </MsalProvider>
          )
        )}
      </>
    );
  }
}

export default Home;
