import React, { useState, useEffect, useRef } from 'react';
import { HTTP } from "../api/http-common";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from "./ErrorHandlingComponent";
import { BiSolidEdit } from "react-icons/bi";
import BrightcoveVideo from './BrightcoveVideo';
import withReactContent from 'sweetalert2-react-content';
import { HiOutlineRefresh } from "react-icons/hi";

const ParticipationListUI = ({ participation, statusList, handleReload }) => {
    const access_token = localStorage.getItem("access_token")
    const userObj = JSON.parse(localStorage.getItem("userObj"))
    const [error, setError] = useState(null);
    const [disabledStatusList, setDisabledStatusList] = useState([]);
    const [statusID, setStatusID] = useState(parseInt(participation.temp_status_id));
    const [episodeId, setEpisodeId] = useState(parseInt(participation.episode_id))
    const [episodeError, setEpisodeError] = useState('')
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const MySwal = withReactContent(Swal);
    const [videoPlayedStates, setVideoPlayedStates] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const userRole = userObj?.role?.id;

    const statusChange = (to_status_id, episode_id) => {
        if (videoPlayedStates[participation.id] === true || !participation.video_id) {
            let field = {
                temp_status_id: to_status_id,
                episode_id: episode_id
            }
            let id = participation.id;

            let config = {
                headers: { "Content-Type": "application/json", token: access_token },
            };
            HTTP.put("amazing_adanians/participation/update/selection/status/" + id, field, config)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire("", response.data.message, "success");
                        handleReload();
                    }
                })
                .catch((error) => {
                    setError(error);
                    if (error.response.status == 403) {
                        setStatusID(participation.temp_status_id);
                    }
                    console.log("error:", error)
                    setRerenderFlag(true);
                });
        } else {
            Swal.fire("", "You must play the video for at least 5 seconds before changing status.", "error");
        }
    };

    const handleVideoPlaybackTimeChange = (timeInSeconds) => {
        if (timeInSeconds >= 5) {
            setVideoPlayedStates(prevState => ({
                ...prevState,
                [participation.id]: true
            }));
        }
    };

    const setStatusDisableList = () => {
        let user_id = participation.user_id;
        let login_user_id = parseInt(userObj.id);
        let login_user_role = parseInt(userObj.role.id);

        let disabledSList = [];
        if (((login_user_role !== 6 && login_user_role !== 1) || login_user_id === user_id)) {
            disabledSList = statusList.map(status => status.id);
        }

        if (participation.is_final_submission_done) {
            disabledSList = statusList.map(status => status.id);
        }

        if (participation.video_id) {
            if (!videoPlayedStates[participation.id]) {
                disabledSList = statusList.map(status => status.id);
            }
        }

        disabledSList = [...new Set(disabledSList)];
        setDisabledStatusList(disabledSList)
    }

    useEffect(() => {
        if (!participation.is_final_submission_done) {
            setStatusID(participation.temp_status_id);
        }
        setStatusDisableList();
    }, [participation.temp_status_id, statusList, participation.id, videoPlayedStates]);

    useEffect(() => {
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag]);

    const handleStatusOptionChange = (event) => {
        statusOpsChange(parseInt(event.target.value));
    };

    const handleStatusOptionChangeByLable = (value) => {
        statusOpsChange(parseInt(value))
    };

    const statusOpsChange = (value) => {
        setEpisodeError('')
        if (participation.video_id === null || participation.video_id === 'null' || participation.video_id === '') {
            Swal.fire('', 'Let participant upload video first!', 'error');
        }

        if (!disabledStatusList.includes(value)) {
            const newStatusID = parseInt(value);
            setStatusID(newStatusID);
            if (newStatusID === 1) {
                MySwal.fire({
                    title: 'Are you sure you want waiting for review',
                    text: 'Do you want to proceed?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setEpisodeId(null);
                        statusChange(newStatusID, null);
                    } else {
                        setStatusID(statusID);
                    }
                })
            }
            if (newStatusID === 2) {
                MySwal.fire({
                    title: 'Are you sure you want to select',
                    text: 'Do you want to proceed?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setEpisodeId(null);
                        statusChange(newStatusID, null);
                    } else {
                        setStatusID(statusID);
                    }
                });
            }
            if (newStatusID === 3) {
                MySwal.fire({
                    title: 'Are you sure you want to reject',
                    text: 'Do you want to proceed?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setEpisodeId(null);
                        statusChange(newStatusID, null);
                    } else {
                        setStatusID(statusID);
                    }
                })
            }
        }
    };

    const deleteParticipationVideoToBrightcove = () => {
        setIsLoading(true);
        let config = {
            headers: { "Content-Type": "multipart/form-data", token: access_token },
        };
        let formData = new FormData();
        setIsLoading(true);
        HTTP.post("amazing_adanians/participation/delete-video/brightcove/" + participation.id, formData, config)
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire("", response.data.message, "success");
                    handleReload();
                }
            })
            .catch((error) => {
                setError(error);
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handlePlayFullScreen = (videoId) => {
        const player = window.bc(document.getElementById(`myVideo-${videoId}`));
        if (player && player.enterFullScreen) {
            player.enterFullScreen();
        } else if (player && player.requestFullscreen) {
            player.requestFullscreen();
        }
    };

    const handleClick = (videoId) => {
        handlePlayFullScreen(videoId);
    }

    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="video-content row">
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-11 video-thumbnail-wrapper">
                    <div className="video-thumbnail-container">
                        {participation.video_id ? (
                            <BrightcoveVideo videoId={participation.video_id} onPlaybackTimeChange={handleVideoPlaybackTimeChange} handleClick={() => handleClick(participation.video_id)} exclusivePlayback={true}/>
                        ) : (
                            <img src='/images/logo/gsa.svg' alt='' />
                        )}
                    </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-sm-8 col-md-8 d-flex justify-content-between align-items-baseline">
                    <div className="content-details-left">
                        <div className="d-flex justify-content-between align-items-baseline">
                            <h4 className="video-title">
                                {participation.name}
                            </h4>
                        </div>
                        <div className="video-status-wrapper">
                            {participation.email !== null &&
                                participation.email !== "" ? (
                                <span>
                                    {participation.email}
                                </span>
                            ) : null}
                        </div>
                        <div className="video-status-wrapper">
                            {userRole !== 6 && participation.aa_master_name !== null &&
                                participation.aa_master_id !== "" ? (
                                <span className="video-status">
                                    Amazing Adanians master : {participation.aa_master_name}
                                </span>
                            ) : null}
                            {userRole !== 6 && participation.judge_name !== null &&
                                participation.judge_name !== "" ? (
                                <span className="video-status">
                                    Assigned judge name : {participation.judge_name}
                                </span>
                            ) : null}
                            {participation.video_id ? (
                                <span className="video-status">
                                    Brightcove Video Id : {participation.video_id}
                                </span>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                    <div className="content-buttons-right">
                        {userRole == 1 && <div className="content-buttons-right">
                            <span className="d-block content-button">
                                <Link
                                    className="view-analytics-button button-design"
                                    key={participation.id}
                                    to={`/participation/update/${participation.id}`}
                                    title="Edit"
                                >
                                    <BiSolidEdit />
                                </Link>
                            </span>
                        </div>}
                        <span className="content-button " title="Refresh vidoe data">
                            {(userRole === 1) && (participation.local_video_name !== null && participation.video_id !== null) && (
                                <button
                                    className="view-analytics-button button-design"
                                    onClick={deleteParticipationVideoToBrightcove}
                                >
                                    <HiOutlineRefresh />
                                </button>
                            )}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-evenly align-items-center">
                    <div className="select-status">
                        {Array.isArray(statusList) && statusList.length > 0 ? (
                            statusList.map((status) => (
                                <span
                                    key={`span-${participation.id}-${status.id}`}
                                    className="status-option"
                                >
                                    <input
                                        id={`status-${participation.id}-${status.id}`}
                                        type="radio"
                                        name={`status-${participation.id}-${status.id}`}
                                        value={status.id}
                                        checked={statusID === status.id}
                                        disabled={disabledStatusList.includes(status.id)}
                                        onChange={handleStatusOptionChange}
                                    />
                                    <label
                                        htmlFor={`status-${participation.id}-${status.id}`}
                                        onChange={() => handleStatusOptionChangeByLable(status.id)}
                                    >
                                        {" "}
                                        {status.name}{" "}
                                    </label>
                                </span>
                            ))
                        ) : (
                            <div>No contents found</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default ParticipationListUI;
