import React from "react";
import { Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { BiSolidEdit } from "react-icons/bi";
import { GoIssueClosed } from "react-icons/go";
import { SlClose } from "react-icons/sl";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { EndTimeIcon, ParticipantIcon, StartTimeIcon, } from "./SideBarIcons";
import { DateShowUI } from "./commonComponents";

const AaMasterListUI = ({ aa_master }) => {
  const [visible, setVisible] = useState(false);
  const handleEpisodeClick = () => {
    setVisible(!visible);
  };

  return (
    <div className="video-content d-flex align-items-center row">
      <div className="col-lg-12">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="video-title">{aa_master.name}</h4>
          <span className="episode-button-wrapper">
            <button
              className="button-design p-1 me-2"
              onClick={handleEpisodeClick}
            > Episode {visible ? <IoIosArrowUp /> : <IoIosArrowDown />} </button>
            <Link
              className="view-analtyics-button button-design"
              key={aa_master.id}
              to={`/amazingadaniansmaster/update/${aa_master.id}`}
              title="Edit"
            >
              <BiSolidEdit />
            </Link>
          </span>
        </div>
        <div className="row episode-timeline-container">
        {/* <DateShowUI DateShowTitle='Duration' aa_master=''/> */}
          <div className="sub-section-episode-detail sub-section-episode-detail">
            <div className="inner-episode-design  episode-timeline-section p-2">
              <h5 className="sub-section-episode-detail-title">Duration</h5>
              {aa_master.start_datetime !== null &&
                aa_master.start_datetime !== "" ? (
                <div className=" ">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b>Start: </b>
                    </span>
                    <span>{aa_master.start_datetime}</span>
                  </span>
                </div>
              ) : null}

              {aa_master.end_datetime !== null &&
                aa_master.end_datetime !== "" ? (
                <div className=" ">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon color={"black"} size={"1rem"} className="pe-1" />
                      <b>End : </b>
                    </span>
                    <span>{aa_master.end_datetime}</span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          

          <div className="sub-section-episode-detail">
            <div className="inner-episode-design  episode-timeline-section p-2">
              <h5 className="sub-section-episode-detail-title">Registration</h5>
              {aa_master.registration_start_datetime !== null &&
                aa_master.registration_start_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b> Start: </b>
                    </span>
                    <span>{aa_master.registration_start_datetime}</span>
                  </span>
                </div>
              ) : null}

              {aa_master.registration_end_datetime !== null &&
                aa_master.registration_end_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon color={"black"} size={"1rem"} className="pe-1" />
                      <b> End: </b>
                    </span>
                    <span>{aa_master.registration_end_datetime}</span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="sub-section-episode-detail ">
            <div className="inner-episode-design  episode-timeline-section p-2">
              <h5 className="sub-section-episode-detail-title">Submission</h5>
              {aa_master.video_submission_start_datetime !== null &&
                aa_master.video_submission_start_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b> Start: </b>
                    </span>
                    <span>{aa_master.video_submission_start_datetime}</span>
                  </span>
                </div>
              ) : null}

              {aa_master.video_submission_end_datetime !== null &&
                aa_master.video_submission_end_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon color={"black"} size={"1rem"} className="pe-1" />
                      <b>  End: </b>
                    </span>
                    <span>{aa_master.video_submission_end_datetime}</span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="sub-section-episode-detail">
            <div className="inner-episode-design  episode-timeline-section p-2">
              <h5 className="sub-section-episode-detail-title">Review</h5>
              {aa_master.review_start_datetime !== null &&
                aa_master.review_start_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <StartTimeIcon color={"black"} size={"1rem"} />
                      <b> Start: </b>
                    </span>
                    <span>{aa_master.review_start_datetime}</span>
                  </span>
                </div>
              ) : null}

              {aa_master.review_end_datetime !== null &&
                aa_master.review_end_datetime !== "" ? (
                <div className="">
                  <span className="video-status episode-status episode-time-main">
                    <span className="d-flex align-items-center">
                      <EndTimeIcon color={"black"} size={"1rem"} className="pe-1" />
                      <b> End: </b>
                    </span>
                    <span>{aa_master.review_end_datetime}</span>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={`episodes-container row ${visible ? 'visible' : 'hidden'}`} >
        {aa_master.episodes.length > 0
          ? aa_master.episodes.map((episode) => (
            <div className="eps-content">
              <div className=" episode-container inner-episode-design">
                <div className="episode-content">
                  <h4 className="inner-episode-title video-title ">
                    {episode.name}
                  </h4>
                  <div className="episode-structure">
                    <div className="episode-thumbnail">
                      <div className="video-thumbnail-container">
                        <img src="/images/logo/gsa.svg" alt="" />
                      </div>
                    </div>
                    <div className="inner-epsidoe-details">
                      <div className="row ">
                        <div className="col-xl-4 col-lg-6 col-sm-6 ">
                          {episode.start_datetime ? (
                            <span className="video-status episode-status">
                              <span className="d-flex align-items-center">
                                <StartTimeIcon color={"black"}
                                  size={'1rem'} />
                                <b>Start Datetime: </b>
                              </span>
                              <span>{episode.start_datetime} </span>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-6 ">
                          {episode.end_datetime ? (
                            <span className="video-status episode-status">
                              <span className="d-flex align-items-center">
                                <EndTimeIcon color={"black"} size={"1rem"} />
                                <b>End Datetime: </b>
                              </span>
                              <span>{episode.end_datetime} </span>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-6 ">
                          {episode.participants_count ? (
                            <span className="video-status episode-status">
                              <span className="d-flex align-items-center">
                                <ParticipantIcon
                                  color={"black"}
                                  size={"1rem"}
                                  className="pe-1"
                                />
                                <b>Participants Count:</b>
                              </span>
                              <span>{episode.participants_count}</span>
                            </span>
                          ) : null}
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-6 ">
                          <div className="article-status-wrapper flex ">
                            {episode.is_voting_line_open ? (
                              <span className="article-status success-message">
                                <GoIssueClosed />
                                <span className="ps-1">
                                  Voting line is open
                                </span>
                              </span>
                            ) : (
                              <span className="article-status error-message">
                                <SlClose />
                                <span className="ps-1">
                                  Voting line is closed
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-6 ">
                          <div className="article-status-wrapper flex ">
                            {episode.is_active ? (
                              <span className="article-status success-message">
                                <GoIssueClosed />
                                <span className="ps-1">
                                  Active
                                </span>
                              </span>
                            ) : (
                              <span className="article-status error-message">
                                <SlClose />
                                <span className="ps-1">
                                  Not Active
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="episode-action-buttons-wrapper">
                  <Link
                    className="mb-2 button-design episode-button "
                    key={episode.id}
                    to={`/amazingadaniansmaster/update/${aa_master.id}/episode/update/${episode.id}`}
                    title="Edit"
                  >
                    <BiSolidEdit />
                  </Link>
                </div>
              </div>
            </div>
          ))
          : null}
      </div>

    </div>
  );
};
export default AaMasterListUI;
