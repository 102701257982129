import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { HTTP } from "../../api/http-common";
import Swal from "sweetalert2";
import Loader from "../../components/loader";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorHandlingComponent from "../../components/ErrorHandlingComponent";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import EpisodeListUI from "../../components/EpisodeListUI";
import useUserRoleEffect from "../../components/userRoleEffect";
import { CommonTitle, DateUpdateUI } from "../../components/commonComponents";

export default function AaMasterAddUpdate() {
  const navigate = useNavigate();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const access_token = localStorage.getItem("access_token");
  const userObj = JSON.parse(localStorage.getItem("userObj"));
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [pageType, setPageType] = useState("Add");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [startDatetime, setStartDatetime] = useState(new Date());
  const [startDatetimeError, setStartDatetimeError] = useState("");

  const [endDatetime, setEndDatetime] = useState(new Date());
  const [endDatetimeError, setEndDatetimeError] = useState("");

  const [registrationStartDatetime, setRegistrationStartDatetime] = useState(
    new Date()
  );
  const [registrationStartDatetimeError, setRegistrationStartDatetimeError] =
    useState("");

  const [registrationEndDatetime, setRegistrationEndDatetime] = useState(
    new Date()
  );
  const [registrationEndDatetimeError, setRegistrationEndDatetimeError] =
    useState("");

  const [videoSubmissionStartDatetime, setVideoSubmissionStartDatetime] =
    useState(new Date());
  const [
    videoSubmissionStartDatetimeError,
    setVideoSubmissionStartDatetimeError,
  ] = useState("");

  const [videoSubmissionEndDatetime, setVideoSubmissionEndDatetime] = useState(
    new Date()
  );
  const [videoSubmissionEndDatetimeError, setVideoSubmissionEndDatetimeError] =
    useState("");

  const [reviewStartDatetime, setReviewStartDatetime] = useState(new Date());
  const [reviewStartDatetimeError, setReviewStartDatetimeError] = useState("");

  const [reviewEndDatetime, setReviewEndDatetime] = useState(new Date());
  const [reviewEndDatetimeError, setReviewEndDatetimeError] = useState("");

  const [episodes, setEpisodes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState({ name: "" });
  const [pagination, setPagination] = useState({ page: "1", limit: "10" });
  const [isActive, setIsActive] = useState();

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const loadAaMasterData = async () => {
    try {
      setIsLoading(true);
      let config = {
        headers: {
          "Content-Type": "application/json",
          is_active: isActive,
          token: access_token,
        },
      };
      HTTP.get("amazing_adanians/master/" + id, config)
        .then((response) => {
          if (response.status === 200) {
            setName(response.data.content.name);
            setStartDatetime(response.data.content.start_datetime_utc);
            setEndDatetime(response.data.content.end_datetime_utc);
            setRegistrationStartDatetime(
              response.data.content.registration_start_datetime_utc
            );
            setRegistrationEndDatetime(
              response.data.content.registration_end_datetime_utc
            );
            setVideoSubmissionStartDatetime(
              response.data.content.video_submission_start_datetime_utc
            );
            setVideoSubmissionEndDatetime(
              response.data.content.video_submission_end_datetime_utc
            );
            setReviewStartDatetime(
              response.data.content.review_start_datetime_utc
            );
            setReviewEndDatetime(response.data.content.review_end_datetime_utc);
            setEpisodes(response.data.content.episodes);
          }
          if (response.status === 204) {
            Swal.fire("", "Amazing Adanians Master Not Found");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let cid = id;
    if (
      cid === "add" ||
      cid === "undefined" ||
      cid === undefined ||
      cid === ":id"
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setPageType("Update");
      loadAaMasterData();
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  const resetValidationErrors = () => {
    setNameError("");
    setStartDatetimeError("");
    setEndDatetimeError("");
    setRegistrationStartDatetimeError("");
    setRegistrationEndDatetimeError("");
    setVideoSubmissionStartDatetimeError("");
    setVideoSubmissionEndDatetimeError("");
    setReviewStartDatetimeError("");
    setReviewEndDatetimeError("");
  };

  const validateField = () => {
    let checked = true;

    resetValidationErrors();

    if (name === "") {
      setNameError("Please enter name");
      checked = false;
    } else if (name.length < 5) {
      setNameError("Minimum 5 character required");
      checked = false;
    } else if (name.length > 50) {
      setNameError("Max character limit 50");
      checked = false;
    }
    if (startDatetime == "" || startDatetime == null) {
      setStartDatetimeError("Select start datetime");
      checked = false;
    }
    if (endDatetime == "" || endDatetime == null) {
      setEndDatetimeError("Select end datetime");
      checked = false;
    }
    if (registrationStartDatetime == "" || registrationStartDatetime == null) {
      setRegistrationStartDatetimeError("Select registration start datetime");
      checked = false;
    }
    if (registrationEndDatetime == "" || registrationEndDatetime == null) {
      setRegistrationEndDatetimeError("Select registration end datetime");
      checked = false;
    }
    if (
      videoSubmissionStartDatetime == "" ||
      videoSubmissionStartDatetime == null
    ) {
      setVideoSubmissionStartDatetimeError(
        "Select video submission start datetime"
      );
      checked = false;
    }
    if (
      videoSubmissionEndDatetime == "" ||
      videoSubmissionEndDatetime == null
    ) {
      setVideoSubmissionEndDatetimeError(
        "Select video submission end datetime"
      );
      checked = false;
    }
    if (reviewStartDatetime == "" || reviewStartDatetime == null) {
      setReviewStartDatetimeError("Select review start datetime");
      checked = false;
    }
    if (reviewEndDatetime == "" || reviewEndDatetime == null) {
      setReviewEndDatetimeError("Select review end datetime");
      checked = false;
    }

    return checked;
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag, search, pagination]);

  const handleSubmit = async () => {
    if (validateField()) {
      try {
        setIsLoading(true);
        let config = {
          headers: { "Content-Type": "application/json", token: access_token },
        };
        let cid =
          id === "add" || id === "undefined" || id === undefined || id === ":id"
            ? 0
            : id;

        let fields = {
          name: name,
          start_datetime: startDatetime,
          end_datetime: endDatetime,
          registration_start_datetime: registrationStartDatetime,
          registration_end_datetime: registrationEndDatetime,
          video_submission_start_datetime: videoSubmissionStartDatetime,
          video_submission_end_datetime: videoSubmissionEndDatetime,
          review_start_datetime: reviewStartDatetime,
          review_end_datetime: reviewEndDatetime,
        };

        await HTTP.put(
          "amazing_adanians/master/create/update/" + cid,
          fields,
          config
        )
          .then(async (response) => {
            if (response.status === 200) {
              if (cid == 0) {
                Swal.fire("", response.data.message, "success");
                navigate(
                  `/amazingadaniansmaster/update/${response.data.content.id}/episode/add`
                );
              } else {
                Swal.fire("", response.data.message, "success");
                navigate("/amazingadaniansmaster/list");
              }
            } else if (response.status === 204) {
              Swal.fire("", "Amazing Adanians Master Not Found", "error");
              navigate("/amazingadaniansmaster/list");
            } else if (response.status === 403) {
              Swal.fire("", response.data.content, "error");
              navigate("/amazingadaniansmaster/list");
            }
          })
          .catch((error) => {
            setError(error);
            setRerenderFlag(true);
            loadAaMasterData();
          });

        setIsLoading(false);
      } catch (error) {
        setError(error);
        setRerenderFlag(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => { }, [isLoading]);

  const handleBack = () => {
    navigate("/amazingadaniansmaster/list");
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className="post-upload-wrapper">
        <div className="d-flex justify-content-between">
          {/* <h2 style={{ fontSize: "25px" }}>{pageType} Amazing Adanians</h2> */}
          <CommonTitle PageTitle='Amazing Adanians' />
          <Link to="/amazingadaniansmaster/list">
            <span className="video-page-icons"></span>
            <span>Back</span>
          </Link>
        </div>
        <div className="post-upload-main ">
          <div className="amazing-adanian-wrapper row">
            <div className="adanian-name col-xl-12">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-input"
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {nameError && <span className="error-message">{nameError}</span>}

              <div className="row episode-date-time-select-wrapper">
                <div className="col-xl-3 episode-timeline">
                  <div className="inner-date-section episode-timeline-section  ">
                    <h5 class="sub-section-episode-detail-title">Duration</h5>
                    <span className="inner-date-timepicker">
                      <span className="d-block" htmlFor="datetime">
                        Start{" "}
                      </span>
                      <DateTimePicker
                        value={startDatetime}
                        onChange={(date) => setStartDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {startDatetimeError && (
                          <span className="error-message">
                            {startDatetimeError}
                          </span>
                        )}
                      </p>

                      <span className="d-block" htmlFor="datetime">
                        End{" "}
                      </span>
                      <DateTimePicker
                        value={endDatetime}
                        onChange={(date) => setEndDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {endDatetimeError && (
                          <span className="error-message">
                            {endDatetimeError}
                          </span>
                        )}
                      </p>
                    </span>
                  </div>
                </div>
                {/* <DateUpdateUI DateSectionTitle ='Duration'/> */}

                <div className="col-xl-3 episode-timeline">
                  <div className="inner-date-section episode-timeline-section  ">

                    <h5 class="sub-section-episode-detail-title">Registration</h5>

                    <span>
                      <label htmlFor="datetime"> Start </label>
                      <br></br>
                      <DateTimePicker
                        value={registrationStartDatetime}
                        onChange={(date) => setRegistrationStartDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {registrationStartDatetimeError && (
                          <p className="error-message">
                            {registrationStartDatetimeError}
                          </p>
                        )}
                      </p>

                      <label htmlFor="datetime">End </label>
                      <br></br>
                      <DateTimePicker
                        value={registrationEndDatetime}
                        onChange={(date) => setRegistrationEndDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {registrationEndDatetimeError && (
                          <p className="error-message">
                            {registrationEndDatetimeError}
                          </p>
                        )}
                      </p>
                    </span>
                  </div>
                </div>
                {/* <DateUpdateUI DateSectionTitle='Registration'/> */}

                <div className="col-xl-3 episode-timeline">
                  <div className="inner-date-section episode-timeline-section  ">
                    <h5 class="sub-section-episode-detail-title">
                      Video submission
                    </h5>
                    <span>
                      <label htmlFor="datetime"> Start </label>
                      <br></br>
                      <DateTimePicker
                        value={videoSubmissionStartDatetime}
                        onChange={(date) =>
                          setVideoSubmissionStartDatetime(date)
                        }
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {videoSubmissionStartDatetimeError && (
                          <span className="error-message">
                            {videoSubmissionStartDatetimeError}
                          </span>
                        )}
                      </p>

                      <label htmlFor="datetime"> End </label>
                      <br></br>
                      <DateTimePicker
                        value={videoSubmissionEndDatetime}
                        onChange={(date) => setVideoSubmissionEndDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {videoSubmissionEndDatetimeError && (
                          <span className="error-message">
                            {videoSubmissionEndDatetimeError}
                          </span>
                        )}
                      </p>
                    </span>
                  </div>
                </div>
                {/* <DateUpdateUI DateSectionTitle='Video Submission'/> */}

                {/* <DateUpdateUI DateSectionTitle='Review Duration'/> */}
                <div className="col-xl-3 episode-timeline">
                  <div className="inner-date-section episode-timeline-section  ">
                    <h5 class="sub-section-episode-detail-title">
                      Review Duration
                    </h5>
                    <span>
                      <label htmlFor="datetime"> Start </label>
                      <br></br>
                      <DateTimePicker
                        value={reviewStartDatetime}
                        onChange={(date) => setReviewStartDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {reviewStartDatetimeError && (
                          <span className="error-message">
                            {reviewStartDatetimeError}
                          </span>
                        )}
                      </p>
                      <label htmlFor="datetime"> End </label>
                      <br></br>
                      <DateTimePicker
                        value={reviewEndDatetime}
                        onChange={(date) => setReviewEndDatetime(date)}
                        format="dd/MM/y h:mm a"
                      />
                      <p>
                        {reviewEndDatetimeError && (
                          <p className="error-message">
                            {reviewEndDatetimeError}
                          </p>
                        )}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
        <div className="d-flex justify-content-center" style={{paddingTop:"10px"}}>
          <button
            type="submit"
            className="form-submit button-design"
            onClick={handleSubmit}
          >
            Submit
          </button>
          &nbsp;
          <button className="form-submit button-design" onClick={handleBack}>
            <span className="text-white">Back</span>
          </button>
        </div>
      </div>

      {/* </div> */}

      {id != undefined && (
        <div className="episode-wrapper">
          <span className="episode-upload-button-wrapper d-flex justify-content-between align-items-center">
            <h2 className="mb-2 fs-4 fw-semibold">Episodes</h2>
            <Link
              to={`/amazingadaniansmaster/update/${id}/episode/add`}
              className="button-design episode-button border-top flexbox mt-2"
              title="Add New Episode"
            >
              +
            </Link>
          </span>

          <div className="episode-inner video-table-main">
            {episodes.length > 0 ? (
              episodes.map((episode) => (
                <EpisodeListUI
                  key={episode.id}
                  episode={episode}
                  aa_master_id={id}
                  loadEpisodes={loadAaMasterData}
                />
              ))
            ) : (
              <div className="text-center">

                <span>No episodes found</span>
              </div>
            )}
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
}
