import React, { useState, useEffect } from "react";
import ErrorHandlingComponent from "../components/ErrorHandlingComponent";
import Loader from "../components/loader";
import { validateEmail } from "../components/common";
import { HTTP } from "../api/http-common";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs() {
  const navigate = useNavigate();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [captchaError, setCaptchaError] = useState("")
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const resetForm = () => {
    setEmail("");
    setMessage("");
    setEmailError("");
    setMessageError("");
    setCaptchaError("");
    setIsCaptchaCompleted(false);
  };

  const resetValidationErrors = () => {
    setEmailError("");
    setMessageError("");
  };

  const validateField = () => {
    resetValidationErrors();

    let checked = true;
    if (email === "") {
      setEmailError("Please enter email");
      if (checked) {
        checked = false;
      }
    } else if (email !== "" && !validateEmail(email)) {
      setEmailError("Please enter valid email");
      if (checked) {
        checked = false;
      }
    } else if (email.length < 5) {
      setEmailError("Please enter minimum 5 characters");
      if (checked) {
        checked = false;
      }
    } else if (email.length > 100) {
      setEmailError("Max character limit is 100");
      if (checked) {
        checked = false;
      }
    }

    if (message === "") {
      setMessageError("Please enter message");
      if (checked) {
        checked = false;
      }
    } else if (message.length < 20) {
      setMessageError("Please enter minimum 20 characters");
      if (checked) {
        checked = false;
      }
    } else if (message.length > 500) {
      setMessageError("Max character limit is 500");
      if (checked) {
        checked = false;
      }
    }

    if (!isCaptchaCompleted) {
      setCaptchaError("Please verify CAPTCHA");
      if (checked) {
        checked = false;
      }
    }

    return checked;
  };

  const handleSubmit = () => {
    if (validateField()) {
      setIsLoading(true);

      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };

      const formData = new FormData();
      formData.append("email", email);
      formData.append("message", message);
      formData.append("recaptcha_response", recaptchaResponse);
      HTTP.post("contact_us", formData, config)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("", response.data.message, "success");
            resetForm();
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  useEffect(() => {}, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {error && <ErrorHandlingComponent err={error} />}
      <div className="container">
        <form className="form-inner row">
          <div className="row mg-btm col-xl-6 col-md-10 col-sm-10 center-width box-shadow-effect border-radius border contact-us-wrapper">
            <div className="contact-us-header">
              {/* <div className="contact-us-image">
                <img src="/images/logo/gsa.svg" alt="" />
              </div> */}
              <h2 className="form-heading text-center">Contact Us</h2>
            </div>
            <div className="col-lg-12 mb-3">
              <label htmlFor="email" className="mb-2">
                Email
              </label>
              <input
                type="text"
                placeholder="Enter Your Email"
                id="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="error-message">{emailError}</span>
            </div>
            <div className="col-lg-12 mb-3">
              <label htmlFor="message" className="mb-2">
                Message
              </label>
              <textarea
                placeholder="Enter Your Message"
                rows="6"
                id="message"
                className="form-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <span className="error-message">{messageError}</span>
            </div>

            <div className="col-lg-12 mb-3">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(value) => {
                  setRecaptchaResponse(value);
                  setIsCaptchaCompleted(true);
                }}
              />
              {captchaError && <span className="error-message">{captchaError}</span>}
            </div>

            <div className="d-flex justify-content-center button-wrapper">
              <button
                type="button"
                className="form-submit button-design"
                onClick={handleSubmit}
                disabled={!isCaptchaCompleted}
              >
                Submit
              </button>
              <button
                type="button"
                className="form-submit button-design"
                onClick={handleBack}
              >
                Back
              </button>

            </div>
          </div>
        </form>
      </div>
    </>
  );
}
