import React, { useState, useEffect } from 'react'
import ReactDoughnut from '../components/ReactDoughnut'
import ChannelChart from '../components/Channel.Chart'
import { BsAndroid2 } from "react-icons/bs";
import { IoLogoApple } from "react-icons/io5";
import useUserRoleEffect from '../components/userRoleEffect';
// import { CommonTitle, MsLogo } from '../components/commonComponents';

export default function Dashboard() {
    const [userObj, setUserObj] = useState(JSON.parse(localStorage.getItem('userObj')));

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);
    return (
        // <>
        //     <div className="stats-main row">
        //         {/* section 1*/}
        //         <div className="stats-sub">
        //             <span className="stats-title"> Active Users</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>9.6%</span></span>
        //         </div>

        //         {/* section 2*/}
        //         <div className="stats-sub">
        //             <span className="stats-title"> Returning Users</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 3*/}
        //         <div className="stats-sub">
        //             <span className="stats-title"> Engagement Sessions</span>
        //             <h2 className="stats-num">65.46%</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 4*/}
        //         <div className="stats-sub">
        //             <span className="stats-title"> Sesions</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 5*/}
        //         <div className="stats-sub">
        //             <span className="stats-title"> Avg. Session Duration</span>
        //             <h2 className="stats-num">14m 37s</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>
        //     </div>
        //     {/* MainMenu */}
        //     <div className="graph-wrapper mg-btm-50 row">
        //         {/* doughtnut graphs main*/}
                
        //         <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 row mg-t-50 align-items-center">
        //         <div className='d-flex '>
        //         <p className="chart-title">Traffic Acquisition Regions</p>
        //         <p className="chart-title">Traffic Acquisition Regions</p>
        //         </div>
        //             <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                        
        //                 <div className="chart-main">
        //                     <div className="chart">
        //                         <ReactDoughnut />
        //                     </div>
        //                 </div>

        //             </div>

        //             <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                        
        //                 <div className="chart-main">
        //                     <div className="chart">
        //                     <p className="chart-title">Traffic Acquisition Regions</p>
        //                         <ChannelChart />
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         {/*-aside stats*/}
        //         <div className="col-lg-3 col-md-12 col-sm-12 p-0">
        //             <div className="aside-stats-wrapper">
        //                 {/* stats 1*/}
        //                 <div className="aside-stats-content">
        //                     <span className="aside-stats-title">
        //                         New User
        //                     </span>
        //                     <div className="aside-stats-num">
        //                         <span className="h2 stats-num">
        //                             346,907
        //                         </span>
        //                         <span className="stats-status">
        //                             <span> <i className="fa-solid fa-arrow-up"></i></span>
        //                             <span>
        //                                 49.6%
        //                             </span>
        //                         </span>
        //                     </div>
        //                 </div>

        //                 {/* stats 2*/}
        //                 <div className="aside-stats-content">
        //                     <span className="aside-stats-title">
        //                         DAU/MAU
        //                     </span>
        //                     <div className="aside-stats-num">
        //                         <p className="h2 stats-num">
        //                             0.78
        //                         </p>
        //                         <span className="stats-status">
        //                             <span> <i className="fa-solid fa-arrow-up"></i></span>
        //                             <span>
        //                                 49.6%
        //                             </span>
        //                         </span>
        //                     </div>
        //                 </div>

        //                 {/* stats 3*/}
        //                 <div className="aside-stats-content">
        //                     <span className="aside-stats-title">
        //                         DAU/WAU
        //                     </span>
        //                     <div className="aside-stats-num">
        //                         <p className="h2 stats-num">
        //                             0.78
        //                         </p>
        //                         <span className="stats-status">
        //                             <span> <i className="fa-solid fa-arrow-up"></i></span>
        //                             <span>
        //                                 49.6%
        //                             </span>
        //                         </span>
        //                     </div>
        //                 </div>

        //                 {/* stats 4*/}
        //                 <div className="aside-stats-content-last">
        //                     <span className="aside-stats-title">
        //                         30-Day Active Users
        //                     </span>
        //                     <div className="aside-stats-num">
        //                         <p className="h2 stats-num">
        //                             346,907
        //                         </p>
        //                         <span className="stats-status">
        //                             <span> <i className="fa-solid fa-arrow-up"></i></span>
        //                             <span>
        //                                 49.6%
        //                             </span>
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         {/* DOWNLOADS*/}
        //     </div>
        //     <span className="stats-num d-inline-block mb-4">
        //         Downloads
        //     </span>

        //     <div className="stats-main row">
        //         {/* section 1*/}
        //         <div className=" stats-below stats-sub">
        //             <span className="stats-title">
        //                 <span className='pe-2'>
        //                     <BsAndroid2 />
        //                 </span>
        //                 Android Users</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>9.6%</span></span>
        //         </div>

        //         {/* section 2*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title ">
        //                 <span className='pe-2'>
        //                     <IoLogoApple />
        //                 </span>
        //                 Returning Users
        //             </span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 3*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title"> Engagement Sessions</span>
        //             <h2 className="stats-num">65.46%</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 4*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title"> Sesions</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 5*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title"> Avg. Session Duration</span>
        //             <h2 className="stats-num">14m 37s</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 2*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title"> Returning Users</span>
        //             <h2 className="stats-num">346,907</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>

        //         {/* section 3*/}
        //         <div className="stats-sub stats-below ">
        //             <span className="stats-title"> Engagement Sessions</span>
        //             <h2 className="stats-num">65.46%</h2>
        //             <span className="stats-status"><span> <i className="fa-solid fa-arrow-up"></i></span> <span>40.0%</span></span>
        //         </div>
        //     </div>
        // </>
        <>
        <div className='gsa-dashboard-logo'>
            <img src="/images/logo/gsa.svg"></img>
        </div>
        </>
    )
}
