import React from 'react';
import { Link } from 'react-router-dom';


export default function Live({ children }) {
  function updateFileInfo() {
    var fileInput = document.getElementById('fileInput');
    var fileInfo = document.getElementById('fileInfo');

    if (fileInput.files.length > 0) {
      // Get the name of the chosen file
      var fileName = fileInput.files[0].name;
      fileInfo.textContent = fileName;
    } else {
      fileInfo.textContent = 'No file chosen';
    }
  }

  // Call the updateFileInfo function
  React.useEffect(() => {
    updateFileInfo();
  }, []);

  return (
    <>
      <div className="post-upload-wrapper">
        <div className="post-upload-main row">
          <div className="headline col-xl-12 my-2">
            <label>Head Line</label>
            <input type="text" className="form-input" />
          </div>
          <div className="intro col-xl-12 my-2">
            <label>Intro</label>
            <textarea name="" id="" rows="4" className="form-input"></textarea>
          </div>
          <div className="headline my-3">
            <label>Video ID</label>
            <input type="text" className="form-input" />
          </div>
          <div className="thumbnail-upload-wrapper row">
            <div className="thumbnail-upload-left col-xl-3">
              <div className="mgm-50">
                <label>Date</label>
                <input type="datetime-local" className="form-input" />
              </div>
            </div>
            <div className="thumbnail-upload-right col-xl-9 my-2">
              <label>Thumbnail Upload</label>
              <div className="image-upload-wrapper">
                <div className="upload-main">
                  <div className="custom-file-input">
                    <input type="file" id="fileInput" className="hidden" accept=".jpeg, .jpg, .png" onChange={updateFileInfo} />
                    <label htmlFor="fileInput" className="custom-file-label">Upload Image</label>
                    <span id="fileInfo" className="file-info d-block text-center m-2">No file chosen</span>
                  </div>
                </div>
              </div>
            </div>
            <div >
              <button type="submit" className="button-design form-submit">Submit</button>
              <button className="button-design form-submit">
                <Link to='/dashboard'>
                  <span className='text-light '>
                    Back
                  </span>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
