import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { HTTP } from "../../api/http-common";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import useUserRoleEffect from "../../components/userRoleEffect";
import Loader from "../../components/loader";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorHandlingComponent from "../../components/ErrorHandlingComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CommonTitle } from "../../components/commonComponents";

export default function ArticleAddUpdate() {
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState("");
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const access_token = localStorage.getItem("access_token");
  const userObj = JSON.parse(localStorage.getItem("userObj"));
  const access_token_expires = localStorage.getItem("access_token_expires");
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [pageType, setPageType] = useState("Add");
  const [headLine, setHeadLine] = useState("");
  const [headLineError, setHeadLineError] = useState("");
  const [intro, setIntro] = useState("");
  const [introError, setIntroError] = useState("");
  const [date, setDate] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [byLine, setByLine] = useState("");
  const [byLineError, setByLineError] = useState("");
  const [bodyCopy, setBodyCopy] = useState("");
  const [bodyCopyError, setBodyCopyError] = useState("");
  const [metaTags, setMetaTags] = useState("");
  const [createdBy, setCreatedBy] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImgCover, setSelectedImgCover] = useState(null);
  const [selectedImgCoverError, setSelectedImgCoverError] = useState(null);
  const [imgCover, setImgCover] = useState(null);
  const imgCoverInputRef = useRef(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [selectedThumbnailError, setSelectedThumbnailError] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const thumbnailInputRef = useRef(null);

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const handleImgCoverChange = (event) => {

    const file = event.target.files[0];
    const maxSizeInBytes = 3 * 1024 * 1024; // 3 MB
    if (file.size > maxSizeInBytes) {
      setValidationMessage('Please select an image smaller than 3 MB.');
      // setSelectedImgCoverError('Please select an image smaller than 3 MB.');
      // Clear the file input field
      event.target.value = null;
      return false;
    } else {
      // Proceed with image upload
      setValidationMessage("");
      // setSelectedImgCoverError("");
      // Your upload logic here...
    }

    const img = new Image();

    img.onload = () => {
      const width = img.width;

      if (width < 1500) {
        setValidationMessage(
          "Please upload an image with a resolution of at least 1500 pixels."
        );
        event.target.value = null;
      } else {
        setValidationMessage("");
        console.log("Image uploaded successfully!");
      }
    };
    setSelectedImgCover(event.target.files[0]);
    setImgCover(URL.createObjectURL(event.target.files[0]));
    if (imgCoverInputRef.current) {
      imgCoverInputRef.current.value = "";
    }
  };

  const handleThumbnailChange = (event) => {
    setSelectedThumbnail(event.target.files[0]);
    setThumbnail(URL.createObjectURL(event.target.files[0]));
    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = "";
    }
  };

  const handleStatusCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const handleEditorChange = (html) => {
    setBodyCopy(html);
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const loadArticleData = async () => {
    try {
      setIsLoading(true);
      let config = {
        headers: { "Content-Type": "application/json", token: access_token },
      };
      HTTP.get("article/" + id, config)
        .then((response) => {
          if (response.status === 200) {
            setHeadLine(response.data.content.head_line);
            setIntro(response.data.content.intro);
            setIsActive(response.data.content.is_active);
            setDate(new Date(response.data.content.date));
            setByLine(response.data.content.by_line);
            setBodyCopy(response.data.content.body_copy);
            setMetaTags(response.data.content.meta_tags);
            setImgCover(response.data.content.cover_img_url);
            setThumbnail(response.data.content.thumbnail_img_url);
            setCreatedBy(response.data.content.created_by);
          }
          if (response.status === 204) {
            Swal.fire("", "Article Not Found", "error");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const showSubmitButton = () => {
    let content_created_by = createdBy;
    let login_user_id = parseInt(userObj.id);
    let user_role = parseInt(userObj.role.id);
    let cid = id;

    if (
      cid === "add" ||
      cid === "undefined" ||
      cid === undefined ||
      cid === ":id"
    ) {
      return true;
    }
    if (user_role === 1) {
      return true;
    } else if (user_role === 4 || user_role === 2 || user_role === 3) {
      return login_user_id === content_created_by;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let cid = id;
    if (
      cid === "add" ||
      cid === "undefined" ||
      cid === undefined ||
      cid === ":id"
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setPageType("Update");
      loadArticleData();
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  const resetValidationErrors = () => {
    setHeadLineError("");
    setIntroError("");
    setBodyCopyError("");
    setSelectedImgCoverError("");
  };

  const validateField = () => {
    resetValidationErrors();

    let checked = true;
    if (headLine === "") {
      setHeadLineError("Please enter head line");
      if (checked) {
        checked = false;
      }
    } else if (headLine.length < 5) {
      setHeadLineError("Please enter minimum 5 character");
      if (checked) {
        checked = false;
      }
    } else if (headLine.length > 200) {
      setHeadLineError("Max character limit 200");
      if (checked) {
        checked = false;
      }
    }

    if (intro === "") {
      setIntroError("Please enter intro");
      if (checked) {
        checked = false;
      }
    } else if (intro.length < 5) {
      setIntroError("Minimum 5 character required");
      if (checked) {
        checked = false;
      }
    } else if (intro.length > 500) {
      setIntroError("Max character limit 500");
      if (checked) {
        checked = false;
      }
    }

    if (date == "" || date == null) {
      setDateError("Select Date");
      if (checked) {
        checked = false;
      }
    }
    if (byLine === "" || byLine === null) {
      setByLineError("Please enter By Line");
      if (checked) {
        checked = false;
      }
    } else if (byLine.length < 5) {
      setByLineError("Minimum 5 character required");
      if (checked) {
        checked = false;
      }
    } else if (byLine.length > 50) {
      setByLineError("Max character limit 50");
      if (checked) {
        checked = false;
      }
    }

    if (bodyCopy === "") {
      setBodyCopyError("Please enter body copy");
      if (checked) {
        checked = false;
      }
    } else if (bodyCopy.length < 20) {
      setBodyCopyError("Minimum 20 character required");
      if (checked) {
        checked = false;
      }
    }
    // else if (bodyCopy.length > 2000) {
    //   setBodyCopyError("Max character limit 2000");
    //   if (checked) {
    //     checked = false;
    //   }
    // }

    if (
      imgCover === null &&
      (selectedImgCover === null || selectedImgCover === "")
    ) {
      setSelectedImgCoverError("Please upload cover image");
      if (checked) {
        checked = false;
      }
    }

    // if (
    //   thumbnail === null &&
    //   (selectedThumbnail === null || selectedThumbnail === "")
    // ) {
    //   setSelectedThumbnailError("Please upload thumbnail");
    //   if (checked) {
    //     checked = false;
    //   }
    // }

    return checked;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const expirationDate = new Date(access_token_expires);
    // if (expirationDate.getTime() < Date.now()) {
    //     handleRefreshToken(setIsLoading, setError, setRerenderFlag);
    // }

    if (validateField()) {
      const access_token = localStorage.getItem("access_token");
      let config = {
        headers: { "Content-Type": "multipart/form-data", token: access_token },
      };
      let cid = id;

      if (
        cid === "add" ||
        cid === "undefined" ||
        cid === undefined ||
        cid === ":id"
      ) {
        cid = 0;
      }

      let formData = new FormData();
      formData.append("head_line", headLine);
      formData.append("intro", intro);
      if (isActive) {
        formData.append("is_active", isActive);
      } else {
        formData.append("is_active", false);
      }
      if (date) {
        formData.append("date", date.toLocaleDateString("en-GB"));
      }
      if (byLine) {
        formData.append("by_line", byLine);
      }
      formData.append("body_copy", bodyCopy);
      if (metaTags) {
        formData.append("meta_tags", metaTags);
      }
      if (selectedImgCover) {
        formData.append("cover_img", selectedImgCover);
      }

      if (selectedThumbnail) {
        formData.append("thumbnail", selectedThumbnail);
      }

      setIsLoading(true);
      await HTTP.put("article/create/update/" + cid, formData, config)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("", response.data.message, "success");
            navigate("/article/list");
          }
          if (response.status === 204) {
            Swal.fire("", "Article Not Found", "error");
          }
          if (response.status === 403) {
            Swal.fire("", response.data.content, "error");
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
          loadArticleData();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => { }, [isLoading]);

  const handleBack = () => {
    navigate("/article/list");
  };

  if (isLoading) {
    return <Loader />;
  }

  const quillStyle = {
    height: "calc(1.5em + 200px)", // You can adjust the height as needed
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "align-center",
    "align-right",
    "align-justify",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className="post-upload-wrapper">
        <div className="d-flex justify-content-between">
          {/* <h2 style={{ fontSize: "25px" }}>{pageType} Article</h2> */}
          <CommonTitle PageTitle='Article' />
          <Link to="/article/list">
            <span className="video-page-icons"></span>
            <span>Back</span>
          </Link>
        </div>
        <div className="post-upload-main row">
          <div className="headline col-xl-12 my-3">
            <label htmlFor="head_line">Head Line</label>
            <input
              type="text"
              id="head_line"
              className="form-input"
              value={headLine}
              onChange={(e) => {
                setHeadLine(e.target.value);
              }}
            />
            {headLineError && (
              <span className="error-message">{headLineError}</span>
            )}
          </div>
          <div className="intro col-xl-12 my-3">
            <label htmlFor="intro">Intro</label>
            <textarea
              className="form-input"
              id="intro"
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
            ></textarea>
            {introError && <span className="error-message">{introError}</span>}
          </div>
          <div className="thumbnail-upload-wrapper row">
            <div className="thumbnail-upload-left col-xl-3">
              <div className="my-2">
                <label htmlFor="date">Date</label>
                <br></br>
                <DatePicker
                  className="form-input"
                  selected={date}
                  onChange={(date) => setDate(date)}
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy"
                  timeCaption="Time"
                />
                <p>
                  {dateError && (
                    <span className="error-message">{dateError}</span>
                  )}
                </p>
              </div>
              <div className="my-2">
                <label htmlFor="by_line">By Line</label>
                <input
                  className="form-input"
                  id="by_line"
                  value={byLine}
                  onChange={(e) => setByLine(e.target.value)}
                />
                {byLineError && (
                  <span className="error-message">{byLineError}</span>
                )}
              </div>
              <div className="my-2">
                <span className="flex align-items-center">
                  <label htmlFor="is_active" className="pe-1">
                    Is Active
                  </label>
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleStatusCheckboxChange}
                  />
                </span>
              </div>
              {/* <div className="thumbnail-upload-right col-xl- my-2">
                <label htmlFor="file">Thumbnail Upload</label>
                <div className="image-upload-wrapper">
                  <div className="upload-main">
                    <div className="custom-file-input">
                      <input ref={thumbnailInputRef} type="file" className="form-input" id="thumbnail" accept=".jpeg, .jpg, .png" onChange={handleThumbnailChange} />
                      <label htmlFor="thumbnail" className="custom-file-label button-design">
                        Upload Thumbnail
                      </label>
                      {thumbnail ? (
                        <img src={thumbnail} width="100%" height="100%" alt="Cover" />
                      ) : (
                        <div>No thumbnail found</div>
                      )}
                      <p className="error-message text-center pb-2"> {selectedThumbnailError} </p>
                    </div>
                    <div></div>
                  </div>
                </div>
               </div> */}
            </div>
            <div className="thumbnail-upload-right col-xl-9 my-2">
              <div className="d-flex justify-content-between align-items-center row my-2">
                <div className="col-lg-2">
                  <label htmlFor="file">Cover Image Upload</label>
                </div>
                <div className="col-lg-3  p-0">
                  <span className="custom-file-input">
                    <input ref={imgCoverInputRef} type="file" className="form-input" id="fileInput" accept=".jpeg, .jpg, .png" onChange={handleImgCoverChange} />
                    <label htmlFor="fileInput" className="custom-file-label button-design">
                      Upload Image
                    </label>
                    <p className="error-message text-center pb-2"> {validationMessage} </p>
                  </span>
                </div>
              </div>


              <div className="image-upload-wrapper">
                <div className="upload-main">
                  {/* <div className="custom-file-input">
                                        
                                        <input ref={imgCoverInputRef} type="file" className="form-input" id="file" accept=".jpeg, .jpg, .png" onChange={handleImgCoverChange} />
                                        <label htmlFor="fileInput" className="custom-file-label button-design">Upload Image</label>
                                        <span id="fileInfo" className="file-info d-block text-center m-2">No file chosen</span>
                                        {imgCover ? (
                                            <img src={imgCover} width="100%" height="100%" alt="Cover" />
                                        ) : (
                                            <div width="100%" height="200">No cover image found</div>
                                        )}
                                        <p className="error-message text-center pb-2">{selectedImgCoverError}</p>
                                        <p className="error-message text-center pb-2">{validationMessage}</p>
                        </div> */}
                  <div className="custom-file-input">
                    {/* <span>
                    <input ref={imgCoverInputRef} type="file" className="form-input" id="fileInput" accept=".jpeg, .jpg, .png" onChange={handleImgCoverChange} />
                    <label htmlFor="fileInput" className="custom-file-label button-design">
                      Upload Image
                    </label> 
                    </span> */}

                    {imgCover ? (
                      <img src={imgCover} width="100%" height="100%" alt="Cover" />
                    ) : (
                      <div>No cover image found</div>
                    )}
                    <p className="error-message text-center pb-2"> {selectedImgCoverError} </p>

                  </div>
                  <div></div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 my-3 text-editor-wrapper">
              <label htmlFor="body_copy">Body copy</label>
              <ReactQuill
                value={bodyCopy}
                onChange={handleEditorChange}
                style={quillStyle}
                modules={quillModules}
                formats={quillFormats}
              />
              <span className="error-message">{bodyCopyError}</span>
            </div>

            <div className="col-xl-12 my-4">
              <label htmlFor="meta_tags">Meta Tags</label>
              <input
                type="text"
                id="meta_tags"
                className="form-input"
                value={metaTags}
                onChange={(e) => setMetaTags(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center">
              {showSubmitButton() && (
                <button
                  type="submit"
                  className="form-submit button-design"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
              &nbsp;
              <button
                className="form-submit button-design"
                onClick={handleBack}
              >
                <span className="text-white">Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
