import React from 'react';

const SearchBar = ({ children }) => {
  return (
      <div className="right-header align-items-center  row ">
        {children}
      </div>
  );
};

export default SearchBar;
