import React, { useState, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import useUserRoleEffect from "../components/userRoleEffect";

function useDebounceEffect(fn, waitTime, deps) {
  React.useEffect(() => {
    const timeout = setTimeout(fn, waitTime);
    return () => clearTimeout(timeout);
  }, deps);
}

async function canvasPreview(image, canvas, crop, scale = 1) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropImage() {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(16 / 9);
  const userObj = JSON.parse(localStorage.getItem("userObj"));

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result ? reader.result.toString() : "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    if (hiddenAnchorRef.current) {
      hiddenAnchorRef.current.href = blobUrlRef.current;
      hiddenAnchorRef.current.click();
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  function handleAspect(e) {
    const newAspect = e.target.value;
    const [width, height] = newAspect.split(":").map(Number);
    const newCrop = centerAspectCrop(
      imgRef.current.width,
      imgRef.current.height,
      width / height
    );
    setAspect(width / height);
    setCrop(newCrop);
    setCompletedCrop(
      convertToPixelCrop(newCrop, imgRef.current.width, imgRef.current.height)
    );
  }

  return (
    <div className="App row crop-image-wrapper">
      <div className="Crop-Controls col-xl-4 col-lg-4 col-md-3 col-sm-6 crop-image-controls">
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="button-design p-1 crop-image-button"
        />
      </div>
      <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 crop-image-controls">
        <span className="button-design  crop-image-button">
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </span>
      </div>
      <div className="col-xl-4 col-lg-5 col-md-5 col-sm-12 crop-image-controls">
        <span className="button-design  crop-image-button">
          <label htmlFor="ratio">Aspect Ratio (e.g., 16:9):</label>
          <input id="ratio" type="text" onChange={handleAspect} />
        </span>
      </div>
      <div className="reform row align-items-center">
        <div className="crop-image-wrapper col-lg-6 col-md-6">
          {!!imgSrc && (
            <div className="crop-image-main">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale})` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
        </div>
        {!!completedCrop && (
          <div className="crop-image-preview col-lg-6 col-md-6">
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        )}
      </div>
      {!!completedCrop && (
        <div className="d-flex justify-content-center">
          <button
            onClick={onDownloadCropClick}
            className="button-design form-submit"
          >
            Download Crop
          </button>
          <div style={{ fontSize: 12, color: "#666" }}></div>
          <a
            href="#hidden"
            ref={hiddenAnchorRef}
            download
            style={{
              position: "absolute",
              top: "-200vh",
              visibility: "hidden",
            }}
          >
            Hidden download
          </a>
        </div>
      )}
    </div>
  );
}
