import React, { useState, useEffect } from "react";
import { HTTP } from "../api/http-common";
import { Link } from "react-router-dom";
import BrightcoveVideo from "../components/BrightcoveVideo";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ErrorHandlingComponent from "./ErrorHandlingComponent";
import Loader from "./loader";
import { HiOutlineRefresh } from "react-icons/hi";
import { BiSolidEdit } from "react-icons/bi";

const ContentListUI = ({ content, statusList, handleReload }) => {
  const access_token = localStorage.getItem("access_token")
  const userObj = JSON.parse(localStorage.getItem("userObj"))
  const [error, setError] = useState(null);
  const [disabledStatusList, setDisabledStatusList] = useState([]);
  const [statusID, setStatusID] = useState(parseInt(content.status_id));
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userRole = userObj?.role?.id;

  const statusChange = (to_status_id) => {
    let formData = new FormData();
    formData.append("status_id", to_status_id);
    let id = content.id;

    let config = {
      headers: { "Content-Type": "multipart/form-data", token: access_token },
    };
    HTTP.put("content/update/status/" + id, formData, config)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("", response.data.message, "success");
          handleReload();
        }
      })
      .catch((error) => {
        setError(error);
        if (error.response.status == 403) {
          setStatusID(content.status_id);
        }
        setRerenderFlag(true);
      });
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  useEffect(() => {
    setStatusID(content.status_id);
    setStatusDisableList();
  }, [content.status_id]);

  const handleStatusOptionChange = (event) => {
    setStatusID(parseInt(event.target.value));
    if (statusID != parseInt(event.target.value)) {
      statusChange(parseInt(event.target.value));
    }
  };

  const handleStatusOptionChangeByLable = (value) => {
    if (!disabledStatusList.includes(value)) {
      setStatusID(parseInt(value));
      if (statusID != parseInt(value)) {
        statusChange(parseInt(value));
      }
    }
  };

  const refreshImages = () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "multipart/form-data", token: access_token },
    };
    let formData = new FormData();
    // console.log("video_id:", content.media_url);
    formData.append("video_id", content.media_url);
    setIsLoading(true);
    HTTP.post("content/refresh/images", formData, config)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("", response.data.message, "success");
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setStatusDisableList = () => {
    let content_created_by = content.created_by;
    let login_user_id = parseInt(userObj.id);
    let login_user_role = parseInt(userObj.role.id);

    let disabledSList = [];
    if (Array.isArray(statusList)) {
      statusList.forEach(function (status) {
        let status_id = status.id;
        let next_status_id = statusID + 1;
        if (login_user_role == 5 || login_user_id == content_created_by) {
          disabledSList.push(status_id);
        }

        if (status_id == 2) {
          //Reviewed
          if (login_user_role == 4) {
            //Creator
            disabledSList.push(status_id);
          }
        }
        if (status_id == 3) {
          //Published
          if (login_user_role == 1) {
            //Admin
          } else if (login_user_role == 2) {
            //Publisher
          } else if (login_user_role == 3) {
            //Reviewer
            disabledSList.push(status_id);
          } else if (login_user_role == 4) {
            //Creator
            disabledSList.push(status_id);
          }
        }
        if (status_id == 4) {
          //UnPublished
          if (login_user_role == 1) {
            //Admin
          } else if (login_user_role == 2) {
            //Publisher
            disabledSList.push(status_id);
          } else if (login_user_role == 3) {
            //Reviewer
            disabledSList.push(status_id);
          } else if (login_user_role == 4) {
            //Creator
            disabledSList.push(status_id);
          }
        }

        if (status_id < content.status_id) {
          //Can't select previous status
          disabledSList.push(status_id);
        }

        if (status_id > next_status_id) {
          disabledSList.push(status_id);
        }
      });
    }
    disabledSList = [...new Set(disabledSList)];

    setDisabledStatusList(disabledSList);
  };

  useEffect(() => { }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className="video-content  row">
        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-11 video-thumbnail-wrapper">
          <div className="video-thumbnail-container">
            {content.media_url && (
              <BrightcoveVideo videoId={content.media_url} />
            )}
          </div>
        </div>
        <div className="col-xl-10 col-lg-9 col-sm-8 col-md-8 d-flex justify-content-between align-items-baseline">
          <div className="content-details-left">
            <div className="d-flex justify-content-between align-items-baseline">
              <h4 className="video-title">{content.title}</h4>
            </div>
            <p className="video-description">{content.description}</p>
            <div className="video-status-wrapper">
              {content.created_at !== null &&
                content.created_at !== "" ? (
                <span className="video-status  fixed-video-status-box">
                  Created At : {content.created_at}
                </span>
              ) : null}
              <span className="video-status  fixed-video-status-box">
                Created By : {content.created_by_name}
              </span>
              {content.status_changed_by !== null &&
                content.status_changed_by !== "" ? (
                <span className="video-status  fixed-video-status-box">
                  Last Status Changed by : {content.status_changed_name}
                </span>
              ) : null}
              {content.section_name !== null && content.section_name !== "" ? (
                <span className="video-status  fixed-video-status-box">
                  Section : {content.section_name}
                </span>
              ) : null}
            </div>
          </div>

          <div className="content-buttons-right">
            <span className="content-button ">
              <Link
                className="view-analytics-button button-design"
                key={content.id}
                to={`/content/update/${content.id}`}
                title="Edit"
              >
                {/* <TbEdit /> */}
                <BiSolidEdit />
              </Link>
            </span>
            <span className="content-button " title="Refresh">
              {userRole === 1 && (
                <button
                  className="view-analytics-button button-design"
                  onClick={refreshImages}
                >
                  <HiOutlineRefresh />
                </button>
              )}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-evenly align-items-center">
          <div className="select-status">
            {Array.isArray(statusList) && statusList.length > 0 ? (
              statusList.map((status) => (
                <span
                  key={`span-${content.id}-${status.id}`}
                  className="status-option"
                >
                  <input
                    id={`status-${content.id}-${status.id}`}
                    type="radio"
                    name={`status-${content.id}-${status.id}`} // Use a unique name attribute
                    value={status.id}
                    checked={statusID === status.id}
                    disabled={disabledStatusList.includes(status.id)}
                    onChange={handleStatusOptionChange}
                  />
                  <label
                    htmlFor={`status-${content.id}-${status.id}`}
                    onChange={() => handleStatusOptionChangeByLable(status.id)}
                  >
                    {" "}
                    {status.name}{" "}
                  </label>
                </span>
              ))
            ) : (
              <>
                <p className="text-center mt-4">No contents found</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentListUI;
