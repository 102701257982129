import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';

ChartJs.register(Tooltip, Title, ArcElement, Legend);

export default function ChannelChart() {
  const [dynamicData, setDynamicData] = useState({
    datasets: [{
      data: [50, 30, 12, 8],
      backgroundColor: ["#a85607", "#021e32", "#c79f3b", "#848383"]
    }],
    labels: [
      'Direct',
      'Organic Search',
      'Mobile Push Notification',
      'Referral',
    ],
  });

  useEffect(() => {
    const fetchData = () => {
      fetch(
        // 'https://jsonplaceholder.typicode.com/users'
      )
        .then((data) => data.json())
        .then((res) => {
          console.log("resss", res)
          const label = [];
          const data = [];
          for (let i of res) {
            label.push(i.name);
            data.push(i.id)
          }
          setDynamicData({
            datasets: [{
              data: data,
              backgroundColor: [
                '#022560',
                '#5199c3',
                '#2c9f7a',
                '#610664',
                '#a87c0a',
                '#720809',
                '#48c9d8',
                '#89a06f',
                '#8e8c8c'
              ]
            }],
            labels: label,
          });
        })
        .catch(e => {
          console.log("error", e)
        })
    }
    fetchData();
  }, []);

  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
      },
    },
  };


  useEffect(() => {
  }, []);

  return (
    <div
      style={{ width: '380px', height: '380px' }}
    >
      <Doughnut data={dynamicData} options={options} />
    </div>
  );
}

