import React, { useState } from 'react';

const MultipleInputsForm = (onSubmit) => {
    const [formData, setFormData] = useState({ title: '', ndescription: '' });

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(formData); 
    };
    console.log(formData)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="title"
                placeholder="Title"
                value={formData.title}
                onChange={handleInputChange}
                className='form-input'
            />
            <input
                type="text"
                name="ndescription"
                placeholder="Description"
                value={formData.ndescription}
                onChange={handleInputChange}
                className='form-input my-2'
            />
        </form>
    );
};

export default MultipleInputsForm;
