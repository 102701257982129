import axios from 'axios';

export const HTTP = axios.create({

    baseURL: process.env.REACT_APP_API_BASE_URL,
    // baseURL: "http://127.0.0.1:8000/",
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json',
    }
});