import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from 'react-router-dom';
import { IoAddCircleOutline } from "react-icons/io5";
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import { BiSolidEdit } from "react-icons/bi";
import useUserRoleEffect from "../../components/userRoleEffect";
import { CommonTitle } from "../../components/commonComponents";

const SectionsList = () => {
    const [access_token, setAccessToken] = useState(localStorage.getItem('access_token'));
    const [sectionList, setSectionList] = useState([]);
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [userObj, setUserObj] = useState(JSON.parse(localStorage.getItem('userObj')));

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    // useEffect(() => {
    //     setIsLoading(true)
    //     if (userRole === 4) {
    //         navigate('/dashboard');
    //     }
    //     setIsLoading(false)
    // }, [userRole, navigate]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const grid = 5;

    const getItemStyle = (isDragging, draggableStyle, isActive) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        background: isActive ? (isDragging ? "lightgreen" : "grey") : "red",
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: '100%',
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedItems = reorder(
            sectionList,
            result.source.index,
            result.destination.index
        );
        setSectionList(updatedItems);
        let draggableId = result.draggableId;
        let newIndex = parseInt(result.destination.index) + 1;

        setNewIndexInList(draggableId, newIndex, updatedItems)
    };

    const setNewIndexInList = async (draggableId, newIndex, updatedItems) => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            id: draggableId,
            index: newIndex,
        };
        HTTP.post('/sections/reorder', fields, config)
            .then((response) => {
                if (response.status === 200) {
                }
                if (response.status === 204) {
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const loadSections = async () => {
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                name: '',
                without: '',
            },
            sorting: { field: 'order_index', type: 'asc' },
        };
        HTTP.post('/sections/list', fields, config)
            .then((response) => {
                if (response.status === 200) {
                    setSectionList(response.data);
                }
                if (response.status === 204) {
                    Swal.fire('', 'Status Not Found', 'error');
                }
            })
            .catch((error) => {
                setError(error);
            })
    };
    useEffect(() => {
        loadSections();
    }, []);


    return (
        <>
            <div className="article-wrapper">
                {rerenderFlag && <ErrorHandlingComponent err={error} />}
                {/* <h2 style={{ fontSize: '25px' }} >Sections </h2> */}
                <CommonTitle PageTitle='Sections'/>
                <div className="video-table-wrapper">
                    {/* button wrapper */}
                    <div className="action-buttons-wrapper row">
                        <div className="left-button col-lg-6 col-md-3 col-6">
                            &nbsp;
                        </div>
                        <div className="right-button col-lg-6 col-md-9 col-6">
                            <div className="action-buttons">
                                <Link to='/sections/add'>
                                    <div className="video-table-button-structure bg-blue" 
                                    style={{ padding: '7px 25px' }}
                                    >
                                        <span className='video-page-icons'>
                                            <IoAddCircleOutline />
                                        </span>
                                        <span>
                                            Add
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {Array.isArray(sectionList) && sectionList.length > 0 ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {sectionList.map((section, index) => (
                                            <Draggable key={section.id.toString()} draggableId={section.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="d-flex align-items-center justify-content-between"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                            section.is_active
                                                        )}
                                                    >
                                                        {section.name}
                                                        {section.is_active}
                                                        <Link className="view-analtyics-button button-design " style={{ float: 'right' }} key={section.id} to={`/sections/update/${section.id}`} title='Edit'>
                                                            <BiSolidEdit />
                                                        </Link>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (
                        null
                    )}
                </div>
            </div>
        </>
    );
};

export default SectionsList;
