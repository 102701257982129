import React, { useState, useEffect } from "react";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/scale.css";
import { PiSubtitlesLight } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { RxSection } from "react-icons/rx";
import {
  DashboardIcon,
  ArticleIcon,
  SendNotification,
  VideoSection,
  ParticipantIcon,
  AdanianIcon,
} from "../components/SideBarIcons";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export default function Sidebar({ onImageClick }) {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [sideBarWidth, setSideBarWidth] = useState(window.innerWidth);
  const userObj = JSON.parse(localStorage.getItem("userObj"));

  const userRole = userObj?.role?.id;

  const handleImageClick = () => {
    setExpanded(!expanded);
    onImageClick(!expanded);
  };

  const handleTitleClick = () => {
    if (sideBarWidth <= 430) {
      setExpanded(false);
      setSideBarWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    function detectTouchDevice() {
      return "ontouchstart" in window || navigator.maxTouchPoints;
    }

    setIsTouchDevice(detectTouchDevice());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSideBarWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`left-sidebar col-lg-3 ${expanded ? "expanded" : "collapsed"}`}
        style={{
        transition: "width 0.5s ease",
        }}
    >
      <div className="mg-btm sidebar-logo-wrapper">
        <Link to="/">
          <span
            className={`sidebar-logo ${location.pathname.includes("/") ? "    " : ""
              }`}
            onClick={() => handleTitleClick()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleTitleClick();
              }
            }}
          >
            <img src="/images/logo/gsa.svg" alt="" />
          </span>
        </Link>
      </div>

      <ul className="sidebar hovering-element">
        <Tippy
          content={"Dashboard"}
          placement={"right-end"}
          className={isTouchDevice ? "touch-device" : "hover-element "}
          animation={"scale"}
          theme={"tomato"}
          arrows={"Large"}
        >
          <div
            className={`sidebar-tooltip sidebar-title ${location.pathname.includes("/dashboard") ? "selected" : ""
              }`}
            data-text="Dashboard"
            onClick={() => handleTitleClick()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleTitleClick();
              }
            }}
          >
            <Link to="/dashboard">
              <span className="sidebar-link-icons">
                <DashboardIcon color="white" size={'1em'} />
              </span>
              <span
                className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
              >
                Dashboard
              </span>
            </Link>
          </div>
        </Tippy>
        <div></div>
        {userRole === 1 && (
          <Tippy
            content={"Users"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
            touch={isTouchDevice}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/user") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/user/list">
                <span className="sidebar-link-icons">
                  <FaRegUser />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Users
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole !== 6 && (
          <Tippy
            content={"Videos"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
            touch={isTouchDevice}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/content") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/content/list">
                <span className="sidebar-link-icons">
                  <VideoSection color="white"></VideoSection>
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Videos
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && userRole !== 6 && (
          <Tippy
            content={"Articles"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
            touch={isTouchDevice}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/article") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/article/list">
                <span className="sidebar-link-icons">
                  <ArticleIcon color="white" size={'1em'} />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Articles
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && (
          <Tippy
            content={"Sections"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/sections") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/sections/list">
                <span className="sidebar-link-icons">
                  <RxSection />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Sections
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && (
          <Tippy
            content={"Static Infos"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/staticinfo") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/staticinfo/list">
                <span className="sidebar-link-icons">
                  <PiSubtitlesLight />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Static Infos
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && (
          <Tippy
            content={"Hero Posters"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/heroposter") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/heroposter/list">
                <span className="sidebar-link-icons">
                  <TfiLayoutSliderAlt />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Hero posters
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {(userRole === 1 || userRole === 6) && (
          <Tippy
            content={"Participation"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/participation") ? "selected" : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/participation/list">
                <span className="sidebar-link-icons">
                  <ParticipantIcon
                    color={"white"}
                    size={"1em"}
                    className="pe-1"
                  />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Participation
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && (
          <Tippy
            content={"Send Notification"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/notification/send")
                  ? "selected"
                  : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/notification/send">
                <span className="sidebar-link-icons">
                  <SendNotification color={"white"} />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Send Notification
                </span>
              </Link>
            </div>
          </Tippy>
        )}

        {userRole === 1 && (
          <Tippy
            content={"Amazing Adanians"}
            placement={"right-end"}
            animation={"scale"}
            className={isTouchDevice ? "touch-device" : "hover-element "}
            theme={"tomato"}
            arrows={"Large"}
          >
            <div
              className={`sidebar-title ${location.pathname.includes("/amazingadaniansmaster")
                  ? "selected"
                  : ""
                }`}
              onClick={() => handleTitleClick()}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick();
                }
              }}
            >
              <Link to="/amazingadaniansmaster/list">
                <span className="sidebar-link-icons">
                  <AdanianIcon color={"white"} size={"1em"} className="pe-1" />
                </span>
                <span
                  className={`sidebar-link ${expanded ? "visible" : "hidden"}`}
                >
                  Amazing Adanians
                </span>
              </Link>
            </div>
          </Tippy>
        )}


      </ul>
      <div className="sidebar-menu-icon">
        <span
          className="sidebar-icons border-radius"
          onClick={handleImageClick}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleTitleClick();
            }
          }}
        >
          <IoMenu />
        </span>
      </div>
    </div>
  );
}
