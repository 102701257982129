import React, { useState, useEffect } from 'react';

const Pagination = ({ total, pagination, handlePageChange, handleLimitChange }) => {
  const totalPages = Math.ceil(total / pagination.limit);
  const [inputPage, setInputPage] = useState(pagination.page);

  const onPageChange = (page) => {
    handlePageChange(page);
  };


  const handleInputChange = (e) => {
    if (e.target.value > totalPages) {
      setInputPage(totalPages);
    } else {
      setInputPage(e.target.value);
    }
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();
    let page = parseInt(inputPage, 10) || 1;
    page = Math.min(page, totalPages);
    onPageChange(page);
  };

  useEffect(() => {
    setInputPage(pagination.page);
  }, [pagination.page]);

  const renderPaginationNumbers = () => {
    const currentPage = parseInt(pagination.page);
    const visiblePages = 2;

    const startPage = Math.max(1, parseInt(currentPage) - parseInt(visiblePages));
    const endPage = Math.min(parseInt(totalPages), parseInt(currentPage) + parseInt(visiblePages));

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => {
      let buttonClass = 'pagination-button';
      if (page === currentPage) {
        buttonClass += ' active-pagination-button';
      }
      if (page === currentPage && page !== 1) {
        buttonClass += ' disabled-pagination-button';
      }

      return (
        <button
          key={page}
          onClick={() => {
            onPageChange(page);
          }}
          className={buttonClass}
          disabled={page === currentPage}
          style={page === currentPage ? { backgroundColor: '#325d85' } : null}
        >
          {page}
        </button>
      );
    });
  };



  const handleLimitSelection = (e) => {
    handleLimitChange(e);
  };

  return (
      <div className='pagination-wrapper row mgm-btm'>
        <div className='col-lg-3 col-sm-4 col-md-4 pagintion-result'>
          <label>Total Results: {total}</label>
        </div>
        {totalPages >= 1 && pagination.page <= totalPages ? (
          // JSX for true case
          <div className=' col-lg-5 col-sm-8 col-md-8 pagination-main'>
            <button onClick={() => onPageChange(parseInt(pagination.page) - 1)} disabled={parseInt(pagination.page) === 1}>
              {'<<'}
            </button>
            {renderPaginationNumbers()}
            <button onClick={() => onPageChange(parseInt(pagination.page) + 1)} disabled={parseInt(pagination.page) === totalPages || totalPages === 0}>
              {'>>'}
            </button>
          </div>
        ) : (
          // JSX for false case
          <div className='col-lg-2 '>Content for false case</div>
        )}
        <div className='col-lg-2 col-md-3 col-sm-3 col-6 left-filter'>
          <form onSubmit={handleInputSubmit} className='form-inline'>
            <div className='form-group'>
              <label htmlFor="pageInput">Page:</label>
              <input type='number' value={inputPage} onChange={handleInputChange} min='1' className='data-table-input mg-left-5' />
            </div>
          </form>
        </div>
        <div className='col-lg-2 col-md-6 col-6 right-filter'>
          <label>Per Page:</label>
          <select className='page-dropdown mg-left-5' value={pagination.limit} onChange={handleLimitSelection}>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
  );
};

export default Pagination;
