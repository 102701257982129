import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import SearchBar from "../components/SearchBar";
import SearchField from "./SearchField";
import UserProfile from "./UserProfile";

const WithHeaderFooter = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const [rightContentExpanded, setRightContentExpanded] = useState(false);

  const handleRightContentClick = (expanded) => {
    setRightContentExpanded(expanded);
  };

  return (
    <>
      <div className="main-slab main-content">
        {!isLoginPage && <Sidebar onImageClick={handleRightContentClick} />}
        <div
          className={`right-content ${rightContentExpanded ? "expanded" : "shrink"
            }`}
          style={{
            transition: "width 0.5s ease, margin-left 0.5s ease",
          }}
        >
          <div className="right-content-wrapper">
            {!isLoginPage && <SearchBar>
              <SearchField />
              <UserProfile />
            </SearchBar>}
            <div className="right-content-inner">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(WithHeaderFooter);
