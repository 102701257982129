import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HTTP } from "../api/http-common";
import Loader from "../components/loader";
import "react-quill/dist/quill.snow.css";
import ErrorHandlingComponent from "../components/ErrorHandlingComponent";

export default function Privacypolicy() {
  // Get the query string from the URL
  const queryString = window.location.search;

  // Parse the query string
  const queryParams = new URLSearchParams(queryString);

  // Check if the desired query parameter exists
  const inMobileDevice = queryParams.has('isMobileDevice');
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [error, setError] = useState(null);
  const [privacypolicy, setPrivacypolicy] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadPrivacypolicy = () => {
    setIsLoading(true);
    let config = {
      headers: { "Content-Type": "application/json" },
    };
    let fields = {
      filters: {
        key: "Privacy Policy",
      },
      pagination: { page: "1", limit: "10" },
      sorting: { field: "id", type: "desc" },
    };
    HTTP.post("static_info/list", fields, config)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.contents && data.contents.length > 0) {
            setPrivacypolicy(data.contents[0].value);
          } else {
            setPrivacypolicy("");
          }
        } else if (response.status === 204) {
          setPrivacypolicy([]);
        }
      })
      .catch((error) => {
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadPrivacypolicy();
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  useEffect(() => { }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {error && <ErrorHandlingComponent err={error} />}
      <div className="container">
        {!inMobileDevice ? (
          <h2 className="text-center mt-3 page-title">PRIVACY POLICY</h2>
        ) : (
          null
        )}

        {!inMobileDevice ? (
          <div className="text-end">
            <Link to="/">Back</Link>
          </div>
        ) : (
          null
        )}
        <div className={`policies-wrapper border-radius ${inMobileDevice ? "mt-4 vh-100" : ""}`}>
          <div className="policies">
            <div
              className="ql-editor mt-16 w-[500px] h-[300px] p-0 text-justify"
              dangerouslySetInnerHTML={{ __html: privacypolicy }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
