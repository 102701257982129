import React, { useState, useEffect } from 'react';
import { IoListSharp, IoSearch, IoAddCircleOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import AaMasterListUI from '../../components/AaMasterListUI';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';

export default function AaMaster() {
    const navigate = useNavigate();
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [search, setSearch] = useState({ name: '' });
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({ page: '1', limit: '10' });
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [aaMasters, setAaMasters] = useState([]);

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole)

    const loadAaMasters = async () => {
        setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                name: search.name,
            },
            pagination: pagination,
            sorting: { field: 'id', type: 'desc' },
        };
        HTTP.post('amazing_adanians/master/list', fields, config)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setAaMasters(data.contents);
                    setTotal(data.total_count);
                }
                if (response.status === 204) {
                    setAaMasters([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        loadAaMasters();
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag, search, pagination]);

    const onPaginationChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const onLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPagination({ ...pagination, limit: newLimit, page: 1 });
    };

    const onSearchEnter = (value) => {
        if (value.length == 0 || value.length >= 3) {
            setSearch({ ...search, name: value });
            setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="article-wrapper">
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            {/* <h2 style={{ fontSize: '25px' }}>Amazing Adanians Master</h2> */}
            <CommonTitle PageTitle='Amazing Adanians Master'/>
            <div className="video-table-wrapper">
                <div className="action-buttons-wrapper row">
                    <div className="left-button col-lg-6 col-md-3 col-6">
                        <button className="video-table-button-structure bg-secondary" style={{ padding: '7px 25px' }}>
                            <span>
                                <span className='video-page-icons'> <IoListSharp /></span>
                                <span>List </span>
                            </span>
                        </button>
                    </div>
                    <div className="right-button col-lg-6 col-md-9 col-6">
                        <ul className="action-buttons">
                            <Link to='/amazingadaniansmaster/add'>
                                <button className="video-table-button-structure bg-blue" style={{ padding: '7px 25px' }}>
                                    <span className='video-page-icons'>
                                        <IoAddCircleOutline />
                                    </span>
                                    <span>
                                        Add
                                    </span>
                                </button>
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className="video-table-main">
                    <div className="timeline-actions-wrapper row justify-content-between align-items-center">
                        <div className="col-xl-5 col-lg-5 col-md-7">
                            <span className="d-block border border-secondary p-1 border-radius">
                                <span><IoSearch /></span>
                                <span>
                                    <input type='text' placeholder='Search and press Enter' className='border-0 inner-searchbar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter(e.target.value) } }} />
                                </span>
                            </span>
                        </div>
                    </div>
                        {Array.isArray(aaMasters) && aaMasters.length > 0 ? (
                            aaMasters.map((aaMaster) => (
                                <AaMasterListUI key={aaMaster.id} aa_master={aaMaster} />
                            ))
                        ) : (
                            <p className='text-center mt-4'>
                                No amazing adanians found
                            </p>
                        )}
                </div>
                    {Array.isArray(aaMasters) && aaMasters.length > 0 ? (
                        <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
                    ) : (
                        null
                    )}
            </div>
        </div>
    )
}