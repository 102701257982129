import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Loader from '../../components/loader';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-quill/dist/quill.snow.css';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';
import ReactQuill, { Quill } from 'react-quill';
import htmlEditButton from "quill-html-edit-button";

Quill.register({
    "modules/htmlEditButton": htmlEditButton,
});

export default function StaticInfoUpdate() {
    const navigate = useNavigate();
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [title, setTitle] = useState('')
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    const [valueError, setValueError] = useState('')

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    useEffect(() => {
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag]);

    useEffect(() => {
        setIsLoading(true);
        loadStaticInfoData();
    }, []);

    const loadStaticInfoData = async () => {
        setIsLoading(true)
        try {
            const response = await HTTP.get('static_info/' + id);
            if (response.status === 200) {
                setTitle(response.data.content.title)
                setKey(response.data.content.key)
                setValue(response.data.content.value)
            } else if (response.status === 204) {
                Swal.fire('', 'Static info Not Found', 'error');
            }
        } catch (error) {
            setError(error);
            setRerenderFlag(true);
        } finally {
            setIsLoading(false);
        }
    };

    const showSubmitButton = () => {
        let user_role = parseInt(userObj.role.id);
        return user_role === 1;
    };

    const resetValidationErrors = () => {
        setValueError('')
    };

    const validateField = () => {
        resetValidationErrors();

        let checked = true;
        if (value === '') {
            setValueError('Please enter value')
            checked = false;
        } else if (value.length < 3) {
            setValueError("Please enter minimum 3 characters");
            if (checked) {
                checked = false;
            }
        }
        // else if (value.length > 200) {
        //     setValueError("Max character limit is 200");
        //     if (checked) {
        //         checked = false;
        //     }
        // }
        return checked;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateField()) {
            let config = {
                headers: { 'Content-Type': 'multipart/form-data', 'token': access_token },
            }
            let formData = new FormData();
            formData.append("value", value)
            setIsLoading(true)
            HTTP.put('static_info/update/' + id, formData, config)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('', response.data.message, 'success');
                        navigate('/staticinfo/list')
                    }
                    if (response.status === 204) {
                        Swal.fire('', 'Static info Not Found', 'error');
                    }
                    if (response.status === 403) {
                        Swal.fire('', response.data.content, 'error');
                    }
                })
                .catch((error) => {
                    setError(error);
                    setRerenderFlag(true);
                    loadStaticInfoData();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleEditorChange = (html) => {
        setValue(html);
    };

    const quillStyle = {
        height: 'calc(1.5em + 200px )', // You can adjust the height as needed
        marginBottom: '10px'
    };

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'direction': 'rtl' }],

            [{ size: [] }],
            ['link', 'image'],

            [{ 'align': [] }],

            ['clean'],
        ],
        htmlEditButton: { debug: true },
    };

    const quillFormats = [
        'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'align', 'align-center', 'align-right', 'align-justify',
        'link', 'image', 'video',
        'script', 'link', 'image',
        'code-block'
    ];

    const handleBack = () => {
        navigate('/staticinfo/list');
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <div>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="post-upload-wrapper">
                <div className='d-flex justify-content-between'>
                    {/* <h2 style={{ fontSize: '25px' }}>Update StaticInfo</h2> */}
                    <CommonTitle PageTitle='Update Static Info' />
                    <Link to='/staticinfo/list'>
                        <span className='video-page-icons'>
                        </span>
                        <span>
                            Back
                        </span>
                    </Link>
                </div>
                <div className="post-upload-main row">
                    <div className="headline col-xl-12 my-2">
                        <label>Title</label>
                        <span className="form-input">{title}</span>
                    </div>
                    <div className="intro col-xl-12 my-2">
                        <label>Key</label>
                        <span className="form-input">{key}</span>
                    </div>
                    <div className="intro col-xl-12 my-2 text-editor-wrapper">
                        <div>
                            <label>value</label>
                            <div >
                                <ReactQuill value={value}
                                    onChange={handleEditorChange}
                                    style={quillStyle}
                                    modules={quillModules}
                                    formats={quillFormats}
                                />
                            </div>
                        </div>
                        {valueError && <span className="error-message">{valueError}</span>}
                    </div>
                    <div className="d-flex justify-content-center ">
                        {showSubmitButton() && (<button type="submit" className="form-submit button-design" onClick={handleSubmit}>Submit</button>)}
                        &nbsp;
                        <button className='form-submit button-design' onClick={handleBack}>
                            <span className='text-white'>Back</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}