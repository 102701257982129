import React, { useState, useEffect } from 'react'
import { HTTP } from '../../api/http-common';
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loader from '../../components/loader';
import ViewerHeader from '../../components/ViewerHeader';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import 'react-quill/dist/quill.snow.css';
import ScrollProgress from '../scroller';

export default function ArticleDescription() {
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = (localStorage.getItem('access_token'));
    // const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const access_token_expires = (localStorage.getItem('access_token_expires'));
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [headLine, setHeadLine] = useState('')
    const [bodyCopy, setBodyCopy] = useState('')
    const [date, setDate] = useState('')
    const [byLine, setByLine] = useState('')
    const [imgCover, setImgCover] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     const expirationDate = new Date(access_token_expires);

    //     if (expirationDate.getTime() < Date.now()) {
    //         handleRefreshToken(setIsLoading, setError, setRerenderFlag);
    //     } else {
    //         loadArticleData();
    //     }
    // }, [access_token_expires]);

    useEffect(() => {
        loadArticleData();
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag]);

    const loadArticleData = async () => {
            setIsLoading(true)
            let config = {
                headers: { 'Content-Type': 'application/json', 'token': access_token },
            }
            HTTP.get('article/' + id, config)
                .then((response) => {
                    if (response.status === 200) {
                        setImgCover(response.data.content.cover_img_url)
                        setHeadLine(response.data.content.head_line)
                        setDate(response.data.content.date)
                        setByLine(response.data.content.by_line)
                        setBodyCopy(response.data.content.body_copy)
                    }
                    if (response.status === 204) {
                        Swal.fire('', 'Article Not Found', 'error');
                    }
                })
                .catch((error) => {
                    setError(error)
                    setRerenderFlag(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />
    }

    return (
        // <div>
        //     {rerenderFlag && <ErrorHandlingComponent err={error} />}
        //     <div className='theme'></div>
        //     <ViewerHeader />
        //     <div className='article-detail-wrapper'>
        //         <div id="section-one" className="overlapping-effect">
        //         </div>
        //         <div className='article-details'>
        //             <span className='d-block text-end pe-2  pt-3'>
        //                 <Link to='/'>
        //                     Back
        //                 </Link>
        //             </span>
        //             <div className='outer-scroll border-radius'
        //                 style={{ backgroundImage: `url(${imgCover})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        //             >
        //                 <div id="section-two" className="overlapping-effect article-details-glass-container">
        //                     <div className="overlap-content article-description-content">
        //                         <span className=''>
        //                             <h1 className='fw-bold text-center'>
        //                                 {headLine}
        //                             </h1>
        //                         </span>
        //                         <div className='d-flex justify-content-between'>
        //                             <span className='fw-bold d-inline-block mb-2 ps-3'>
        //                                 {byLine}
        //                             </span>
        //                             <span className='fw-bold d-inline-block mb-2 pe-3'>
        //                                 {date}
        //                             </span>
        //                         </div>

        //                         <div className="article-description-content">
        //                             <div className="ql-editor mt-10 w-[500px] h-[300px]" dangerouslySetInnerHTML={{ __html: bodyCopy }} />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>

        //         </div>

        //     </div>
        // </div >
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <ViewerHeader />
            <div className="container">
                {/* main article description page structure starts here  */}
                <div className="article-description-wrapper row">
                    <div className="col-xl-11 center-width">
                        {/* page title  */}
                        <div className=" pb-3 d-flex align-items-center justify-content-between page-title-wrapper">
                            <span className="d-flex align-items-center">
                                <h2>Article Details</h2>
                            </span>
                            <Link to='/'>
                                Back
                            </Link>
                            <ScrollProgress/>
                        </div>
                        {/* video cover image  */}
                        <div className="video-cover-image border-radius">
                        {imgCover? <img src={imgCover} alt="" /> : null}
                        </div>
                        {/* video details starts here  */}
                        <div className="video-details-container col-xl-12">
                            {/* video title */}
                            <div className="post-detail-descriptions-wrapper mb-4">
                                <div className="border-radius border bg-white p-3 box-shadow-effect article-description-container">
                                    <div className="">
                                        <h3 className="fs-1 text-center mg-btm"> {headLine} </h3>
                                    </div>

                                    {/* article status  */}
                                    <div className="article-details-status justify-content-between d-flex">
                                        {byLine ? <span className="fw-bold d-inline-block mb-2 pe-3"> {byLine} </span> : null}

                                        {date ? <span className="fw-bold d-inline-block mb-2 pe-3"> {date} </span> : null}
                                    </div>
                                    <div className="article-description-content ">
                                        {bodyCopy ?
                                            <div className="ql-editor mt-10 w-[500px] h-[300px] p-0" dangerouslySetInnerHTML={{ __html: bodyCopy }} />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
