import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './loader';

const useUserRoleEffect = (userRole) => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);

        if (userRole === 5) {
            navigate('/');
        } else if (userRole === 4 || userRole === 3 || userRole === 2) {
            if (!window.location.pathname.includes("/content")) {
                navigate('/dashboard');
            }
        } else if (userRole === 6) {
            if (window.location.pathname !== '/participation/list') {
                navigate('/dashboard');
            }
        } else {
            if (!localStorage.getItem('userObj')) {
                navigate('/');
            }
        }
        setIsLoading(false);
    }, [userRole, navigate]);
    if (isLoading) {
        return <Loader />;
    }
}
export default useUserRoleEffect;