import React, { useState } from "react";
// import DateTimePicker from "react-datetime-picker";
// import { EndTimeIcon, StartTimeIcon } from "./SideBarIcons";
// import { IoSearch } from "react-icons/io5";
// import Pagination from "./pagination";

const CommonTitle = ({ PageTitle }) => {
  return <h2 className="PageTitle">{PageTitle}</h2>;
};

// const DateUpdateUI = ({ DateSectionTitle }) => {
//   const [startDatetime, setStartDatetime] = useState(new Date());
//   const [startDatetimeError, setStartDatetimeError] = useState("");

//   const [endDatetime, setEndDatetime] = useState(new Date());
//   const [endDatetimeError, setEndDatetimeError] = useState("");
//   return (
//     <div className="col-xl-3 episode-timeline">
//       <div className="inner-date-section episode-timeline-section  ">
//         <h5 className="sub-section-episode-detail-title">{DateSectionTitle}</h5>
//         <span className="inner-date-timepicker">
//           <span className="d-block" htmlFor="datetime">
//             Start{" "}
//           </span>
//           <DateTimePicker
//             value={startDatetime}
//             onChange={(date) => setStartDatetime(date)}
//             format="dd/MM/y h:mm a"
//           />
//           <p>
//             {startDatetimeError && (
//               <span className="error-message">{startDatetimeError}</span>
//             )}
//           </p>

//           <span className="d-block" htmlFor="datetime">
//             End{" "}
//           </span>
//           <DateTimePicker
//             value={endDatetime}
//             onChange={(date) => setEndDatetime(date)}
//             format="dd/MM/y h:mm a"
//           />
//           <p>
//             {endDatetimeError && (
//               <span className="error-message">{endDatetimeError}</span>
//             )}
//           </p>
//         </span>
//       </div>
//     </div>
//   );
// };
// const DateShowUI = ({ DateShowTitle, aa_master }) => {
//   return (
//     <div className="sub-section-episode-detail sub-section-episode-detail">
//       <div className="inner-episode-design  episode-timeline-section p-2">
//         <h5 className="sub-section-episode-detail-title">{DateShowTitle}</h5>
//         {aa_master.start_datetime !== null &&
//         aa_master.start_datetime !== "" ? (
//           <div className=" ">
//             <span className="video-status episode-status episode-time-main">
//               <span className="d-flex align-items-center">
//                 <StartTimeIcon color={"black"} size={"1rem"} />
//                 <b>Start: </b>
//               </span>
//               <span>{aa_master.start_datetime}</span>
//             </span>
//           </div>
//         ) : null}

//         {aa_master.end_datetime !== null && aa_master.end_datetime !== "" ? (
//           <div className=" ">
//             <span className="video-status episode-status episode-time-main">
//               <span className="d-flex align-items-center">
//                 <EndTimeIcon color={"black"} size={"1rem"} className="pe-1" />
//                 <b>End : </b>
//               </span>
//               <span>{aa_master.end_datetime}</span>
//             </span>
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// };

// const onPaginationChange = (page) => {
//   setPagination({ ...pagination, page });
// };

// const onLimitChange = (e) => {
//   const newLimit = parseInt(e.target.value);
//   setPagination({ ...pagination, limit: newLimit, page: 1 });
// };

// const onSearchEnter = (value) => {
//   if (value.length == 0 || value.length >= 3) {
//       setSearch({ ...search, name: value });
//       setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
//   }
// };
// const InnerSearchBox = () => {
//   const [searchValue, setSearchValue] = useState('');
//   const navigate = useNavigate();
//   const access_token = (localStorage.getItem('access_token'));
//   const userObj = (JSON.parse(localStorage.getItem('userObj')));
//   const [error, setError] = useState(null);
//   const [rerenderFlag, setRerenderFlag] = useState(false);
//   const [search, setSearch] = useState({ name: '' });
//   const [total, setTotal] = useState(0);
//   const [pagination, setPagination] = useState({ page: '1', limit: '10' });
//   const [isLoading, setIsLoading] = useState(false);
//   const [aaMasters, setAaMasters] = useState([]);
//   return (
//     <div className="col-xl-5 col-lg-5 col-md-7">
//       <span className="d-block border border-secondary p-1 border-radius">
//         <span>
//           <IoSearch />
//         </span>
//         <span>
//           <input
//             type="text"
//             placeholder="Search and press Enter"
//             className="border-0 inner-searchbar"
//             value={searchValue}
//             onChange={(e) => setSearchValue(e.target.value)}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 onSearchEnter(e.target.value);
//               }
//             }}
//           />
//         </span>
//       </span>
//     </div>
//   );
// };

export { CommonTitle };
