import React, { useState, useEffect } from 'react';
import { IoListSharp, IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import 'sweetalert2/dist/sweetalert2.min.css';
import useUserRoleEffect from '../../components/userRoleEffect';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import ArticleListUI from '../../components/ArticleListUI';
import { handleRefreshToken } from '../../components/refreshToken';
import { CommonTitle } from '../../components/commonComponents';

export default function Article() {
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const access_token_expires = (localStorage.getItem('access_token_expires'));
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [articles, setArticles] = useState([]);
    const [search, setSearch] = useState({ head_line: '' });
    const [sorting, setSorting] = useState({ field: 'id', type: 'desc' });
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({ page: '1', limit: '10' });
    const [isLoading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    // useEffect(() => {
    //     setIsLoading(true)
    //     if (userRole === 4) {
    //         navigate('/dashboard');
    //     }
    //     setIsLoading(false)
    // }, [userRole, navigate]);

    // useEffect(() => {
    //     const expirationDate = new Date(access_token_expires);

    //     if (expirationDate.getTime() < Date.now()) {
    //         handleRefreshToken(setIsLoading, setError, setRerenderFlag);
    //     } else {
    //         loadArticles();
    //     }

    // }, [access_token_expires, search, pagination]);

    const loadArticles = async () => {
        setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                head_line: search.head_line,
            },
            pagination: pagination,
            sorting: sorting,
        };
        HTTP.post('article/list', fields, config)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setArticles(data.contents);
                    setTotal(data.total_count);
                }
                if (response.status === 204) {
                    setArticles([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadArticles();
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag, search, pagination]);

    const onPaginationChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const onLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPagination({ ...pagination, limit: newLimit, page: 1 });
    };

    const handleSortFieldChange = (field) => {
        const newSorting = {
            field,
            type: 'desc',
        };
        setSorting(newSorting);
        setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
    }

    const handleSortTypeChange = (type) => {
        setSorting({ ...sorting, type: type });
        setPagination({ ...pagination, page: 1, limit: pagination.limit });
    }

    const onSearchEnter = (value) => {
        if (value.length == 0 || value.length >= 3) {
            setSearch({ ...search, head_line: value });
            setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
        }
    };

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="article-wrapper">
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            {/* <h2 style={{ fontSize: '25px' }}>Articles</h2> */}
            <CommonTitle PageTitle='Articles' />
            <div className="video-table-wrapper">
                {/* button wrapper */}
                <div className="action-buttons-wrapper row">
                    <div className="left-button col-lg-6 col-md-3 col-6">
                        <button href="#" className="video-table-button-structure bg-secondary" style={{ padding: '7px 25px' }}>
                            <span>
                                <span className='video-page-icons'> <IoListSharp /></span>
                                <span>List </span>
                            </span>
                        </button>
                    </div>
                    <div className="right-button col-lg-6 col-md-9 col-6">
                        <ul className="action-buttons">
                            <Link to='/article/add'>
                                <button className="video-table-button-structure bg-blue" style={{ padding: '7px 25px' }}>
                                    <span className='video-page-icons'>
                                        <IoAddCircleOutline />
                                    </span>
                                    <span>
                                        Add
                                    </span>
                                </button>
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className="video-table-main">
                    <div className="timeline-actions-wrapper row justify-content-between align-items-center">
                        <div className="col-xl-5 col-lg-5 col-md-7">
                            <span className="d-block border border-secondary p-1 border-radius">
                                <span><IoSearch /></span>
                                <span>
                                    <input type='text' placeholder='Search and press Enter' className='border-0 inner-searchbar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter(e.target.value) } }} />
                                </span>
                            </span>
                        </div>
                        <div className='col-xl-4 col-lg-5 col-md-6 d-flex align-items-center video-table-dropdown p-0'>
                            <span>
                                <label htmlFor="sort" className='pe-2'> Sort:</label>
                                <select className="border-secondary  p-1  form-input" value={sorting.field} onChange={(e) => handleSortFieldChange(e.target.value)}>
                                    <option value="id">Id</option>
                                    <option value="head_line">Head Line</option>
                                    <option value="date">Date</option>
                                </select>
                            </span>
                            <span>
                                <label htmlFor="sortBy" className='pe-2'> SortBy:</label>
                                <select className="border-secondary  p-1  form-input" value={sorting.type} onChange={(e) => handleSortTypeChange(e.target.value)}>
                                    <option value="desc">Desc</option>
                                    <option value="asc">Asc</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    {Array.isArray(articles) && articles.length > 0 ? (
                        articles.map((article) => (
                            <ArticleListUI key={article.id} article={article} />
                        ))
                    ) : (
                        <p className='text-center mt-4'>
                            No articles found
                        </p>
                    )}
                </div>
                <>
                    {Array.isArray(articles) && articles.length > 0 ? (
                        <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
                    ) : (
                        null
                    )}
                </>
            </div>
        </div>
    )
}