import React, { useState, useEffect } from "react";
import { HTTP } from "../api/http-common";
import Slider from "react-slick";
import BrightcoveVideo from "./BrightcoveVideo";
import Loader from "./loader";
import ErrorHandlingComponent from "./ErrorHandlingComponent";

const HeroPosterSliderComponent = () => {
    const access_token = (localStorage.getItem('access_token'));
    const [isLoading, setIsLoading] = useState(false);
    const [heroPosters, setHeroPosters] = useState([]);
    const [error, setError] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        display: 'flex'
    };

    useEffect(() => {
        const sliderDots = document.querySelector(".slick-dots");
        if (sliderDots) {
            sliderDots.style.display = "block";
        }
    }, []);

    const loadHeroPosters = async () => {
        setIsLoading(true)
        let config = {
            headers: { 'Content-Type': 'application/json', 'token': access_token },
        }
        let fields = {
            filters: {
                title: '',
                is_active: true,
            },
            pagination: { page: '1', limit: '200' },
            sorting: { field: 'id', type: 'desc' },
        };
        HTTP.post('hero_poster/list', fields, config)
            .then((response) => {
                const data = response.data;
                if (response.status === 200) {
                    setHeroPosters(data.contents);
                }
                if (response.status === 204) {
                    setHeroPosters([])
                }
            })
            .catch((error) => {
                setError(error)
                setRerenderFlag(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        loadHeroPosters();
    }, []);

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />
    }


    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="slider-main" id="resume">
                <Slider {...settings}>
                    {Array.isArray(heroPosters) && heroPosters.length > 0 ? (
                        heroPosters.map((heroPoster) => (
                            <div key={heroPoster.id} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mobile-slider-width" >
                                <BrightcoveVideo videoId={heroPoster.media_url} />
                            </div>
                        ))
                    ) : (
                        null
                    )}
                </Slider>
            </div>
        </>
    )
}

export default HeroPosterSliderComponent;