import React, { useEffect, useState } from "react";
import Loader from "./loader";
import { useNavigate } from "react-router";

// export const isValidEmail = (input) => {
//     const emailRegex = /^[^\s@]+@adani+\.com/;
//     return emailRegex.test(input);
// };

// export const isValidEmail = (input) => {
//     const emailRegex = /^[^\s@]+@(adani|gmail|usualsmart.com)\.com$/;
//     return emailRegex.test(input);
// };

export const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isValidOTP = (input) => {
    const otpRegex = /^\d+$/;
    return otpRegex.test(input);
}

export const getDisabledStatusList = (statusList, content_created_by) => {
    let userObj = JSON.parse(localStorage.getItem('userObj'))
    let login_user_id = parseInt(userObj.id);
    let login_user_role = parseInt(userObj.role.id);
    let disabledSList = [];
    if (Array.isArray(statusList)) {
        statusList.forEach(function (status) {
            let status_id = status.id;
            if (status_id == 2) {//Reviewed
                if (login_user_role == 4) {//Creator
                    disabledSList.push(status_id);
                }
            }
            if (status_id == 3) {//Published
                if (login_user_role == 1) {//Admin

                } else if (login_user_role == 2) {//Reviewer
                    disabledSList.push(status_id);
                } else if (login_user_role == 4) {//Creator
                    disabledSList.push(status_id);
                }
            }
            if (status_id == 4) {//UnPublished
                if (login_user_role == 1) {//Admin

                } else if (login_user_role == 2) {//Reviewer
                    disabledSList.push(status_id);
                } else if (login_user_role == 3) {//Publisher
                    disabledSList.push(status_id);
                } else if (login_user_role == 4) {//Creator
                    disabledSList.push(status_id);
                }
            }
            if (login_user_id == content_created_by) {
                disabledSList.push(status_id);
            }
        });
    }
    disabledSList = [...new Set(disabledSList)];//Remove duplicate value
    return disabledSList;
}

export const validateEmail = (input) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
}

export const RoleBaseEfect = (userRole) => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true)
        if (userRole !== 1) {
            navigate('/');
        }
        if (!localStorage.getItem('userObj')){
            navigate('/');
        }
        setIsLoading(false)
    }, [userRole, navigate])
    if (isLoading) {
        return <Loader />;
    }
}