import React, { useState, useEffect } from 'react';
import { IoListSharp, IoSearch, IoAddCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import ContentListUI from '../../components/ContentListUI'
import { HTTP } from '../../api/http-common';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import Pagination from '../../components/pagination';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import useUserRoleEffect from '../../components/userRoleEffect';
import Loader from '../../components/loader';
import { handleRefreshToken } from '../../components/refreshToken';
import { CommonTitle } from '../../components/commonComponents';


export default function Video() {
  const access_token = (localStorage.getItem('access_token'));
  const userObj = (JSON.parse(localStorage.getItem('userObj')));
  const access_token_expires = (localStorage.getItem('access_token_expires'));
  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [contents, setContents] = useState([]);
  const [search, setSearch] = useState({ title: '', status_id: '', section_id: '' });
  const [sorting, setSorting] = useState({ field: 'id', type: 'desc' });
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ page: '1', limit: '10' });
  const [statusList, setStatusList] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [sectionList, setSectionList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const loadSections = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {
        name: '',
        without: process.env.REACT_APP_ARICAL_SECTION_ID,
      },
      sorting: { field: 'order_index', type: 'asc' },
    };
    HTTP.post('/sections/list', fields, config)
      .then((response) => {
        if (response.status === 200) {
          setSectionList(response.data);
        }
        if (response.status === 204) {
          Swal.fire('', 'Status Not Found', 'error');
        }
      })
      .catch((error) => {
        setError(error);
      })
  };

  const loadAllStatus = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {

      },
      sorting: { field: 'id', type: 'asc' },
    };
    HTTP.post('/content_status_master/list', fields, config)
      .then((response) => {
        if (response.status === 200) {
          setStatusList(response.data);
        }
        if (response.status === 204) {
          Swal.fire('', 'Status Not Found', 'error');
        }
      })
      .catch((error) => {
        setError(error);
      })
  };

  const loadContents = async () => {
    setIsLoading(true)
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {
        title: search.title,
        status_id: search.status_id,
        section_id: search.section_id,
        is_web: true,
      },

      pagination: pagination,
      sorting: sorting,
    };

    HTTP.post('content/list', fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setContents(data.contents);
          setTotal(data.total_count);
        }
        if (response.status === 204) {
          setContents([])
        }
      })
      .catch((error) => {
        setError(error)
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadAllStatus();
    loadSections();
  }, []);

  //   useEffect(() => {
  //     const expirationDate = new Date(access_token_expires);

  //     if (expirationDate.getTime() < Date.now()) {
  //         handleRefreshToken(setIsLoading, setError, setRerenderFlag);
  //     } else {
  //       loadContents();
  //     }

  // }, [access_token_expires, search, pagination]);

  useEffect(() => {
    loadContents()
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag, search, pagination]);

  const onPaginationChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const handleSortFieldChange = (field) => {
    const newSorting = {
      field,
      type: 'desc',
    };
    setSorting(newSorting);
    setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
  }

  const handleSortTypeChange = (type) => {
    setSorting({ ...sorting, type: type });
    setPagination({ ...pagination, page: 1, limit: pagination.limit });
  }

  const handleStatusChange = (status_id) => {
    setSearch({ ...search, status_id: status_id });
  };
  const handleSectionChange = (section_id) => {
    setSearch({ ...search, section_id: section_id });
  };

  const onLimitChange = (e) => {
    const newLimit = parseInt(e.target.value);
    setPagination({ ...pagination, limit: newLimit, page: 1 });
  };

  const onSearchEnter = (value) => {
    if (value.length == 0 || value.length >= 3) {
      setSearch({ ...search, title: value });
      setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
    }
  };

  useEffect(() => {
  }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="article-wrapper">
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      {/* <h2 style={{ fontSize: '25px' }} >Videos </h2> */}
      <CommonTitle PageTitle='Videos' />
      <div className="video-table-wrapper">

        {/* button wrapper */}
        <div className="action-buttons-wrapper row">
          <div className="left-button col-lg-6 col-md-3 col-6">
            <button className="video-table-button-structure bg-secondary" style={{ padding: '7px 25px' }}>
              <span>
                <span className='video-page-icons'> <IoListSharp /></span>
                <span>List </span>
              </span>
            </button>
          </div>
          <div className="right-button col-lg-6 col-md-9 col-6">
            <span className="action-buttons">
              <Link to='/content/add'>
                <button className="video-table-button-structure bg-blue" style={{ padding: '7px 25px' }}>
                  <span className='video-page-icons'>
                    <IoAddCircleOutline />
                  </span>
                  <span>
                    Add
                  </span>
                </button>
              </Link>
            </span>
          </div>
        </div>

        {/* video table */}
        <div className="video-table-main">
          <div className="timeline-actions-wrapper row justify-content-between align-items-center ">
            <div className='col-xl-5 col-lg-5 col-md-6'>
              <span className=' d-flex border border-secondary p-1 border-radius align-items-center'>
                <span><IoSearch /></span>
                <span className='viewer-searchfield'>
                  <input type='text' placeholder={`Search and press enter`} className='border-0 inner-searchbar' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter(e.target.value) } }} />
                </span>
              </span>
            </div>
            <div className='col-xl-6 col-lg-5 col-md-6 d-flex align-items-center video-table-dropdown p-0'>
              <span>
                <label htmlFor="sort" className='pe-2'> Sort:</label>
                <select className="border-secondary  p-1  form-input" value={sorting.field} onChange={(e) => handleSortFieldChange(e.target.value)}>
                  <option value="id">Id</option>
                  <option value="title">Title</option>
                  <option value="description">Description</option>
                  <option value="created_at">Created At</option>
                  <option value="updated_at">Updated At</option>
                </select>
              </span>
              <span>
                <label htmlFor="sortBy" className='pe-2'> SortBy:</label>
                <select className="border-secondary  p-1  form-input" value={sorting.type} onChange={(e) => handleSortTypeChange(e.target.value)}>
                  <option value="desc">Desc</option>
                  <option value="asc">Asc</option>
                </select>
              </span>
              <span>
                <label htmlFor="statusSelect" className='pe-2'> Status:</label>
                <select name="role_id" id="" className="border-secondary  p-1  form-input" onChange={(e) => {
                  handleStatusChange(e.target.value);
                }} value={search.status_id}>
                  <option key="" value="">All</option>
                  {Array.isArray(statusList) && statusList.length > 0 ? (
                    statusList.map((status) => (
                      <option key={status.id} value={status.id}>{status.name}</option>
                    ))
                  ) : (
                    null
                  )}
                </select>
              </span>
              <span>
                <label htmlFor='content' className='pe-2'> Section:</label>
                <select name="role_id" id="" className="border-secondary  p-1  form-input" onChange={(e) => {
                  handleSectionChange(e.target.value);
                }} value={search.section_id}>
                  <option key="" value="">All</option>
                  {Array.isArray(sectionList) && sectionList.length > 0 ? (
                    sectionList.map((section) => (
                      <option key={section.id} value={section.id}>{section.name}</option>
                    ))
                  ) : (
                    null
                  )}
                </select>
              </span>

            </div>
          </div>
          {Array.isArray(contents) && contents.length > 0 ? (
            contents.map((content) => (
              <ContentListUI key={content.id} content={content} statusList={statusList} handleReload={loadContents} />
            ))
          ) : (
            <p className='text-center mt-4'>
              No contents found
            </p>
          )}
        </div>
        <div>
          {Array.isArray(contents) && contents.length > 0 ? (
            <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
          ) : (
            null
          )}
        </div>
      </div>
    </div >
  )
}
