import React from 'react';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { BiSolidEdit } from "react-icons/bi";

const StaticInfoListUI = ({ static_info }) => {

    const value = static_info.value.length > 200 ? static_info.value.slice(0, 200) + '...' : static_info.value;

    return (
            <div className="video-content d-flex align-items-center row">
                <div className="col-lg-12">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="video-title">
                            {static_info.title}
                        </h4>
                        <Link className="view-analtyics-button button-design" key={static_info.id} to={`/staticinfo/update/${static_info.id}`} title='Edit'>
                            <BiSolidEdit />
                        </Link>
                    </div>
                    <p className="video-description">
                        {static_info.key}
                    </p>
                    <p className="video-description">
                        <div className="ql-editor mt-16 w-[500px] h-[300px] p-0" style={{}} dangerouslySetInnerHTML={{ __html: value }} />
                    </p>
                </div>
            </div>
    )
}

export default StaticInfoListUI