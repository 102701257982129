import { LogLevel } from "@azure/msal-browser";
export const msalC = {
    auth: {
      clientId: 'dac72029-0a27-4632-8014-58a8b0cd94bb', // Your application client ID
      authority: 'https://login.microsoftonline.com/04c72f56-1848-46a2-8167-8e5d36510cbc', // Your Azure AD tenant ID
      redirectUri: '/', // Redirect URI for your application/http://localhost:3000
      postLogpostLogoutRedirectUri: '/',
      navigateToLoginRequestUrl:false
    },
    cache: {
      cacheLocation: 'sessionStorage', // Cache location (localStorage or sessionStorage)
      storeAuthStateInCookie: false, // Set this to true if you want to store auth state in cookies
    },
    system:{
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if(containsPii){
                    return;
                }
                switch(level){
                    case LogLevel.Error:
                        console.error(message)
                        return;
                        case LogLevel.Info:
                            console.info(message)
                            return;
                        default:
                            return;
                    
                }
              console.log(`MSAL Logging (${level}): ${message}`);
            },
            piiLoggingEnabled: false, // Set to true if you want to log Personally Identifiable Information (PII)
            logLevel: "info", // Set the desired log level (info, warning, error, verbose)
          },
    }
};

export const loginRequest = {
    scopes:['user.read']
}