import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from './../../components/ErrorHandlingComponent'
import useUserRoleEffect from '../../components/userRoleEffect';
import Loader from '../../components/loader';
import { CommonTitle } from '../../components/commonComponents';

function UserAddEdit() {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const { id } = useParams();

  const access_token = (localStorage.getItem('access_token'));
  const userObj = (JSON.parse(localStorage.getItem('userObj')));

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [contactNo, setContactNo] = useState('');
  const [contactNoError, setContactNoError] = useState('');

  const [roleId, setRoleId] = useState('');
  const [roleIdError, setRoleIdError] = useState('');

  const [selectionCount, setSelectionCount] = useState('');
  const [selectionCountError, setSelectionCountError] = useState('');

  const [status, setStatus] = useState(false);

  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const handleStatusCheckboxChange = () => {
    setStatus(!status);
  };

  useEffect(() => {
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag]);

  const loadAllRoles = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    HTTP.get('/roles/list', config)
      .then((response) => {
        if (response.status === 200) {
          setRoles(response.data);
        }
        if (response.status === 204) {
          Swal.fire('', 'Roles Not Found', 'error');
        }
      })
      .catch((error) => {
        console.log(error);

      })
  };

  const loadUserData = async () => {
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    HTTP.get('user/' + id, config)
      .then((response) => {
        if (response.status === 200) {
          setName(response.data.content.name)
          setEmail(response.data.content.email)
          setContactNo(response.data.content.contact_no)
          setRoleId(response.data.content.role_id)
          setStatus(response.data.content.status)
          setSelectionCount(response.data.content.selection_count)
        }
        if (response.status === 204) {
          Swal.fire('', 'User Not Found', 'error');
        }
      })
      .catch((error) => {
        console.log(error);

      })
  };

  useEffect(() => {
    setIsLoading(true);
    loadAllRoles();
    setIsLoading(false);
    setIsLoading(true);
    loadUserData();
    setIsLoading(false);
  }, []);

  const resetValidationErrors = () => {
    setNameError('');
    setEmailError('');
    setContactNoError('');
    setRoleIdError('');
    setSelectionCountError('');
  };

  const validateField = () => {

    resetValidationErrors();

    let checked = true;
    if (name === '') {
      setNameError('Please enter Name');
      if (checked) {
        checked = false;
      }
    } else if (name.length < 5) {
      setNameError("Please enter minimum 5 characters");
      if (checked) {
        checked = false;
      }
    } else if (name.length > 100) {
      setNameError("Max character limit is 100");
      if (checked) {
        checked = false;
      }
    }

    if (email === '') {
      setEmailError('Please enter an email');
      if (checked) {
        checked = false;
      }
    }
    //  else if (!email.endsWith('@adani.com')) {
    //   setEmailError('Please enter a valid email like xyz@adani.com');
    //   if (checked) {
    //     checked = false;
    //   }
    // }
     else if (email.length < 5) {
      setEmailError("Please enter minimum 5 characters");
      if (checked) {
        checked = false;
      }
    } else if (email.length > 100) {
      setEmailError("Max character limit is 100");
      if (checked) {
        checked = false;
      }
    }

    if (contactNo === null || contactNo === '') {
      setContactNoError('Please enter a number');
      if (checked) {
        checked = false;
      }
    } else if (contactNo !== "" && contactNo.length !== 10) {
      setContactNoError('Please enter a valid number');
      if (checked) {
        checked = false;
      }
    }

    if (roleId === 6) {
      if (selectionCount === null || selectionCount === "") {
        setSelectionCountError('Please enter selection count');
        if (checked) {
          checked = false;
        }
      }
    }

    if (roleId === null || roleId === '') {
      setRoleIdError('Please select role');
      if (checked) {
        checked = false;
      }
    }

    return checked;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateField()) {
      console.log('User Form submitted:');
      let config = {
        headers: { 'Content-Type': 'application/json', 'token': access_token },
      }
      let fields = {
        name: name,
        email: email,
        role_id: roleId,
        contact_no: contactNo,
        status: status,
        selection_count: selectionCount,
      };
      setIsLoading(true)
      HTTP.put('/user/update/' + id, fields, config)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire('', response.data.message, 'success');
            navigate('/user/list')
          }

          if (response.status === 204) {
            Swal.fire('', 'Roles Not Found', 'error');
          }
        })
        .catch((error) => {
          setError(error);
          setRerenderFlag(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
  }, [isLoading]);

  const handleBack = () => {
    navigate('/user/list');
  };

  if (isLoading) {
    return <Loader />;
  }


  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className='d-flex justify-content-between'>
        {/* <h2 style={{ fontSize: '25px' }}>Update User</h2> */}
        <CommonTitle PageTitle='Upadte User'/>
        <Link to='/user/list'>
          <span>
            Back
          </span>
        </Link>
      </div>

      <div className="form-inner post-upload-main">
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <div className="row mg-btm">
            {/* Name field */}
            <div className="col-lg-6 mg-btm">
              <label htmlFor="name" className="add-form-label label-title">
                Name
              </label>
              <input
                type="text"
                placeholder="Enter Your Name"
                id="name"
                className="form-input"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <span className="error-message">{nameError}</span>
            </div>
            {/* Email field */}
            <div className="col-lg-6 mg-btm">
              <label htmlFor="email" className="add-form-label label-title">
                Email
              </label>
              <input
                type="text"
                placeholder="Enter Your Email"
                id="email"
                className="form-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span className="error-message">{emailError}</span>
            </div>

            {/* Number field */}
            <div className="col-lg-6 mg-btm">
              <label
                htmlFor="contact_no"
                className="add-form-label label-title"
              >
                Contact Number
              </label>
              <input
                type="text"
                placeholder="Contact Number"
                id="contact_no"
                className="form-input"
                maxLength={10}
                aria-controls="none"
                value={contactNo}
                onChange={(e) => {
                  setContactNo(e.target.value);
                }}
              />
              <span className="error-message">{contactNoError}</span>
            </div>
            {/* Role_id field */}
            <div className="col-lg-6 mg-btm">
              <label htmlFor="role_id" className="add-form-label label-title">
                Role
              </label>
              <select name="role_id" id="role_id" className="form-input" onChange={(e) => {
                setRoleId(e.target.value);
              }} value={roleId}>
                <option key="" value="">Select</option>
                {Array.isArray(roles) && roles.length > 0 ? (
                  roles.map((role) => (
                    <option key={role.id} value={role.id}>{role.name}</option>
                  ))
                ) : (
                  null
                )}
              </select>
              <span className="error-message">{roleIdError}</span>
            </div>
            {/* StatusID field */}
            <div className="col-lg-6 mg-btm">
              <label htmlFor="status_id" className="add-form-label label-title">
                Is Active
              </label>
              <input
                type="checkbox"
                checked={status}
                onChange={handleStatusCheckboxChange}
              />
              <span className="error-message">{roleIdError}</span>
            </div>
          </div>
          {userRole === 1 && roleId === 6 && <div className="col-lg-6 mg-btm">
            <label
              htmlFor="selection_count"
              className="add-form-label label-title"
            >
              Selection Count
            </label>
            <input
              type="text"
              placeholder="Selection Count"
              id="selection_count"
              className="form-input"
              aria-controls="none"
              value={selectionCount}
              onChange={(e) => {
                setSelectionCount(e.target.value);
              }}
            />
            <span className="error-message">{selectionCountError}</span>
          </div>}
          <div className="d-flex justify-content-center">
            <input type="submit" className="form-submit button-design" value="Submit" />
            <button className="form-submit button-design" type="button" onClick={handleBack}>
              Back
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default UserAddEdit;
