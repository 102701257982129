import React, { useState, useEffect } from 'react';
import { HTTP } from '../../api/http-common';
import '../../tables/table.css';
import Pagination from '../../components/pagination';
import { Link } from 'react-router-dom';
import ErrorHandlingComponent from './../../components/ErrorHandlingComponent'
import useUserRoleEffect from '../../components/userRoleEffect';
import Loader from '../../components/loader';
import { BiSolidEdit } from "react-icons/bi";
import { BiSend } from "react-icons/bi";
import { handleRefreshToken } from '../../components/refreshToken';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MultipleInputsForm from '../../components/MultipleInputsForm'
import { CommonTitle } from '../../components/commonComponents';


const UserTable = () => {
  const access_token = (localStorage.getItem('access_token'));
  const userObj = (JSON.parse(localStorage.getItem('userObj')));
  const access_token_expires = (localStorage.getItem('access_token_expires'));
  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userRole = userObj?.role?.id;
  useUserRoleEffect(userRole);

  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      isShow: true,
      filterable: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      isShow: true,
      filterable: true,
    },
    {
      id: "contact_no",
      numeric: false,
      disablePadding: false,
      label: "Contact No",
      isShow: true,
      filterable: true,
    },
    {
      id: "employee_id",
      numeric: false,
      disablePadding: false,
      label: "Employee Id",
      isShow: true,
      filterable: true,
    },
    {
      id: "role_name",
      numeric: false,
      disablePadding: false,
      label: "Role",
      isShow: true,
      filterable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isShow: true,
      filterable: true,
    },
    {
      id: "updated_by",
      numeric: false,
      disablePadding: false,
      label: "Updated By",
      isShow: true,
      filterable: false,
    },
    {
      id: "status_changed_by",
      numeric: false,
      disablePadding: false,
      label: "Status Changed By",
      isShow: true,
      filterable: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      isShow: true,
      filterable: false,
    },
  ];
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState({ name: '', email: '', contact_no: '', employee_id: '', role_name: '', status: '' });
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState({ field: 'id', type: 'asc' });
  const [pagination, setPagination] = useState({ page: '1', limit: '10' });
  const [searchValue, setSearchValue] = useState({ name: '', email: '', contact_no: '', employee_id: '', role_name: '', status: '' })

  // useEffect(() => {
  //   const expirationDate = new Date(access_token_expires);

  //   if (expirationDate.getTime() < Date.now()) {
  //     handleRefreshToken(setIsLoading, setError, setRerenderFlag);
  //   } else {
  //     loadUsers();
  //   }

  // }, [access_token_expires, search, sorting, pagination]);

  const loadUsers = async () => {
    setIsLoading(true);
    let config = {
      headers: { 'Content-Type': 'application/json', 'token': access_token },
    }
    let fields = {
      filters: {
        name: search.name,
        email: search.email,
        contact_no: search.contact_no,
        employee_id: search.employee_id,
        role_name: search.role_name,
        status: search.status,
        has_mobile_token: false
      },
      pagination: pagination,
      sorting: sorting,
    };

    HTTP.post('user/list', fields, config)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setUsers(data.contents);
          setTotal(data.total_count);
        }
        if (response.status === 204) {
          setUsers([])
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setRerenderFlag(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
    if (rerenderFlag) {
      setRerenderFlag(false);
    }
  }, [rerenderFlag, search, sorting, pagination]);

  const onPaginationChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const handleSortChange = (field) => {
    const newSorting = {
      field,
      type: sorting.field === field && sorting.type === 'asc' ? 'desc' : 'asc',
    };
    setSorting(newSorting);
    setPagination({ ...pagination, page: 1, limit: (pagination.limit) });
  };

  const onLimitChange = (e) => {
    const newLimit = parseInt(e.target.value);
    setPagination({ ...pagination, limit: newLimit, page: 1 });
  };

  const MySwal = withReactContent(Swal);
  const handleNotification = (token) => {
    MySwal.fire({
      title: 'Send Notification',
      html: <MultipleInputsForm />,
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      preConfirm: async () => {
        const title = document.querySelector('[name="title"]').value;
        const description = document.querySelector('[name="ndescription"]').value;

        console.log("Description:", description);

        let fields = {
          token: token,
          title: title,
          body: description,
          icon: null,
          image: null
        }

        return HTTP.post('/notification/send-notification', fields)
          .then(response => {
            return response.data;
          })
          .catch((error) => {

            console.log(error);
            setError(error);
            setRerenderFlag(true);
          });
      }
    }).then((result) => {
      if (result.isConfirmed) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  const onSearchEnter = () => {
    setIsLoading(true)
    const searchConditions = {
      name: searchValue.name || "",
      email: searchValue.email || "",
      contact_no: searchValue.contact_no || "",
      employee_id: searchValue.employee_id || "",
      role_name: searchValue.role_name || "",
      status: searchValue.status || "",
    };

    if (Object.values(searchConditions).every(value => value.length == 0 || value.length >= 3)) {
      setSearch(searchConditions);
      setPagination({ ...pagination, page: 1, limit: pagination.limit });
    }
    setIsLoading(false)
  };

  // const handleUploadUser = async (event) => {
  //   const file = event.target.files[0];
  //   let config = {
  //     headers: { "Content-Type": "multipart/form-data", token: access_token },
  //   };
  //   let formData = new FormData();
  //   formData.append('file', file);
  //   setIsLoading(true);
  //   await HTTP.post("user/upload/users", formData, config)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const { success_count, skipped_count, skip_reasons } = response.data.content;

  //         let message = '';

  //         if (skipped_count > 0) {
  //           message += '<p>Email should be in valid format (i.e., xyz@gmail.com)</p>';
  //           message += '<p>Length of contact no should be 10 and only contain numbers</p>';
  //         }

  //         message += `<p><strong>Uploaded Users:</strong> ${success_count}</p>`;
  //         message += `<p><strong>Skipped Users:</strong> ${skipped_count}</p>`;

  //         let title = null
  //         let icon = null

  //         if (success_count > 0) {
  //           title = "Users uploaded successfully"
  //           icon = "success"
  //         } else {
  //           title = "Unable to upload users"
  //           icon = "error"
  //         }

  //         Swal.fire({
  //           title: title,
  //           html: message,
  //           icon: icon,
  //         });
  //         loadUsers();
  //       }
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setRerenderFlag(true);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}

      <div className='table-wrapper'>
        <div className='demo-download-wrapper'>
          {/* <h2 style={{ fontSize: '25px' }}>Users</h2> */}
          <CommonTitle PageTitle='Users'/>
          {/* <CommonTitle/> */}
          
          {/* <span className='demo-download custom-file-input'>
            <input type="file" onChange={handleUploadUser} />
            <label className='button-design p-2 px-3'>Upload Users</label>
            <a href={'/test_data.xlsx'} download className='demo-file-link'>Download demo file</a>
          </span> */}

        </div>
        <table className='data-table'>
          <thead>
            <tr>
              {columns.map((cell) => (
                <th key={cell.id} onClick={() => handleSortChange(cell.id)}>
                  {cell.label}
                  {sorting.field === cell.id && (
                    <span>
                      {sorting.type === 'asc' ? ' ▲' : ' ▼'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
            <tr className='data-table-search'>
              {columns.map((cell) => (
                (cell.filterable != 'undefined' && cell.filterable !== undefined && cell.filterable) ? (
                  <td key={cell.id} >
                    {cell.id === 'status' ? (
                      <select
                        className='data-table-dropdown'
                        onChange={(e) => {
                          onSearchEnter();
                          const newStatus = e.target.value;
                          setSearchValue(prevSearchValue => ({ ...prevSearchValue, [cell.id]: newStatus }));
                          setSearch(prevSearch => ({ ...prevSearch, [cell.id]: newStatus }));
                        }}
                        value={searchValue.status}
                        onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter() } }}
                      >
                        <option value="">All Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    ) : (
                      <input
                        className='data-table-input'
                        type="text"
                        placeholder={`${cell.label}`}
                        value={searchValue[cell.id]}
                        onChange={(e) => setSearchValue({ ...searchValue, [cell.id]: e.target.value })}
                        onKeyDown={(e) => { if (e.key === 'Enter') { onSearchEnter() } }}
                      />
                    )}
                  </td>
                ) : (
                  <td key={cell.id}></td>
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) && users.length > 0 ? (
              users.map((user) => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.contact_no}</td>
                  <td>{user.employee_id}</td>
                  <td>{user.role_name}</td>
                  <td>{user.status}</td>
                  <td>{user.updated_by}</td>
                  <td>{user.status_changed_by}</td>
                  <td>
                    <Link key={user.id} to={`/user/edit/${user.id}`} title='Edit'>
                      <BiSolidEdit />
                    </Link>
                    {user.mobile_token && <Link key={user.id} onClick={() => handleNotification(user.mobile_token)} title='Send'>
                      <BiSend />
                    </Link>}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <>
        {Array.isArray(users) && users.length > 0 ? (
          <Pagination total={total} pagination={pagination} handlePageChange={onPaginationChange} handleLimitChange={onLimitChange} />
        ) : (
          null
        )}
      </>
    </>
  );
}

export default UserTable;