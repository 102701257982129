import React, { useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useNavigate } from 'react-router-dom'
import Loader from './loader';

const ErrorHandlingComponent = ({ err }) => {
  let navigate = useNavigate();
  const callback = ('');
  const [isLoading, setIsLoading] = useState(false);

  const catchHandler = () => {
    switch (err.response.status) {
      case 0:
        showSAlert('Remote server can not be reachable', 'error', callback);
        break;
      case 400:
        if (err.response.data.detail != undefined && err.response.data.detail != 'undefined') {
          showSAlert(err.response.data.detail, 'error', callback);
        } else {
          showSAlert(err.response.data.message, 'error', callback);
          showSAlert(err.response.data.content, 'error', callback);
        }
        break;
      case 401:
        localStorage.clear();
        navigate('/');
        break;
      case 403:
        if (err.response.data.detail != undefined && err.response.data.detail != 'undefined') {
          showSAlert(err.response.data.detail, 'error', callback);
        } else {
          showSAlert(err.response.data.content, 'error', callback);
        }

        break;
      case 404:
        showSAlert(err.response.data.message, 'error', callback);
        break;
      case 409:
        showSAlert(err.response.data.message, 'error', callback);
        break;
      default:
        break;
    }

  };

  const showSAlert = (message, type, callback) => {
    // Replace this with your actual implementation of showing alerts
    Swal.fire('', message, type);
    if (callback) {
      callback();
    }
  };

  // Invoke the catchHandler when the component mounts
  React.useEffect(() => {
    setIsLoading(true);
    catchHandler();
    setIsLoading(false)
  }, []);

  if (isLoading) {
    return <Loader />
  }

  return null;
};

export default ErrorHandlingComponent;
