import React, { useEffect, useRef } from 'react';
import "slick-carousel/slick/slick.css";

const BrightcoveVideo = ({ videoId, onPlaybackTimeChange, handleClick, exclusivePlayback }) => {
  const videoRef = useRef(null);
  let playerId = localStorage.getItem('brightcovePlayerValue');
  if (playerId === null || playerId === '') {
    playerId = 'default'
  }

  useEffect(() => {
    const loadPlayerScript = () => {
      const script = document.createElement('script');
      script.src = `https://players.brightcove.net/6415818175001/${playerId}_default/index.min.js`;
      script.async = true;

      script.onload = createPlayer;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const createPlayer = () => {
      if (window.bc) {
        const player = window.bc(document.getElementById(`myVideo-${videoId}`));

        player.on('timeupdate', () => {
          const currentTime = player.currentTime();
          onPlaybackTimeChange(currentTime);
        });

        player.on('play', () => {
          if (exclusivePlayback) {
            const videos = document.querySelectorAll('video');
            videos.forEach((video) => {
              if (video !== videoRef.current && !video.paused) {
                video.pause();
              }
            });
          }
        });
      }
    };

    if (window.bc) {
      createPlayer();
    } else {
      const cleanup = loadPlayerScript();
      return () => cleanup();
    }
  }, [onPlaybackTimeChange, videoId, playerId, exclusivePlayback]);

  return (
    <div id={`overlay-${videoId}`} onPlay={handleClick}>
      <video
        ref={videoRef}
        id={`myVideo-${videoId}`}
        data-account="6415818175001"
        data-player={playerId}
        data-embed="default"
        controls
        data-video-id={videoId}
        data-playlist-id=""
        data-application-id=""
        data-include-download="true"
        className="video-js vjs-fluid"
      />
    </div>
  );
};

export default BrightcoveVideo;
