import React from "react";
import { Link } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.min.css";
import { BiSolidEdit } from "react-icons/bi";
import { GoIssueClosed } from "react-icons/go";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ArticleListUI = ({ article }) => {
  
  return (
    <div className="video-content row">
      {/* <div className="col-lg-1">
                    <div className="justify-content-center">
                        <label className="custom-checkbox">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        </label>
                    </div>  
                </div> */}
      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-11 video-thumbnail-wrapper">

        <div className="video-thumbnail-container">
          {Array.isArray(article.media_list) &&
            article.media_list.length > 0 ? (
            <>
              {article.media_list.map((article) => (
                <div key={article.id}>
                  {article.media_type.includes("image") && article.media_name.startsWith("article_background_") ? (
                    <img src={`${article.media_path_url}`} alt="" />
                  ) : null}
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8">
        <div className="d-flex justify-content-between align-items-baseline">
          <h4 className="video-title">{article.head_line}</h4>
          <Link
            className="view-analtyics-button button-design"
            key={article.id}
            to={`/article/update/${article.id}`}
            title="Edit"
          >
            <BiSolidEdit />
          </Link>
        </div>
        <p className="video-description">{article.intro}</p>
        <div className="video-status-wrapper">
          {article.is_active ? (
            <span className="article-status success-message">
              <GoIssueClosed />
              <span className="ps-1" style={{ marginRight: "10px" }}>
                {" "}
                Active{" "}
              </span>
            </span>
          ) : (
            <span className="article-status error-message">
              <AiOutlineCloseCircle />
              <span className="ps-1" style={{ marginRight: "10px" }}>
                {" "}
                Not Active{" "}
              </span>
            </span>
          )}
          {article.by_line !== null && article.by_line !== "" ? (
            <span className="video-status ">By Line : {article.by_line}</span>
          ) : null}
          {article.date !== null && article.date !== "" ? (
            <span className="video-status">Date : {article.date}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ArticleListUI;
