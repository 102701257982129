import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP } from '../../api/http-common';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ErrorHandlingComponent from '../../components/ErrorHandlingComponent';
import Loader from '../../components/loader';
import 'react-quill/dist/quill.snow.css';
import useUserRoleEffect from '../../components/userRoleEffect';
import { CommonTitle } from '../../components/commonComponents';

export default function SectionAddUpdate() {
    const navigate = useNavigate();
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = (localStorage.getItem('access_token'));
    const userObj = (JSON.parse(localStorage.getItem('userObj')));
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [pageType, setPageType] = useState('Add');

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const handleStatusCheckboxChange = () => {
        setIsActive(!isActive);
    };

    const userRole = userObj?.role?.id;
    useUserRoleEffect(userRole);

    const loadSectionData = async () => {
        try {
            setIsLoading(true)
            let config = {
                headers: { 'Content-Type': 'application/json', 'token': access_token },
            }
            HTTP.get('sections/' + id, config)
                .then((response) => {
                    if (response.status === 200) {
                        setName(response.data.section.name)
                        setIsActive(response.data.section.is_active)
                    }
                    if (response.status === 204) {
                        Swal.fire('', 'Article Not Found', 'error');
                    }
                })
                .catch((error) => {
                    setError(error)
                    setRerenderFlag(true);
                })
        } finally {
            setIsLoading(false);
        }
    };

    const validateField = () => {
        setName('');

        let checked = true;
        if (name === '') {
            setNameError('Please enter Name');
            if (checked) {
                checked = false;
            }
        } else if (name.length < 5) {
            setNameError("Please enter minimum 5 characters");
            if (checked) {
                checked = false;
            }
        } else if (name.length > 100) {
            setNameError("Max character limit is 100");
            if (checked) {
                checked = false;
            }
        }
        return checked;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateField()) {
            let config = {
                headers: { 'Content-Type': 'multipart/form-data', 'token': access_token },
            }
            let cid = id;

            if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
                cid = 0
            }

            let formData = new FormData();
            formData.append("name", name);
            if (isActive) {
                formData.append("is_active", isActive)
            } else { formData.append("is_active", false) }
            setIsLoading(true)
            HTTP.put('sections/create/update/' + cid, formData, config)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('', response.data.message, 'success');
                        navigate('/sections/list')
                    }

                    if (response.status === 204) {
                        Swal.fire('', 'Section Not Found', 'error');
                    }

                    if (response.status === 403) {
                        Swal.fire('', response.data.content, 'error');
                    }
                })
                .catch((error) => {
                    setError(error);
                    setRerenderFlag(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };
    const handleBack = () => {
        navigate('/sections/list');
    };

    useEffect(() => {
        setIsLoading(true);
        let cid = id;
        if (cid === 'add' || cid === 'undefined' || cid === undefined || cid === ':id') {
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setPageType('Update')
            loadSectionData();
            setIsLoading(false);
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
    }, [isLoading]);

    if (isLoading) {
        return <Loader />;
    }


    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="post-upload-wrapper">
                <div className='d-flex justify-content-between'>
                    {/* <h2 style={{ fontSize: '25px' }}>{pageType} Article</h2> */}
                    <CommonTitle PageTitle='Articles'/>
                    <Link to='/sections/list'>
                        <span className='video-page-icons'>
                        </span>
                        <span>
                            Back
                        </span>
                    </Link>
                </div>
                <div className="post-upload-main row">
                    <div className="headline col-xl-12 my-3">
                        <label htmlFor='head_line'>Name</label>
                        <input type="text" id="head_line" className="form-input" value={name} onChange={(e) => { setName(e.target.value) }} />
                        {nameError && <span className="error-message">{nameError}</span>}
                    </div>
                    <div className="headline col-xl-12 my-3">
                        <label htmlFor='is_active'>Is Active</label>
                        <input
                            type="checkbox"
                            checked={isActive}
                            onChange={handleStatusCheckboxChange}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="form-submit button-design" onClick={handleSubmit}>Submit</button>
                        &nbsp;
                        <button className='form-submit button-design' onClick={handleBack}>
                            <span className='text-white'>Back</span>
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
