import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate,UnauthenticatedTemplate,useMsal,MsalProvider } from "@azure/msal-react";
import { loginRequest } from "../components/msalC.js";

const WrappedView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt:'create'
    }).catch((error) => console.log(error))
  };
  return (
  <div className='xyz'>
    <AuthenticatedTemplate>
      {activeAccount ? (
        <p> Authenticated </p>
      ):null}
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <button onClick={handleRedirect}>
        Sign up
      </button>
    </UnauthenticatedTemplate>
    
  </div>
  );

};

const SAMLRedirect = ({instance}) => {

  return (
    <MsalProvider instance={instance}>
      <div>SAMLRedirect</div>
      <WrappedView/>
    </MsalProvider>
  )
}

export default SAMLRedirect;
