import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { GoIssueClosed } from "react-icons/go";
import { SlClose } from "react-icons/sl";
import { BiSolidEdit } from "react-icons/bi";
import { EndTimeIcon, IdIcon, ParticipantIcon, StartTimeIcon } from './SideBarIcons';
import { HTTP } from '../api/http-common';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import Loader from './loader';
import ErrorHandlingComponent from './ErrorHandlingComponent';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import withReactContent from 'sweetalert2-react-content';

const EpisodeListUI = ({ aa_master_id, episode, loadEpisodes }) => {
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const access_token = localStorage.getItem("access_token");
    const userObj = JSON.parse(localStorage.getItem("userObj"));
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        if (rerenderFlag) {
            setRerenderFlag(false);
        }
    }, [rerenderFlag]);

    const handleDelete = () => {
        MySwal.fire({
            title: 'Are you sure you want to delete episode',
            text: 'Do you want to proceed?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                let config = {
                    headers: { "Content-Type": "application/json", token: access_token },
                };
                HTTP.delete("amazing_adanians/episode/master/delete/" + episode.id, config)
                    .then((response) => {
                        if (response.status === 200) {
                            Swal.fire("", "Episode Deleted successfully", "success");
                            loadEpisodes();
                        }
                    })
                    .catch((error) => {
                        setError(error);
                        setRerenderFlag(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        })
    }
    useEffect(() => { }, [isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {rerenderFlag && <ErrorHandlingComponent err={error} />}
            <div className="video-content align-items-center row">
                <div className="col-lg-12 episode-container">
                    <div className='episode-content'>
                        <h4 className="video-title">
                            {episode.name}
                        </h4>
                        <div className='row'>
                            <div className="col-xl-4 col-lg-6 col-sm-6 ">
                                {episode.start_datetime ? (<span className='video-status episode-status'>
                                    <span className='d-flex align-items-center'>
                                        <StartTimeIcon color={'black'} size={'1rem'} />
                                        <b>Start Datetime: </b>
                                    </span>
                                    <span>{episode.start_datetime} </span>
                                </span>) : null}
                            </div>

                            <div className="col-xl-4 col-lg-6 col-sm-6 ">
                                {episode.end_datetime ? (<span className='video-status episode-status'>
                                    <span className='d-flex align-items-center'>
                                        <EndTimeIcon color={'black'} size={'1rem'} />
                                        <b>End Datetime: </b>
                                    </span>
                                    <span>{episode.end_datetime} </span>
                                </span>) : null}
                            </div>

                            <div className="col-xl-4 col-lg-6 col-sm-6 ">
                                {episode.video_id ? (<span className='video-status episode-status'>
                                    <span className='d-flex align-items-center'>
                                        <IdIcon color={'black'} size={'1rem'} />
                                        <b>
                                            Video ID:
                                        </b>
                                    </span>
                                    <span>
                                        {episode.video_id}
                                    </span>
                                </span>) : null}
                            </div>

                            <div className="col-xl-4 col-lg-6 col-sm-6 ">
                                {episode.participants_count ? (<span className='video-status episode-status'>
                                    <span className='d-flex align-items-center'>
                                        <ParticipantIcon color={'black'} size={'1rem'} className="pe-1" />
                                        <b>
                                            Participants Count:
                                        </b>
                                    </span>

                                    <span>
                                        {episode.participants_count}
                                    </span>
                                </span>) : null}
                            </div>

                            <div className='col-xl-4 col-lg-6 col-sm-6 '>
                                <div className='article-status-wrapper flex ' >
                                    {episode.is_voting_line_open ? (
                                        <span className='article-status success-message'>
                                            <GoIssueClosed />

                                            <span className='ps-1'>
                                                Voting line is open
                                            </span>
                                        </span>
                                    ) :
                                        <span className='article-status error-message'>
                                            <SlClose />

                                            <span className='ps-1'>
                                                Voting line is closed
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-6 col-sm-6 '>
                                <div className='article-status-wrapper flex ' >
                                    {episode.is_active ? (
                                        <span className='article-status success-message'>
                                            <GoIssueClosed />

                                            <span className='ps-1'>
                                                Active
                                            </span>
                                        </span>
                                    ) :
                                        <span className='article-status error-message'>
                                            <SlClose />

                                            <span className='ps-1'>
                                                not Active
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='episode-action-buttons-wrapper'>
                        <Link className="mb-2 button-design episode-button flexbox-center" key={episode.id} to={`/amazingadaniansmaster/update/${aa_master_id}/episode/update/${episode.id}`} title='Edit'>
                            <BiSolidEdit />
                        </Link>
                        <button className="button-design episode-button " onClick={handleDelete} title='Delete'>
                            {" "}
                            x
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EpisodeListUI;