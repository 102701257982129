import React, { useState, useEffect } from "react";
import { HTTP } from '../api/http-common';
import BrightcoveVideo from '../components/BrightcoveVideo';
import Loader from "./loader";
import ErrorHandlingComponent from "./ErrorHandlingComponent";

const VideoSliderComponent = ({ section }) => {
  const access_token = (localStorage.getItem('access_token'));
  const [isLoading, setIsLoading] = useState(false)
  const [contents, setContents] = useState([]);
  const [error, setError] = useState(null);
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const loadContents = async () => {
    try {
      setIsLoading(true)
      let config = {
        headers: { 'Content-Type': 'application/json', 'token': access_token },
      }
      const sectionId = section.id;
      let fields = {
        filters: {
          title: '',
          status_id: process.env.REACT_APP_PUBLISHED_STATUS_ID,
          section_id: `${sectionId}`,
        },

        pagination: { page: "1", limit: "5" },
        sorting: { field: "id", type: "desc" },
      };

      HTTP.post('content/list', fields, config)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            setContents(data.contents);
          }
          if (response.status === 204) {
            setContents([])
          }
        })
        .catch((error) => {
          setError(error)
          setRerenderFlag(true);
        })
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      })
    }
  };
  useEffect(() => {
    loadContents();
  }, []);

  useEffect(() => {
  }, [isLoading]);

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {rerenderFlag && <ErrorHandlingComponent err={error} />}
      <div className="small-video-main-slab">
        {/* main div */}
        <div className="border-bottom  border-light-subtle">
          <span className="slab-title  d-inline-block">
            {section.name}
          </span>
        </div>
        {/* title */}
        <div className="resume small-videos-wrapper row" id="latest">
          {Array.isArray(contents) && contents.length > 0 ? (
            contents.map((content) => (
              <div key={content.id} className="col-xl-3 col-lg-4 col-md-4 col-sm-6  mobile-slider-width " >
                {/* sub section */}
                <div className="image-container">
                  <BrightcoveVideo videoId={content.media_url} />
                </div>
              </div>
            ))
          ) : (
            <>
              <p className="text-center mt-4">No contents found </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default VideoSliderComponent;
